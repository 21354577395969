import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Route, Station, Amenity,Bank,BankAccount,PaymentMethod,Language,BusType} from '../_models';

const routeUrl = `${environment.apiUrl}/route`;
const busTypeUrl = `${environment.apiUrl}/busType`;
const stationUrl = `${environment.apiUrl}/station`;
const amenityUrl = `${environment.apiUrl}/amenity`;
const bankUrl = `${environment.apiUrl}/bank`;
const bankAccountUrl = `${environment.apiUrl}/bankAccount`;
const paymentMethodUrl = `${environment.apiUrl}/paymentMethod`;
const languagueUrl = `${environment.apiUrl}/language`;

@Injectable({ providedIn: 'root' })
export class GuzoyeSettingsService {
    private routeSubject: BehaviorSubject<Route>;
    public route: Observable<Route>;
    private busTypeSubject: BehaviorSubject<BusType>;
    public busType: Observable<BusType>;
    private stationSubject: BehaviorSubject<Station>;
    public station: Observable<Station>;
    private amenitySubject: BehaviorSubject<Amenity>;
    public amenity: Observable<Amenity>;
    private bankSubject: BehaviorSubject<Bank>;
    public bank: Observable<Bank>;
    private bankAccountSubject: BehaviorSubject<BankAccount>;
    public bankAccount: Observable<BankAccount>;
    private paymentMethodSubject: BehaviorSubject<PaymentMethod>;
    public paymentMethod: Observable<PaymentMethod>;
    private languageSubject: BehaviorSubject<Language>;
    public language: Observable<Language>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.routeSubject = new BehaviorSubject<Route>(null);
        this.route = this.routeSubject.asObservable();
        this.busTypeSubject = new BehaviorSubject<BusType>(null);
        this.busType = this.busTypeSubject.asObservable();
        this.stationSubject = new BehaviorSubject<Station>(null);
        this.station = this.stationSubject.asObservable();
        this.amenitySubject = new BehaviorSubject<Amenity>(null);
        this.amenity = this.amenitySubject.asObservable();
        this.bankSubject = new BehaviorSubject<Bank>(null);
        this.bank = this.bankSubject.asObservable();
        this.bankAccountSubject = new BehaviorSubject<BankAccount>(null);
        this.bankAccount = this.bankAccountSubject.asObservable();
        this.paymentMethodSubject = new BehaviorSubject<PaymentMethod>(null);
        this.paymentMethod = this.paymentMethodSubject.asObservable();
        this.languageSubject = new BehaviorSubject<Language>(null);
        this.language = this.languageSubject.asObservable();
    }

    public get routeValue(): Route {
        return this.routeSubject.value;
    }

    public get busTypeValue(): BusType {
        return this.busTypeSubject.value;
    }

    public get stationValue(): Station {
        return this.stationSubject.value;
    }

    public get amenityValue(): Amenity {
        return this.amenitySubject.value;
    }

    public get bankValue(): Bank {
        return this.bankSubject.value;
    }

    public get bankAccountValue(): BankAccount {
        return this.bankAccountSubject.value;
    }

    public get paymentMethodValue(): PaymentMethod {
        return this.paymentMethodSubject.value;
    }

    public get languageValue(): Language {
        return this.languageSubject.value;
    }
   

    getAllRoute() {
        return this.http.get<Route[]>(routeUrl);
    }

    getAllBusType() {
        return this.http.get<BusType[]>(busTypeUrl);
    }

    getAllStation() {
        return this.http.get<Station[]>(stationUrl);
    }

    getAllAmenity() {
        return this.http.get<Amenity[]>(amenityUrl);
    }

    getAllBank() {
        return this.http.get<Bank[]>(bankUrl);
    }

    getAllBankAccount() {
        return this.http.get<BankAccount[]>(bankAccountUrl);
    }

    getAllPaymentMethod() {
        return this.http.get<PaymentMethod[]>(paymentMethodUrl);
    }

    getAllLanguage() {
        return this.http.get<Language[]>(languagueUrl);
    }

   //country Method
    getRouteById(id: string) {
        return this.http.get<Route>(`${routeUrl}/${id}`);
    }

    getBusTypeById(id: string) {
        return this.http.get<BusType>(`${busTypeUrl}/${id}`);
    }

    getStationById(id: string) {
        return this.http.get<Station>(`${stationUrl}/${id}`);
    }
    getAmenityById(id: string) {
        return this.http.get<Amenity>(`${amenityUrl}/${id}`);
    }

    getBankById(id: string) {
        return this.http.get<Bank>(`${bankUrl}/${id}`);
    }

    getBankAccountById(id: string) {
        return this.http.get<BankAccount>(`${bankAccountUrl}/${id}`);
    }
    getBusinessAccountById(id: string) {
        return this.http.get<BankAccount>(`${bankAccountUrl}/bank/${id}`);
    }
    
    getPaymentMethodById(id: string) {
        return this.http.get<PaymentMethod>(`${paymentMethodUrl}/${id}`);
    }

    getLanguageById(id: string) {
        return this.http.get<Language>(`${languagueUrl}/${id}`);
    }

    createRoute(params) {
        return this.http.post(routeUrl, params);
    }
    
    updateRoute(id, params) {
        return this.http.put(`${routeUrl}/${id}`, params)
            .pipe(map((route: any) => {
                    this.route = { ...this.routeValue, ...route };
                    this.routeSubject.next(route);
              
                return route;
            }));
    }
    deleteRoute(id: string) {
        return this.http.delete(`${routeUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createBusType(params) {
        return this.http.post(busTypeUrl, params);
    }
    
    updateBusType(id, params) {
        return this.http.put(`${busTypeUrl}/${id}`, params)
            .pipe(map((busType: any) => {
                    this.busType = { ...this.busTypeValue, ...busType };
                    this.busTypeSubject.next(busType);
              
                return busType;
            }));
    }
    deleteBusType(id: string) {
        return this.http.delete(`${busTypeUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createStation(params) {
        return this.http.post(stationUrl, params);
    }
    
    updateStation(id, params) {
        return this.http.put(`${stationUrl}/${id}`, params)
            .pipe(map((station: any) => {
                    this.station = { ...this.stationValue, ...station };
                    this.stationSubject.next(station);
              
                return station;
            }));
    }
    deleteStation(id: string) {
        return this.http.delete(`${stationUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createAmenity(params) {
        return this.http.post(amenityUrl, params);
    }
    
    updateAmenity(id, params) {
        return this.http.put(`${amenityUrl}/${id}`, params)
            .pipe(map((amenity: any) => {
                    this.amenity = { ...this.amenityValue, ...amenity };
                    this.amenitySubject.next(amenity);
              
                return amenity;
            }));
    }
    deleteAmenity(id: string) {
        return this.http.delete(`${amenityUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createBank(params) {
        return this.http.post(bankUrl, params);
    }
    
    updateBank(id, params) {
        return this.http.put(`${bankUrl}/${id}`, params)
            .pipe(map((bank: any) => {
                    this.bank = { ...this.bankValue, ...bank };
                    this.bankSubject.next(bank);
              
                return bank;
            }));
    }
    deleteBank(id: string) {
        return this.http.delete(`${bankUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createBankAccount(params) {
        return this.http.post(bankAccountUrl, params);
    }
    
    updateBankAccount(id, params) {
        return this.http.put(`${bankAccountUrl}/${id}`, params)
            .pipe(map((bankAccount: any) => {
                    this.bankAccount = { ...this.bankAccountValue, ...bankAccount };
                    this.bankAccountSubject.next(bankAccount);
              
                return bankAccount;
            }));
    }
    deleteBankAccount(id: string) {
        return this.http.delete(`${bankAccountUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createPaymentMethod(params) {
        return this.http.post(paymentMethodUrl, params);
    }
    
    updatePaymentMethod(id, params) {
        return this.http.put(`${paymentMethodUrl}/${id}`, params)
            .pipe(map((paymentMethod: any) => {
                    this.paymentMethod = { ...this.paymentMethodValue, ...paymentMethod };
                    this.paymentMethodSubject.next(paymentMethod);
              
                return paymentMethod;
            }));
    }
    deletePaymentMethod(id: string) {
        return this.http.delete(`${paymentMethodUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createLanguage(params) {
        return this.http.post(languagueUrl, params);
    }
    
    updateLanguage(id, params) {
        return this.http.put(`${languagueUrl}/${id}`, params)
            .pipe(map((language: any) => {
                    this.language = { ...this.languageValue, ...language };
                    this.languageSubject.next(language);
              
                return language;
            }));
    }
    deleteLanguage(id: string) {
        return this.http.delete(`${languagueUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    
}