import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService, AlertService } from '../../../../_services';
import { MustMatch } from '../../../../_helpers';


@Component({
    selector: 'app-otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.scss']
  })
export class OtpComponent implements OnInit {
    form: FormGroup;
    loading = false;
    account = [];
    response =[];
    appCode ; string;
    planCode: string;
    period: string;
    email: string;
    submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.appCode = this.route.snapshot.params['appCode'];
        this.planCode = this.route.snapshot.params['planCode'];
        this.period = this.route.snapshot.params['period'];
        this.email = this.route.snapshot.params['email'];
        this.form = this.formBuilder.group({
            email: this.email,
            otp: ['', Validators.required],
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        // if (this.form.invalid) {
        //     return;
        // }
        
        this.loading = true;
        
 this.accountService.verifyEmail(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    var account = this.account = account;
                    console.log(account);
                    
                    this.alertService.success(account, { keepAfterRouteChange: true });
                    this.router.navigate(['/checkout/'+this.appCode+'/'+ this.planCode+'/'+ this.period ], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
}