import { Component, OnInit } from '@angular/core';
import { AccountService,ApplcationService } from '../../../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
const imageUrl = `${environment.apiUrl}/preview`;

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketPlaceComponent implements OnInit {
  apps;
  imageView =imageUrl;
  constructor(
    private accountService: AccountService,
      private applcationService: ApplcationService,
      private route: ActivatedRoute,
      private router: Router,
  ) { }

  ngOnInit(): void {
    this.applcationService.getAll()
    .pipe(first())
    .subscribe(apps => this.apps = apps);
}
blogGrid: number = 1;

}
