import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-how-it-works',
    templateUrl: './how-it-works.component.html',
    styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: 'How It Works',
            paragraph: "Here on Fiverr it's just like working with any other freelancer. You can ask them to sign your NDA or any other legal document your legal department deems necessary. You can also find additional confidentiality obligations of freelancers mandated under Fiverr's Terms of Service."
        }
    ]
    howItWorksBox = [
        {
            icon: 'flaticon-user',
            title: 'Find Interesting Freelancer',
            paragraph: 'Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.'
        },
        {
            icon: 'flaticon-support-1',
            title: 'Compare a price',
            paragraph: 'Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.'
        },
        {
            icon: 'flaticon-tick',
            title: 'Make a Reservation',
            paragraph: 'Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.'
        }
    ]

}