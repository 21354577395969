
<div class="container-fluid" id="login">


    <div class="form-container sign-in-container">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <h1 class="font-weight-bold">One Time Password </h1>
        <div class="social-container">
        </div>
  
        <div class="form-group">
            <!-- <label>Verification Code</label> -->
            <input type="hidden" formControlName="otp" value="{{email}}">
            <input type="text" formControlName="otp" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.otp.errors }" />
            <div *ngIf="submitted && f.otp.errors" class="invalid-feedback">
                <div *ngIf="f.otp.errors.required">Verification Code is required</div>
                <div *ngIf="f.otp.errors.otp">Verification Code is invalid</div>
            </div>
        </div>
       {{email}}
        <button class="signin">Verify</button>
      
      </form>
   
    </div>

    <div class="overlay-container">
        <div class="overlay">
          <div class="overlay-panel overlay-right">
            <h1 class="font-weight-bold">One Time password</h1>
            <p>Onetime password sent to {{email}}, Please verfiy your email by entering code</p>
           <a routerLink='/register/{{appCode}}/{{planCode}}/{{period}}'>
                 <button class="signup" id="signUp">Sign Up</button>
           </a> 
          </div>
        </div>
      </div>
  </div>
  