<app-seller-sidemenu></app-seller-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-freelancer-navbar></app-freelancer-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="row">
    
        <div class="col-lg-10 col-md-12">
            <div class="my-profile-box">
                <h3>Freelancer Bio</h3>
    
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-lg-10">
                            <div class="col-lg-6 col-md-12">
                            
                                <div class="add-listings-box">
                                    <h3>Product Image</h3>
                               <div class="row">
                                <div class="file-upload-box col-md-4">
                                    <input type="text" class="form-control" formControlName="avator"  placeholder="Name of your business">
                                    <input 
                                    formControlName="image"
                                    id="image" 
                                    type="file" 
                                    class="dropzone"
                                    (change)="onFileChange($event)">
                                <div *ngIf="f.image.touched && f.image.invalid" class="alert alert-danger">
                                    <div *ngIf="f.image.errors.required">File is required.</div>
                                </div>
                               
                                </div>
                                <div class="col-md-8">
                                    <img [src]="logoSrc" *ngIf="logoSrc" style="height: 200px; width:400px">
                                </div>
                               </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <button type="submit">Save Change</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->