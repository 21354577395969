import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../../_helpers';
import { first } from 'rxjs/operators';
import { AccountService,ApplcationService,GuzoyeSettingsService,AlertService } from '../../../_services';
@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
    form: FormGroup;
    lastOrder;
    app;
    pricing;
    appCode: string;
    planId: string;
    period: string;
    today;
    oneMonth;
    twoMonth;
    oneyear;
    myService;
    price;
    qty;
    discount;
    finalPrice;
    alreadyRegister;
    selectedValue: string;
    submitted = false;
    loading = false;
    account = this.accountService.accountValue;
    
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private applcationService: ApplcationService,
        private alertService: AlertService
    ) { }

    
    ngOnInit(): void {
       
        this.appCode = this.route.snapshot.params['appCode'];
        this.planId = this.route.snapshot.params['planId'];
        this.period = this.route.snapshot.params['period'];
        if(!this.selectedValue){
            this.selectedValue = this.period
        }
      
        this.applcationService.getAllPricingByAppCode(this.appCode)
            .pipe(first())
            .subscribe(app => this.app = app);

        this.applcationService.getAllPricing(this.appCode,this.planId,this.selectedValue)
            .pipe(first())
            .subscribe(pricing => this.pricing = pricing);
            this.today =  moment().format('DD-MM-YYYY');
            this.oneMonth =  moment().add(1, 'M').format('DD-MM-YYYY');
            
            
        this.applcationService.getLastOrder(this.appCode,this.account.id)
            .pipe(first())
            .subscribe(lastOrder => this.lastOrder = lastOrder);   
           
         this.form = this.formBuilder.group({
            productName: ['', Validators.required],
            productCode: this.appCode,
            planId: this.planId,
            paymentPeriod: this.selectedValue,
            price:['', Validators.required],
            discount:['', Validators.required],
            qty:['', Validators.required],
            finalPrice:['', Validators.required],
            cupponCode:['1111', Validators.required],
            trailStart: this.today,
            trailEnd:this.oneMonth,
            paidPeriodStart:this.oneMonth,
            paidPeriodEnd: this.oneyear,
            accountId: this.account.id,
            applcationId: ['', Validators.required],
        });
           
            
    }
    
    pageTitleContent = [
        {
            title: 'Cart',
            backgroundImage: 'assets/img/page-title/page-title2.jpg'
        }
    ]

      // convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  
  onSubmit() {
    this.submitted = true;
  console.log(this.form.value);
  
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    // if (this.form.invalid) {
    //     return;
    // }
    
    this.loading = true;
        this.createAppOrder();
  
        console.log(this.form.value);
     
        
}


private createAppOrder() {
    this.applcationService.createAppOrder(this.form.value)
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Membership created successfully', { keepAfterRouteChange: true });
                this.router.navigate(['/checkout/'+this.appCode+'/'+ this.planId+'/'+ this.period ], { relativeTo: this.route });
              
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
                this.alreadyRegister = true;
            }
        });
}

}