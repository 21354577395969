<app-seller-sidemenu></app-seller-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-freelancer-navbar></app-freelancer-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="row">
    
        <div class="col-lg-10 col-md-12">
            <div class="my-profile-box">
                <h3>Freelancer Bio</h3>
    
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Skill </label>
                                <input type="text" class="form-control" formControlName="name"  placeholder="Logo Design">
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Please enter Name Name</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label><i class='bx bx-duplicate'></i> Your Level :</label>
                                <select formControlName="levelId" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.levelId.errors }" >
                                    <option>Select your Skill Level</option>
                                    <option value="{{level.id}}" *ngFor="let level of levels">{{level.nameE}}</option>
                                </select>
                                <div *ngIf="submitted && f.levelId.errors" class="invalid-feedback">
                                    <div *ngIf="f.levelId.errors.required">Please Select Levels</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <button type="submit">Save Change</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-10 col-md-12">
            <div class="my-profile-box">
                <div class="col-lg-8">
                    <div class="col-lg-8 col-md-12">
        
                        <div class="row">
                            <div class="col-lg-12 col-md-12" *ngFor="let s of skills ">
                                <div class="single-listings-item">
                                    <div class="row m-0">
        
                                        <div class="col-lg-8 col-md-8 p-0">
                                            <div class="listings-content">
                                                <span class="status {{s.name}}"><i class="flaticon-save"></i>{{s.name}}, {{s.level.name}}</span>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
       
        </div>
        </div>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->