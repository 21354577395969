<app-webNavBar></app-webNavBar>

<!-- Start Listings Details Area -->
<section class="listings-details-area pb-70">
    <div class="listings-details-image">
       <div class="row">
        <div class="col-md-3">
           
           </div>
           <div class="col-md-9">
            <img src="assets/img/onetapcall.jpg" alt="image">
           </div>
       </div>
        
        
        <div class="container">
            <div class="container">
                <div class="listings-details-content">
                    <span class="meta"> 
                        <h1>
                            <ngx-typed-js [strings]="['SMS ','USSD','CALL CENTER', 'CRM', 'TICKITING SYSTEM']" [typeSpeed]="100" [loop]="true" [backSpeed]="10" [showCursor]="false">
                                <span class="typing"></span>
                            </ngx-typed-js>
                        </h1>
                    </span>
                   
                  
                    <ul class="d-flex align-items-center">
                        <li class="phone-number">
                            <a  href="tel:+251111400810"><i class='bx bx-phone-call'></i>+251-111-400-810</a>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid">
          
        </div>
    </div>
  
<app-bpoService></app-bpoService>
</section>
<!-- End Listings Details Area -->

<app-footer-style-one></app-footer-style-one>