import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ProductService,PlanService ,AccountService,AlertService } from '../../../../_services';
import { MustMatch } from '../../../../_helpers';
import { Account } from 'src/app/_models';
import { environment } from '../../../../../environments/environment';
const imageUrl = `${environment.apiUrl}/preview`;


@Component({
    selector: 'app-gigfaq',
    templateUrl: './gigfaq.component.html',
    styleUrls: ['./gigfaq.component.scss']
})
export class GigFaqComponent implements OnInit {
    form: FormGroup;
    id: string;
    product;
    productCode: string;
    gigFaqs;
    isAddMode: boolean;
    loading = false;
    submitted = false;
    imageView = imageUrl;
    account = this.accountService.accountValue;
   
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private productService: ProductService,
        private planService: PlanService,
        private alertService: AlertService
    ) {
        
    }

    ngOnInit() {
        this.productCode = this.route.snapshot.params['productCode'];
        this.productService.getOne(this.productCode)
        .pipe(first())
        .subscribe(product => this.product = product);
        this.productService.getGigFaq(this.productCode)
        .pipe(first())
        .subscribe(gigFaqs => this.gigFaqs = gigFaqs);
        this.form = this.formBuilder.group({
            question: ['', Validators.required],
            answer: ['', Validators.required],
            productCode: this.productCode,
            productId: ['', Validators.required],
        });
    }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

      onSubmit() {
          
          this.submitted = true;
          // reset alerts on submit
          this.alertService.clear();
  
          // stop here if form is invalid
          // if (this.form.invalid) {
          //     return;
          // }
  
          this.loading = true;
              this.createproduct();
        
      }
  
      private createproduct() {
          this.productService.createFaq(this.form.value)
              .pipe(first())
              .subscribe({
                  next: () => {
                      this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                      this.router.navigate(['../'], { relativeTo: this.route });
                  },
                  error: error => {
                      this.alertService.error(error);
                      this.loading = false;
                  }
              });
      }
    
    breadcrumb = [
        {
            title: 'Freelancer Bio',
            subTitle: 'Dashboard'
        }
    ]

}