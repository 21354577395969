<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <section class="listing-area">
        <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let product of products ">
                <div class="single-listings-box">
                    <div class="listings-image">
                        <div class="listings-image-slides">
                            <img src="{{imageView}}{{product.avatorPath}}{{product.avator}}" alt="image" >
                        </div>
                        <a routerLink="/{{product.code}}" class="bookmark-save"><i class="flaticon-heart"></i></a>
                        <a routerLink="/{{product.code}}" class="category"><i class="flaticon-cooking"></i></a>
                    </div>
                    <div class="listings-content">
                        <div class="author">
                            <div class="d-flex align-items-center">
                                <img [src]="product.code" alt="image">
                                <span>{{product.code}}</span>
                            </div>
                        </div>
                        <span class="status {{product.name}}"><i class="flaticon-save"></i> {{product.name}}</span>
                        <div class="d-flex align-items-center justify-content-between">
                            
                       
                        </div>
                    </div>
                    <div class="listings-footer">
                        <div class="d-flex justify-content-between align-items-center">
                            <a routerLink="/gigdetail/{{product.code}}"  class="default-btn">Edit</a>
                            <a routerLink="/" class="default-btn">Delete</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->