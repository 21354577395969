import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FreelancerService,CategoryService,AddressService, AccountService,AlertService } from '../../../../_services';
import { MustMatch } from '../../../../_helpers';
import { Account } from 'src/app/_models';
import { environment } from '../../../../../environments/environment';
const imageUrl = `${environment.apiUrl}/preview`;


@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class FreelancerAddressComponent implements OnInit {
    form: FormGroup;
    id: string;
    parents;
    regionId;
    regions;
    cities;
    cityId;
    subCityId;
    seferId;
    subCities;
    sefers;
    address;
    logoSrc: string = '';
    imagePath: string='';
    isAddMode: boolean;
    loading = false;
    submitted = false;
    imageView = imageUrl;
    account = this.accountService.accountValue;
   
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private freelancerService: FreelancerService,
        private addressService: AddressService,
        private categoryService: CategoryService,
        private alertService: AlertService
    ) {
        
    }

    ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;
        this.addressService.getRegioninCountryById('1')
        .pipe(first())
        .subscribe(regions => this.regions = regions);
        this.freelancerService.getOneAddress(this.account.id)
        .pipe(first())
        .subscribe(address => this.address = address);
        this.form = this.formBuilder.group({
            countryId: ['1', Validators.required],
            regionId: ['', Validators.required],
            cityId: ['', Validators.required],
            subCityId: ['', Validators.required],
            seferId: ['', Validators.required],
            accountId: this.account.id,
        });

       
    }
    onRegionSelect(value) {
        this.regionId = value;
        this.addressService.getCityInRegionById(this.regionId)
        .pipe(first())
        .subscribe(cities => this.cities = cities);
    }
    onCitySelect(value) {
        this.cityId = value;
        this.addressService.getSubCityinCityById(this.cityId)
        .pipe(first())
        .subscribe(subCities => this.subCities = subCities);
    }
    onSubCitySelect(value) {
        this.subCityId = value;
        this.addressService.getSeferInSubCityById(this.subCityId)
        .pipe(first())
        .subscribe(sefers => this.sefers = sefers);
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

      onSubmit() {
          this.submitted = true;
          // reset alerts on submit
          this.alertService.clear();
  
          // stop here if form is invalid
        //   if (this.form.invalid) {
        //       return;
        //   }
  
          this.loading = true;
              this.createproduct();
        
      }
  
      private createproduct() {
          this.freelancerService.createLocation(this.form.value)
              .pipe(first())
              .subscribe({
                  next: () => {
                      this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                      
                  },
                  error: error => {
                      this.alertService.error(error);
                      this.loading = false;
                  }
              });
      }
    
    breadcrumb = [
        {
            title: 'Freelancer  Address',
            subTitle: 'Dashboard'
        }
    ]

}