<app-webNavBar></app-webNavBar>


<!-- Start Cart Area -->
<section class="cart-area " >
    <div class="col-lg-6"  *ngIf="alreadyRegister">
        <label class="col-lg-12">
            <div class=" paymentPeriodBox" style="background-color: #FF6700;">
                <div class="paymentPeriod">
                    <div class="row"> 
                        <div class="col-md-1">
                            <input type="radio" id="paymentPeriod" name="paymentPeriod" formControlName="paymentPeriod" value="annually" ng-model="paymentPeriod" checked>
                        </div>
                        <div class="col-md-11"><label for="annually">{{lastOrder.paymentPeriod}}</label></div>
                    </div>
                   
                      <p>  {{lastOrder.productName }}</p>
                      <p> then 400 birr/month</p>
{{lastOrder | json}}
                      <a routerLink="/checkout/{{lastOrder.productCode}}/{{lastOrder.planId}}/{{lastOrder.paymentPeriod}}" class="default-btn"><i class="flaticon-tick flaticon-right-arrow">Proceed to Checkout</i></a>
                </div>
            </div>
        </label>
    </div>
    <div class="row board">

        <div class="col-lg-4 box" style="background-color: #FF6700;">
            <h4> {{app.application[0].applcationName}}</h4>
           </div>
       <div class="col-lg-8 box" style="background-color: #FFF;">
      <div class="order-details">
      <h5>Asfaw, start your 1-month free trial now!</h5>  
      </div>
       </div>
       
</div>
    <div class="row ">
        <div class="col-md-1">
            <button class="default-btn" style="background-color: #FF6700;color: #FFF;"> <span class="icon">1</span></button>
        </div>
   
   <div class="col-md-11">
    <h5>Select your billing cycle
    </h5>  
    <p>Select annual to save 20% and expense with a single receipt.</p>
   </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row "  *ngFor="let p of app.prices;">
            <div class="col-lg-6 " ng-click="paymentPeriod = 'paymentPeriod'" *ngIf=" p.planType === 'Monthly' && p.plan === planCode">
                <label class="col-lg-12 ">
                    <div class="paymentPeriodBox" style="background-color: #FFF;">
                        <div class="paymentPeriod">
                            <div class="row"> 
                                <div class="col-md-1">
                                    <input type="radio" id="paymentPeriod" name="paymentPeriod" formControlName="paymentPeriod" value="Monthly" ng-model="paymentPeriod"   (change)="changePeriod($event)">
                                    
                                </div>
                                <div class="col-md-11"><label for="Monthly">Monthly</label></div>
                            </div>
                              <p> 1- month free trial</p>
                              <p> then {{ p.price}} birr/month</p>
                             
                         
                        </div>
                    </div>
                </label>
            </div>
            <div class="col-lg-6"  ng-click="paymentPeriod = 'paymentPeriod'" *ngIf=" p.planType === 'Annuel'">
                <label class="col-lg-12">
                    <div class=" paymentPeriodBox" style="background-color: #FFF;">
                        <div class="paymentPeriod">
                            <div class="row"> 
                                <div class="col-md-1">
                                    <input type="radio" id="paymentPeriod" name="paymentPeriod" formControlName="paymentPeriod" value="annually" ng-model="paymentPeriod">
                                </div>
                                <div class="col-md-11"><label for="annually">Annual</label></div>
                            </div>
                              <p> 1- month free trial</p>
                              <p> then 400 birr/month</p>
                               
                           
                        </div>
                    </div>
                </label>
            </div>
            
        </div>
        <div class="row board" *ngIf=" selectedValue === 'Monthly' ">
            <div class="payment-box" style="background-color: #FFF;">
                <div class="payment-method">
                  <div class="row">
                    <p class="col-md-11"> Monthly</p> <p class="col-md-1">$69.99</p>
                    <hr>
                    <p class="col-md-11"> Monthly after free trial</p> <p class="col-md-1">$69.99</p>
                  </div>
                   <hr>
        
                   <p>Your free trial begins on <b>{{today}}</b> and will end on <b>{{oneMonth}}</b>. You can cancel anytime before <b>January 8, 2022</b> to avoid being charged and we’ll send an email reminder 7 days before the trial ends.
   
                   </p>
                </div>
            </div>
        </div>
        <div class="row board" *ngIf=" selectedValue ===  'Annuel' ">
            <div class="payment-box" style="background-color: #FFF;" >
                <div class="payment-method">
                  <div class="row">
                    <p class="col-md-11"> Total after free trial ($79.99 x 12 months)</p> <p class="col-md-1">$69.99 
                       
                    </p>
                    <span> *$240.00 saving when you buy annual</span>
        
                  </div>
                   <hr>
                
                   <p>Your free trial begins on <b>December 8, 2021</b> and will end on <b>January 8, 2022</b>. You can cancel anytime before <b>January 8, 2022</b> to avoid being charged and we’ll send an email reminder 7 days before the trial ends.
        
                   </p>
                </div>
            </div>
        </div>
   
    <div class="container" style="background-color: #FFF;" *ngFor="let price of pricing;">

            <div class="cart-table table-responsive" >
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let a of app.application;">
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/products/products-img1.jpg" alt="item">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products"> {{a.applcationName}}</a>
                                
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">{{price.price}}</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1"  formControlName="qty" [ngModel]="1">
                                    <input type="hidden" formControlName="productName" [ngModel]="a.applcationName" >
                                    <input type="hidden" formControlName="price" [ngModel]="price.price" >
                                    <input type="hidden" formControlName="discount" [ngModel]="price.discount" >
                                    <input type="hidden" formControlName="finalPrice" [ngModel]="price.price - 9.50" >
                                    <input type="hidden" formControlName="trailStart" [ngModel]="today" >
                                    <input type="hidden" formControlName="trailEnd" [ngModel]="oneMonth" >
                                    <input type="hidden" formControlName="paidPeriodStart" [ngModel]="today" >
                                    <input type="hidden" formControlName="paidPeriodEnd" [ngModel]="today" >
                                    <input type="hidden" formControlName="applcationId" [ngModel]="app.application[0].id" >
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">{{ price.price}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="cart-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7 text-right">
                        <div class="shopping-coupon-code">
                            <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code" id="coupon-code">
                            <button type="submit">Apply Coupon</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cart-totals">
                <h3>Cart Totals</h3>

                <ul>
                    <li>Subtotal <span>{{ price.price}}</span></li>
                    <li>Shipping <span>{{ price.discount}}</span></li>
                    <li>Total <span>{{ price.price}}</span></li>
                </ul>
                <div>
                    
                </div>
                <button type="submit" class="default-btn"><i class="flaticon-tick"></i>Proceed to Checkout</button>

            </div>
       
    </div>
</form>
</section>

<!-- End Cart Area -->
<app-footer-style-one></app-footer-style-one>