import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seller-sidemenu',
  templateUrl: './seller-sidemenu.component.html',
  styleUrls: ['./seller-sidemenu.component.scss']
})
export class SellerSidemenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
