<app-seller-sidemenu></app-seller-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-freelancer-navbar></app-freelancer-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="row">
    
        <div class="col-lg-10 col-md-12" *ngIf="topReasons.length < 4">
            <div class="my-profile-box">
                <h3> Four Top Main Reason why customer choose you</h3>
    
                <form [formGroup]="form" (ngSubmit)="onSubmit()" >
                    <div class="row">
                        
                        <div class="col-xl-8  col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Reason Title</label>
                                <input type="text" class="form-control" formControlName="title"  placeholder="title">
                            </div>
                            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                <div *ngIf="f.title.errors.required">Please Select title</div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <div class="form-group">
                                <label>Detail</label>
                                <textarea cols="30" rows="6" formControlName="detail"  placeholder="Short description about you..." class="form-control"></textarea>
                            </div>
                        </div>
    
                        <div class="col-lg-8 col-md-12">
                            <div class="form-group">
                                <button type="submit">Save Change</button>
                            </div>
                        </div>
                        <alert></alert>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-10 col-md-12">
            <div class="my-profile-box">
                <div class="col-lg-8">
                    <div class=" table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="header" colspan="2">Title</th>
                                    <th class="header" colspan="2">Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                               
                                <tr *ngFor="let top of topReasons">
                                    <td colspan="2">
                                        <p class="text-right">{{top.title}}</p>
                                    </td>
                                    <td colspan="2">
                                        <p class="text-right">{{top.detail}}</p>
                                    </td>
                                </tr>
                                <tr>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
       
        </div>
        </div>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->