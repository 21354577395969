

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>


    <!-- Start -->
    <div class="row">
    
        <div class="col-lg-4 col-md-12" >
            <div class="my-profile-box">
    
                <div class="author-sidebar">
                    <div class="user-profile">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/user3.jpg" alt="image">
    
                            <div class="title">
                                <h5>{{account.firstName}}  {{account.lastName}}</h5>
                                <span class="sub-title">Own Company</span>
                                <div class="rating d-flex align-items-center">
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="rating-count">(5 reviews)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="user-contact-info">
                 

                    <ul class="user-contact">
                        <li><a href="#"><i class='bx bx-phone-call'></i> {{account.mobile}}</a></li>
                        <li><a href="#"><i class='bx bx-envelope'></i> {{account.email}}</a></li>
                    </ul>
                    <hr>
                    <ul class="social-profiles">
                        <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                        <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                    </ul>

             
                </div>
            </div>

            
            <div class="my-profile-box">
    
                <div class="user-contact-info">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <h5>Bio</h5>

                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group" *ngIf="!account.bio">
                                <button (click)="bioFunction()">Add Bio </button>
                            </div>
                            <div class="form-group" *ngIf="account.bio">
                                <button (click)="bioFunction()">Edit Bio </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <p>{{account.bio}}</p>
                    </div>
                    <div class="profile-form" *ngIf="bio">
                        
                    <form [formGroup]="bioForm" (ngSubmit)="onBioSubmit()">
                        <div class="row">
                            
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea cols="30" rows="6" formControlName="bio"  placeholder="Short description about you..." class="form-control" [ngClass]="{ 'is-invalid': submitted && bf.bio.errors }"  [ngModel]="account.bio"></textarea>
                                    <div *ngIf="submitted && bf.bio.errors" class="invalid-feedback">
                                        <div *ngIf="bf.bio.errors.required">Please Select Area Name</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <button (click)="cancel()">Cancel </button>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <button type="submit">Save Change</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
                <div class="my-profile-box" >
                    <hr>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <h5>Location</h5>

                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group" *ngIf="!location">
                                <button (click)="locationFunction()">Add Location </button>
                            </div>
                            <div class="form-group" *ngIf="location">
                                <button (click)="bioFunction()">Edit Location </button>
                            </div>
                        </div>
                    </div>
                    <div class="profile-form" *ngIf="location">
  
                        <form [formGroup]="aForm" (ngSubmit)="onAddressSubmit()">
                            <div class="row">
                                
                                
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <select formControlName="regionId" class="form-control"  [ngClass]="{ 'is-invalid': submitted && af.regionId.errors }" (change)="onRegionSelect($event.target.value)">
                                            <option> Region</option>
                                            <option value="{{region.id}}" *ngFor="let region of regions">{{region.nameE}}</option>
                                        </select>
                                        <div *ngIf="submitted && af.regionId.errors" class="invalid-feedback">
                                            <div *ngIf="af.regionId.errors.required">Please Select Region</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <select formControlName="cityId" class="form-control"  [ngClass]="{ 'is-invalid': submitted && af.cityId.errors }" (change)="onCitySelect($event.target.value)">
                                            <option>City</option>
                                            <option value="{{city.id}}" *ngFor="let city of cities">{{city.nameE}}</option>
                                        </select>
                                        <div *ngIf="submitted && af.cityId.errors" class="invalid-feedback">
                                            <div *ngIf="af.cityId.errors.required">Please Select Categories</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <select formControlName="subCityId" class="form-control" [ngClass]="{ 'is-invalid': submitted && af.subCityId.errors }" (change)="onSubCitySelect($event.target.value)">
                                            <option> Sub City</option>
                                            <option value="{{subCity.id}}" *ngFor="let subCity of subCities">{{subCity.nameE}}</option>
                                        </select>
                                        <div *ngIf="submitted && af.subCityId.errors" class="invalid-feedback">
                                            <div *ngIf="af.subCityId.errors.required">Please Select Sub City</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <select formControlName="seferId" class="form-control" [ngClass]="{ 'is-invalid': submitted && af.seferId.errors }" >
                                            <option>Area Name</option>
                                            <option value="{{sefer.id}}" *ngFor="let sefer of sefers">{{sefer.nameE}}</option>
                                        </select>
                                        <div *ngIf="submitted && af.seferId.errors" class="invalid-feedback">
                                            <div *ngIf="af.seferId.errors.required">Please Select Area Name</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 button button-warning">
                                    <div class="form-group">
                                        <button type="submit" (click)="cancel()"> Cancel</button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <button type="submit">Save </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="my-profile-box" >
                    <hr>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <h5>Language</h5>

                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group" *ngIf="!language">
                                <button (click)="languageFunction()">Add Language </button>
                            </div>
                            <div class="form-group" *ngIf="language">
                                <button (click)="languageFunction()">Edit Location </button>
                            </div>
                        </div>
                        <div class=" table-responsive">
                            <table class="table table-striped">
                                <tbody>
                                   
                                    <tr *ngFor="let ls of languageSkills">
                                        <td colspan="2">
                                            <p class="text-right">{{ls.language.name}} -</p>
                                        </td>
                                        <td colspan="2">
                                            <p class="text-right">{{ls.level.name}}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="profile-form" *ngIf="language">
  
                    <form [formGroup]="lForm" (ngSubmit)="onLanguageSubmit()">
                        <div class="row">
                            
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select formControlName="languageId"  class="form-control" [ngClass]="{ 'is-invalid': submitted && lf.languageId.errors }">
                                        <option>Select Languag</option>
                                        <option value="{{language.id}}" *ngFor="let language of languages">{{language.nameE}}</option>
                                    </select>
                                    <div *ngIf="submitted && lf.languageId.errors" class="invalid-feedback">
                                        <div *ngIf="lf.languageId.errors.required">Please  Language</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group profile">
                                    <select formControlName="levelId" class="form-control"  [ngClass]="{ 'is-invalid': submitted && lf.levelId.errors }" >
                                        <option>Select Language Level</option>
                                        <option value="{{level.id}}" *ngFor="let level of levels">{{level.nameE}}</option>
                                    </select>
                                    <div *ngIf="submitted && lf.levelId.errors" class="invalid-feedback">
                                        <div *ngIf="lf.levelId.errors.required">Please Select Levels</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 button button-warning">
                                <div class="form-group">
                                    <button type="submit" (click)="cancel()"> Cancel</button>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <button type="submit">Save </button>
                                </div>
                            </div>
                            <alert></alert>
                        </div>
                    </form>
                    </div>
                </div>
                <div class="my-profile-box" >
                    <hr>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <h5>Skill</h5>

                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group" *ngIf="!language">
                                <button (click)="skillFunction()">Add Language </button>
                            </div>
                            <div class="form-group" *ngIf="language">
                                <button (click)="skillFunction()">Edit Location </button>
                            </div>
                        </div>
                    </div>
                    <table class="table table-striped">
                        <tbody>
                           
                            <tr *ngFor="let s of skills">
                                <td colspan="2">
                                    <p class="text-right">{{s.name}} -</p>
                                </td>
                                <td colspan="2">
                                    <p class="text-right">{{s.level.name}}</p>
                                </td>
                            </tr>
                            <tr>
                            </tr>
                        </tbody>
                    </table>
                    <div class="profile-form" *ngIf="skill">
  
                        <form [formGroup]="sForm" (ngSubmit)="onSkillSubmit()">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="name"  placeholder="Logo Design">
                                        <div *ngIf="submitted && sf.name.errors" class="invalid-feedback">
                                            <div *ngIf="sf.name.errors.required">Please enter Name Name</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <select formControlName="levelId" class="form-control"  [ngClass]="{ 'is-invalid': submitted && sf.levelId.errors }" >
                                            <option>Select your Skill Level</option>
                                            <option value="{{level.id}}" *ngFor="let level of levels">{{level.nameE}}</option>
                                        </select>
                                        <div *ngIf="submitted && sf.levelId.errors" class="invalid-feedback">
                                            <div *ngIf="sf.levelId.errors.required">Please Select Levels</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 button button-warning">
                                    <div class="form-group">
                                        <button type="submit" (click)="cancel()"> Cancel</button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <button type="submit">Save </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="my-profile-box" >
                    <hr>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <h5>Education</h5>

                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group" *ngIf="!education">
                                <button (click)="educationFunction()">Add Education </button>
                            </div>
                            <div class="form-group" *ngIf="education">
                                <button (click)="educationFunction()">Edit Education </button>
                            </div>
                        </div>
                    </div>
                    <table class="table table-striped">
                        <tbody>
                           
                            <tr *ngFor="let e of educations">
                                <td colspan="2">
                                    <p class="text-right">  From {{e.inistution}},{{e.country.name}}</p>
                                </td>
                                <td colspan="2">
                                    <p class="text-right"> From {{e.inistution}},{{e.country.name}}</p>
                                </td>
                            </tr>
                            <tr>
                            </tr>
                        </tbody>
                    </table>
                    <div class="profile-form" *ngIf="education">
  
                        <form [formGroup]="eForm" (ngSubmit)="onEducationSubmit()">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <select formControlName="countryId"  class="form-control" [ngClass]="{ 'is-invalid': submitted && ef.countryId.errors }">
                                            <option>Select Country</option>
                                            <option value="{{country.id}}" *ngFor="let country of countires">{{country.nameE}}</option>
                                        </select>
                                        <div *ngIf="submitted && ef.countryId.errors" class="invalid-feedback">
                                            <div *ngIf="ef.countryId.errors.required">Please Select Country</div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-xl-12 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="inistution"  placeholder="Adama Sience and Technology Univiersity">
                                        <div *ngIf="submitted && ef.inistution.errors" class="invalid-feedback">
                                            <div *ngIf="ef.inistution.errors.required">Please enter Inistution Name</div>
                                        </div>
                                    </div>
                                </div>
                              
                                <div class="col-xl-4 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <select formControlName="degreeId"  class="form-control" [ngClass]="{ 'is-invalid': submitted && ef.degreeId.errors }">
                                            <option>Select Country</option>
                                            <option value="{{degree.id}}" *ngFor="let degree of degrees">{{degree.nameE}}</option>
                                        </select>
                                        <div *ngIf="submitted && ef.degreeId.errors" class="invalid-feedback">
                                            <div *ngIf="ef.degreeId.errors.required">Please Select Degree</div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div class="col-xl-8  col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="title"  placeholder="Computer Sience">
                                    </div>
                                    <div *ngIf="submitted && ef.title.errors" class="invalid-feedback">
                                        <div *ngIf="ef.title.errors.required">Please Select title</div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <select formControlName="yearOfGraguation"  class="form-control" [ngClass]="{ 'is-invalid': submitted && ef.countryId.errors }">
                                            <option>Select Year</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                            <option value="2016">2016</option>
                                            <option value="2015">2015</option>
                                            <option value="2014">2014</option>
                                            <option value="2013">2013</option>
                                            <option value="2012">2012</option>
                                            <option value="2011">2011</option>
                                            <option value="2010">2010</option>
                                            <option value="2009">2009</option>
                                            <option value="2008">2008</option>
                                            <option value="2007">2007</option>
                                            <option value="2006">2006</option>
                                            <option value="2005">2005</option>
                                            <option value="2004">2004</option>
                                            <option value="2003">2003</option>
                                            <option value="2002">2002</option>
                                            <option value="2001">2001</option>
                                            <option value="2000">2000</option>
                                            <option value="1999">1999</option>
                                            <option value="1998">1998</option>
                                            <option value="1997">1997</option>
                                            <option value="1996">1996</option>
                                            <option value="1995">1995</option>
                                            <option value="1994">1994</option>
                                            <option value="1993">1993</option>
                                            <option value="1992">1992</option>
                                            <option value="1991">1991</option>
                                            <option value="1990">1990</option>
                                            <option value="1989">1989</option>
                                            <option value="1988">1988</option>
                                            <option value="1987">1987</option>
                                            <option value="1986">1986</option>
                                            <option value="1985">1985</option>
                                            <option value="1984">1984</option>
                                            <option value="1983">1983</option>
                                            <option value="1982">1982</option>
                                            <option value="1981">1981</option>
                                            <option value="1980">1980</option>
                                            <option value="1979">1979</option>
                                            <option value="1978">1978</option>
                                            <option value="1977">1977</option>
                                            <option value="1976">1976</option>
                                            <option value="1975">1975</option>
                                            <option value="1974">1974</option>
                                            <option value="1973">1973</option>
                                            <option value="1972">1972</option>
                                            <option value="1971">1971</option>
                                            <option value="1970">1970</option>
        
                                        </select>
                                        <div *ngIf="submitted && ef.yearOfGraguation.errors" class="invalid-feedback">
                                            <div *ngIf="ef.yearOfGraguation.errors.required">Please Select country</div>
                                        </div>
                                    </div>
                                </div>
                           
                                <div class="col-lg-6 col-md-12 button button-warning">
                                    <div class="form-group">
                                        <button type="submit" (click)="cancel()"> Cancel</button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <button type="submit">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="my-profile-box" >
                    <hr>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <h5>Certafication</h5>

                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group" *ngIf="!certification">
                                <button (click)="certaficationFunction()">Add Certification </button>
                            </div>
                            <div class="form-group" *ngIf="certification">
                                <button (click)="certaficationFunction()">Edit Certification </button>
                            </div>
                        </div>
                    </div>
                    <table class="table table-striped">
                        <tbody>
                           
                            <tr *ngFor="let c of certifications">
                                <td colspan="2">
                                    <p class="text-right"> From {{c.inistution}}  on  {{c.grantedOn}}</p>
                                </td>
                                <td colspan="2">
                                    <p class="text-right">From {{c.inistution}}  on  {{c.grantedOn}}</p>
                                </td>
                            </tr>
                            <tr>
                            </tr>
                        </tbody>
                    </table>

                    <div class="profile-form" *ngIf="certification">
  
                        <form [formGroup]="cForm" (ngSubmit)="onCertificationSubmit()">
                            <div class="row">
                                <div class="col-xl-12  col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="certification"  placeholder="Certificate">
                                    </div>
                                    <div *ngIf="submitted && cf.certification.errors" class="invalid-feedback">
                                        <div *ngIf="cf.certification.errors.required">Please Select title</div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="inistution"  placeholder="Adama Sience and Technology Univiersity">
                                        <div *ngIf="submitted && cf.inistution.errors" class="invalid-feedback">
                                            <div *ngIf="cf.inistution.errors.required">Please enter Inistution Name</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <select formControlName="grantedOn"  class="form-control" [ngClass]="{ 'is-invalid': submitted && cf.countryId.errors }">
                                            <option>Select Year</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                            <option value="2016">2016</option>
                                            <option value="2015">2015</option>
                                            <option value="2014">2014</option>
                                            <option value="2013">2013</option>
                                            <option value="2012">2012</option>
                                            <option value="2011">2011</option>
                                            <option value="2010">2010</option>
                                            <option value="2009">2009</option>
                                            <option value="2008">2008</option>
                                            <option value="2007">2007</option>
                                            <option value="2006">2006</option>
                                            <option value="2005">2005</option>
                                            <option value="2004">2004</option>
                                            <option value="2003">2003</option>
                                            <option value="2002">2002</option>
                                            <option value="2001">2001</option>
                                            <option value="2000">2000</option>
                                            <option value="1999">1999</option>
                                            <option value="1998">1998</option>
                                            <option value="1997">1997</option>
                                            <option value="1996">1996</option>
                                            <option value="1995">1995</option>
                                            <option value="1994">1994</option>
                                            <option value="1993">1993</option>
                                            <option value="1992">1992</option>
                                            <option value="1991">1991</option>
                                            <option value="1990">1990</option>
                                            <option value="1989">1989</option>
                                            <option value="1988">1988</option>
                                            <option value="1987">1987</option>
                                            <option value="1986">1986</option>
                                            <option value="1985">1985</option>
                                            <option value="1984">1984</option>
                                            <option value="1983">1983</option>
                                            <option value="1982">1982</option>
                                            <option value="1981">1981</option>
                                            <option value="1980">1980</option>
                                            <option value="1979">1979</option>
                                            <option value="1978">1978</option>
                                            <option value="1977">1977</option>
                                            <option value="1976">1976</option>
                                            <option value="1975">1975</option>
                                            <option value="1974">1974</option>
                                            <option value="1973">1973</option>
                                            <option value="1972">1972</option>
                                            <option value="1971">1971</option>
                                            <option value="1970">1970</option>
        
                                        </select>
                                        <div *ngIf="submitted && cf.grantedOn.errors" class="invalid-feedback">
                                            <div *ngIf="cf.grantedOn.errors.required">Please select grantedOn</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 button button-warning">
                                    <div class="form-group">
                                        <button type="submit" (click)="cancel()"> Cancel</button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <button type="submit">Save Change</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="col-lg-8 col-md-12">
           
              <section class="listing-area">
                <div class="row">
                    <div class="col-xl-3 col-lg-6 col-md-6" *ngFor="let product of products ">
                        <div class="single-listings-box">
                            <div class="listings-image">
                                <div class="listings-image-slides">
                                    <img src="{{imageView}}{{product.avatorPath}}{{product.avator}}" alt="image" >
                                </div>
                                <a routerLink="/{{product.code}}" class="bookmark-save"><i class="flaticon-heart"></i></a>
                                <a routerLink="/{{product.code}}" class="category"><i class="flaticon-cooking"></i></a>
                            </div>
                            <div class="listings-content">
                                <div class="author">
                                    <div class="d-flex align-items-center">
                                        <img [src]="product.code" alt="image">
                                        <span>{{product.code}}</span>
                                    </div>
                                </div>
                                <span class="status {{product.name}}"><i class="flaticon-save"></i> {{product.name}}</span>
                                <div class="d-flex align-items-center justify-content-between">
                                    
                               
                                </div>
                            </div>
                            <div class="listings-footer">
                                <div class="d-flex justify-content-between align-items-center">
                                    <a routerLink="/gigdetail/{{product.code}}"  class="default-btn">Edit</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6 col-md-6">
                        <div class="single-listings-box">
                            <div class="listings-image">
                              
                            </div>
                            <div class="listings-content">
                                <div class="author">
                                    <div class="d-flex align-items-center">
                                      
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <a routerLink="/addproduct"  class="default-btn">Add Gig</a>
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    
                               
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>
    


</div>
<app-footer-style-two></app-footer-style-two>
<!-- End Main Content Wrapper Area -->