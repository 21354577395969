<!-- <app-navbar-style-two></app-navbar-style-two> -->
<app-topNav></app-topNav>

<app-sellerNav></app-sellerNav>
<div *ngIf="!account">
    <app-hometwo-banner></app-hometwo-banner>

<app-partner></app-partner>
</div>
<div *ngIf="account">
    <app-afterlogin></app-afterlogin>

<app-partner></app-partner>
</div>
<div class="board">
   
<div class="top">
    <h4>Top Selling Gig's </h4>
</div>
<app-products></app-products>
<div class="header">
    <h4>Most popular Gigs in Social Media Marketing </h4>
</div>
<app-products></app-products>
<div class="header">
    <h4>Inspired by your Search history</h4>
</div>
<app-products></app-products>
<section class="category-area pt-100 pb-70">
    <app-category></app-category>
</section>

<section class="feedback-area bg-image ptb-100">
    <app-feedback-style-one></app-feedback-style-one>
</section>

<!-- <app-hometwo-events></app-hometwo-events> -->

<section class="how-it-works-area pt-100 pb-70 bg-f9f9f9">
    <app-how-it-works></app-how-it-works>
</section>

<!-- <app-hometwo-blog></app-hometwo-blog> -->

<app-app-download></app-app-download>
</div>

<app-footer-style-two></app-footer-style-two>