
 <section class="listing-area">
    <div class="row">
      
        <div class="col-xl-4 col-lg-6 col-md-6"  *ngFor="let app of apps">
            <a routerLink="/productDetail/{{app.applcationId}}/{{app.applcationOrder.planId}}">
            <div class="single-listings-box">
                <div class="listings-image">
                    <div class="listings-image-slides">
                        <div class="post-image">
                            <a routerLink="/plan/{{app.applcationCode}}" class="d-block">
                                <img src="assets/img/onetapcare.jpg" alt="image">
                            </a>
                        </div>
                    </div>
                    <a routerLink="/{{app.applcationName}}" class="bookmark-save"></a>
                    <a routerLink="/{{app.applcationName}}" class="category" style="background-color: orange;"><i>Unpaid</i></a>
                </div>
                <div class="listings-content">
                     
                    <ul class="listings-meta">
                        <li><a routerLink="/{{app.applcationName}}"><i class="flaticon-furniture-and-household"></i> {{app.applcation.applcationCode}}</a></li>
                        <li><i class="flaticon-pin"></i> {{app.applcationOrder.price}}</li>
                    </ul>
                    <span class="status {{app.applcationName}}"><i class="flaticon-save"></i> {{app.applcation.applcationName}} </span>

                </div>
            </div>
            </a>
        </div>

       
    </div>
</section>



