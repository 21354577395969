<!-- Start Sidemenu Area -->
<div class="sidemenu-area">
    <div class="sidemenu-header">
        <a routerLink="/dashboard" class="navbar-brand d-flex align-items-center">
            <img src="assets/img/onetapwhite.png" alt="image">
        </a>
        <div class="responsive-burger-menu d-block d-lg-none">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
    </div>
    <div class="sidemenu-body">
        <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
            <li class="nav-item-title">
               <h5> Main</h5>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/bio" class="nav-link">
                    <span class="icon"><i class='bx bx-home-circle'></i></span>
                    <span class="menu-title"><b>Freelancer Bio</b></span>
                  
                    <p  class="menu-title"> Please add some bio Informations about yourself</p>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/address" class="nav-link">
                    <span class="icon"><i class='bx bx-home-circle'></i></span>
                    <span class="menu-title"><b>Freelancer Address</b></span>
                    <p  class="menu-title"> Please add some bio Informations about yourself</p>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/language" class="nav-link">
                    <span class="icon"><i class='bx bx-wallet'></i></span>
                    <span class="menu-title"><b>Language</b> </span>
                    <p  class="menu-title"> Please add some bio Informations about yourself</p>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/education" class="nav-link">
                    <span class="icon"><i class='bx bx-envelope-open'></i></span>
                    <span class="menu-title"><b>Education</b></span>
                    <p  class="menu-title"> Please add some bio Informations about yourself</p>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/skill" class="nav-link">
                    <span class="icon"><i class='bx bx-copy'></i></span>
                    <span class="menu-title"><b>Freelancer Skills</b></span>
                    <p  class="menu-title"> Please add some bio Informations about yourself</p>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/certafication" class="nav-link">
                    <span class="icon"><i class='bx bx-wallet'></i></span>
                    <span class="menu-title"><b>Certafication</b> </span>
                    <p  class="menu-title"> Please add some bio Informations about yourself</p>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/4reason" class="nav-link">
                    <span class="icon"><i class='bx bx-wallet'></i></span>
                    <span class="menu-title"><b>Top Reason</b> </span>
                    <p  class="menu-title"> Please add four main reason why your are cool</p>
                </a>
            </li>
            <li class="nav-item-title">
                <h5>Account</h5>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/profileinfo" class="nav-link">
                    <span class="icon"><i class='bx bxs-user-circle'></i></span>
                    <span class="menu-title">Account Information</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/changepassword" class="nav-link">
                    <span class="icon"><i class='bx bxs-user-circle'></i></span>
                    <span class="menu-title">Change Password</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/profileimage" class="nav-link">
                    <span class="icon"><i class='bx bxs-user-circle'></i></span>
                    <span class="menu-title">Profile Picture</span>
                </a>
            </li>
            <li class="nav-item">
                <a routerLink="/" class="nav-link">
                    <span class="icon"><i class='bx bx-log-out'></i></span>
                    <span class="menu-title">Logout</span>
                </a>
            </li>
        </ul>
    </div>
</div>
<!-- End Sidemenu Area -->