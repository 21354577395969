<app-seller-sidemenu></app-seller-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-freelancer-navbar></app-freelancer-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="row" *ngIf="!bios">
    
        <div class="col-lg-10 col-md-12" *ngIf="!submitted">
            <div class="my-profile-box">
                <h3>Freelancer Bio</h3>
    
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Motto</label>
                                <input type="text" class="form-control" formControlName="motto"  placeholder="">
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Website</label>
                                <input type="text" class="form-control" formControlName="website"  placeholder="ayer.com">
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Bio</label>
                                <textarea cols="30" rows="6" formControlName="bio"  placeholder="Short description about you..." class="form-control"></textarea>
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Facebook URL</label>
                                <input type="text" class="form-control" formControlName="facebookUrl"  placeholder="https://www.facebook.com/">
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Instagram URL</label>
                                <input type="text" class="form-control" formControlName="instagramUrl"  placeholder="https://instagram.com/">
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Linkedin URL</label>
                                <input type="text" class="form-control" formControlName="linkedInUrl"   placeholder="https://www.linkedin.com/">
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>TikTok URL</label>
                                <input type="text" class="form-control" formControlName="tiktokUrl"  placeholder="https://tiktok.com/">
                            </div>
                        </div>
    
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <button type="submit">Save Change</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- End -->
    <div class="row" *ngIf="bios">
        <alert></alert>
        <div class="col-lg-4 col-md-12">
            <div class="my-profile-box">
                <div class="author-sidebar">
                    <div class="user-profile">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/user3.jpg" alt="image">
   
                            <div class="title">
                                <h4>{{account.firstName}} {{account.lastName}}</h4>
                                <span class="sub-title">{{ bios.motto}}</span>
                                <div class="rating d-flex align-items-center">
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="rating-count">(5 reviews)</span>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="user-contact-info">
                        <h3>Contact</h3>
    
                        <ul class="user-contact">
                            <li><a href="#"><i class='bx bx-phone-call'></i> {{account.mobile}}</a></li>
                            <li><a href="#"><i class='bx bx-envelope'></i> {{account.email}}</a></li>
                        </ul>
    
                        <ul class="social-profiles">
                            <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                            <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            <li><a href="#" class="linkedin"><i class='bx bxl-linkedin'></i> LinkedIn</a></li>
                            <li><a href="#" class="youttube"><i class='bx bxl-youtube'></i> youtube</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="col-lg-6 col-md-12">

                <div class="my-profile-box">
                   
{{bios.bio}}
                    
                </div>
           
        </div>
    </div>
    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->