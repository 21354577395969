import { Component, OnInit } from '@angular/core';
import { MustMatch } from '../../../_helpers';
import { first } from 'rxjs/operators';
import { AccountService,ApplcationService,GuzoyeSettingsService,AlertService } from '../../../_services';
import { environment } from '../../../../environments/environment';
const imageUrl = `${environment.apiUrl}/preview`;
@Component({
  selector: 'app-dashboard-navbar',
  templateUrl: './dashboard-navbar.component.html',
  styleUrls: ['./dashboard-navbar.component.scss']
})
export class DashboardNavbarComponent implements OnInit {
  imageView = imageUrl;
  account = this.accountService.accountValue;
  constructor(
    private accountService: AccountService,
  ) { }

  ngOnInit(): void {
  }

}
