<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                   

                    <img src="assets/img/onetapblack.png" alt="image">
                </div>
            </div>

           

          

            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="footer-contact-info">
                        <li><i class="bx bx-map"></i>Yeka Sub City, Woreda 07, Rebcca Building, Addis Ababa/Ethiopia</li>
                        <li><i class="bx bx-phone-call"></i><a href="tel:+251111400810">+251-111-400-810</a></li>
                        <li><i class="bx bx-envelope"></i><a href="mailto:hello@onetap.et">hello@onetap.et</a></li>
                        <li><i class="bx bxs-inbox"></i><a href="tel:+251913712347">+251913712347</a></li>
                    </ul>

                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

    
    </div>


</footer>
<!-- End Footer Area -->

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>