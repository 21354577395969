<!-- Start Banner Area -->
<section class="banner-area">
    <div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="banner-content" *ngFor="let Content of bannerContent;">
                    <!-- <h1>{{Content.title}} 
                        <ngx-typed-js [strings]="['Website Designer', 'Logo Desiner', 'HR Consultant', 'Survey ', 'Shopping']" [typeSpeed]="100" [loop]="true" [backSpeed]="100" [showCursor]="false">
                            <span class="typing"></span>
                        </ngx-typed-js>
                    </h1> -->
                    <div  class="bannerText">
                        <h3>Find and Hire
                            the Best 
                             Freelancer
                            for your Business</h3>
                    </div>
                    
                    <form>
                        <div class="row m-0 align-items-center">
                            <div class="col-lg-10 col-md-12 p-0">
                                <div class="form-group">
                                    <label><i class="flaticon-search"></i></label>
                                    <input type="text" class="form-control" placeholder="What are you looking for?">
                                </div>
                            </div>

                            <!-- <div class="col-lg-3 col-md-6 p-0">
                                <div class="form-group">
                                    <label><i class="flaticon-pin"></i></label>
                                    <input type="text" class="form-control" placeholder="Location">
                                </div>
                            </div> -->

                            <!-- <div class="col-lg-3 col-md-6 p-0">
                                <div class="form-group category-select">
                                    <label><i class="flaticon-category"></i></label>
                                    <ngx-select-dropdown (searchChange)="searchChange($event)" name="category-select" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" [config]="config" [options]="options"></ngx-select-dropdown>
                                </div>
                            </div> -->

                            <div class="col-lg-2 col-md-12 p-0">
                                <div class="submit-btn">
                                    <button type="submit">Search </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <ul class="popular-search-list">
                        <li>Popular:</li>
                        <li *ngFor="let category of categories;"><a routerLink="/{{category.link}}">{{category.name}}</a></li>
                    </ul>
                </div>
            </div>
          
        </div>
    </div>
</section>

<!-- End Banner Area -->