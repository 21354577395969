
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>
 <div class="breadcrumb-area" >
                <ol class="breadcrumb">
                    <li class="item"><a routerLink="/">Overview</a></li>
                    <li class="item"><a routerLink="dashboard">Pricing</a></li>
                    <li class="item"><a routerLink="dashboard">Describtion</a></li>
                    <li class="item"><a routerLink="dashboard">Requirement</a></li>
                    <li class="item"><a routerLink="dashboard">Gallery</a></li>
                    <li class="item"><a routerLink="dashboard">FAQ</a></li>
                </ol>
            </div>
 
   
    <!-- End Breadcrumb Area -->
 <div class="row">
     
    <div class="col-md-8">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <!-- Start -->
            <div class="add-listings-box">
                <h3>Basic Informations</h3>
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label><i class='bx bx-briefcase-alt'></i> Listing Title:</label>
                            <input type="text" class="form-control" formControlName="name"  placeholder="Name of your business">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label><i class='bx bx-briefcase-alt'></i> Listing Title:</label>
                            <input type="text" class="form-control" formControlName="nameE"  placeholder="Name of your business">
                        </div>
                    </div>
        
        
                    <div class="col-lg-4 col-md-6">
                        <div class="form-group">
                            <label><i class='bx bx-duplicate'></i>  Category:</label>
                            <select formControlName="parentId"  [ngClass]="{ 'is-invalid': submitted && f.parentId.errors }" (change)="onCategorySelect($event.target.value)">
                                <option>Select Category</option>
                                <option value="{{category.id}}" *ngFor="let category of categories">{{category.nameE}}</option>
                            </select>
                            <div *ngIf="submitted && f.parentId.errors" class="invalid-feedback">
                                <div *ngIf="f.parentId.errors.required">Please Select Categories</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="form-group">
                            <label><i class='bx bx-duplicate'></i> Sub Category:</label>
                            <select formControlName="subId"  [ngClass]="{ 'is-invalid': submitted && f.subId.errors }" (change)="onsubCategorySelect($event.target.value)">
                                <option>Select Category</option>
                                <option value="{{sub.id}}" *ngFor="let sub of subCategories">{{sub.nameE}}</option>
                            </select>
                            <div *ngIf="submitted && f.subId.errors" class="invalid-feedback">
                                <div *ngIf="f.subId.errors.required">Please Select Categories</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="form-group">
                            <label><i class='bx bx-duplicate'></i>  Child Category:</label>
                            <select formControlName="categoryId"  [ngClass]="{ 'is-invalid': submitted && f.categoryId.errors }" >
                                <option>Select Category</option>
                                <option value="{{child.id}}" *ngFor="let child of subCategories">{{child.nameE}}</option>
                            </select>
                            <div *ngIf="submitted && f.categoryId.errors" class="invalid-feedback">
                                <div *ngIf="f.categoryId.errors.required">Please Select Categories</div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3>Description</h3>
            
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea cols="30" rows="6" formControlName="describtion"  placeholder="Short description about you..." class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="file-upload-box col-md-4">
                        <input 
                        formControlName="image"
                        id="image" 
                        type="file" 
                        class="dropzone"
                        (change)="onFileChange($event)">
                    <div *ngIf="f.image.touched && f.image.invalid" class="alert alert-danger">
                        <div *ngIf="f.image.errors.required">File is required.</div>
                    </div>
                   
                    </div>
                    <div class="col-md-8">
                        <img [src]="logoSrc" *ngIf="logoSrc" style="height: 200px; width:400px">
                    </div>
                   </div>
            </div>
        
           
                    
            <div class="add-listings-btn">
                <button type="submit">Submit Listings</button>
            </div>
            <!-- End -->
        </form>
    </div>
    <div class="col-md-4"></div>
 </div>

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->