<app-seller-sidemenu></app-seller-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-freelancer-navbar></app-freelancer-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="row">
    
        <div class="col-lg-10 col-md-12">
            <div class="my-profile-box">
                <h3>Freelancer Bio</h3>
    
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="row">
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label><i class='bx bx-duplicate'></i>  Language :</label>
                                <select formControlName="languageId"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.languageId.errors }">
                                    <option>Select Language</option>
                                    <option value="{{language.id}}" *ngFor="let language of languages">{{language.nameE}}</option>
                                </select>
                                <div *ngIf="submitted && f.languageId.errors" class="invalid-feedback">
                                    <div *ngIf="f.languageId.errors.required">Please Select Language</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label><i class='bx bx-duplicate'></i> Langague Level :</label>
                                <select formControlName="levelId" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.levelId.errors }" >
                                    <option>Select Language Level</option>
                                    <option value="{{level.id}}" *ngFor="let level of levels">{{level.nameE}}</option>
                                </select>
                                <div *ngIf="submitted && f.levelId.errors" class="invalid-feedback">
                                    <div *ngIf="f.levelId.errors.required">Please Select Levels</div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <button type="submit">Save Change</button>
                            </div>
                        </div>
                        <alert></alert>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-10 col-md-12">
            <div class="my-profile-box">
                <div class="col-lg-8">
                    <div class=" table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="header" colspan="2">Language</th>
                                    <th class="header" colspan="2">Language Level</th>
                                </tr>
                            </thead>
                            <tbody>
                               
                                <tr *ngFor="let ls of languageSkills">
                                    <td colspan="2">
                                        <p class="text-right">{{ls.language.name}}</p>
                                    </td>
                                    <td colspan="2">
                                        <p class="text-right">{{ls.level.name}}</p>
                                    </td>
                                </tr>
                                <tr>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
       
        </div>
        </div>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->