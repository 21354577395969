<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="vesax-responsive-nav">
        <div class="vesax-responsive-menu">
            <div class="responsive-burger-menu d-lg-none d-block">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
        </div>
    </div>
    <div class="vesax-nav">
        <nav class="navbar navbar-expand-md navbar-light">
            <div class="collapse navbar-collapse mean-menu">
                <ul class="navbar-nav">
                    <li class="nav-item"><a routerLink="/dashboard" class="nav-link"> <span class="icon"><i class='bx bx-home-circle'></i></span>Dashboard</a> </li>
                    <li class="nav-item"><a routerLink="/dashboard" class=" nav-link"> <span class="icon"><i class='bx bx-home-circle'></i></span>Order</a> </li>
                    <li class="nav-item"><a routerLink="/dashboard" class=" nav-link"> <span class="icon"><i class='bx bx-home-circle'></i></span>Messages</a> </li>
                    <li class="nav-item"><a routerLink="/dashboard" class=" nav-link"> <span class="icon"><i class='bx bx-home-circle'></i></span>Blogs</a> </li>
                    <li class="nav-item"><a routerLink="/dashboard" class=" nav-link"> <span class="icon"><i class='bx bx-home-circle'></i></span>Dashboard</a> </li>
                   
                </ul>
                <div class="others-option d-flex align-items-center">
                    <div class="option-item">
                        <div class="dropdown profile-nav-item">
                            <a href="#" class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="menu-profile">
                                    <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <span class="name">My Account</span>
                                </div>
                            </a>
                            <div class="dropdown-menu">
                                <div class="dropdown-header d-flex flex-column align-items-center">
                                    <div class="figure mb-3">
                                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    </div>

                                    <div class="info text-center">
                                        <span class="name">{{account.firstName}} {{account.lastName}}</span>
                                        <p class="mb-3 email">{{account.email}}</p>
                                    </div>
                                </div>
                                <div class="dropdown-body">
                                    <ul class="profile-nav p-0 pt-3">
                                        <li class="nav-item"><a routerLink="/dashboard" class="nav-link"><i class='bx bx-user'></i> <span>Dashboard</span></a></li>
                                        <li class="nav-item"><a routerLink="/dashboard-messages" class="nav-link"><i class='bx bx-envelope'></i> <span>Messages</span></a></li>
                                        <li class="nav-item"><a routerLink="/dashboard-bookings" class="nav-link"><i class='bx bx-edit-alt'></i> <span>Bookings</span></a></li>
                                        <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-cog'></i> <span>Settings</span></a></li>
                                    </ul>
                                </div>
                                <div class="dropdown-footer">
                                    <ul class="profile-nav">
                                        <li class="nav-item">
                                            <a routerLink="/" class="nav-link"><i class='bx bx-log-out'></i> <span>Logout</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </nav>
    </div>
    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <div class="dropdown profile-nav-item">
                                <a href="#" class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div class="menu-profile">
                                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                        <span class="name">My Account</span>
                                    </div>
                                </a>
                                <div class="dropdown-menu">
                                    <div class="dropdown-header d-flex flex-column align-items-center">
                                        <div class="figure mb-3">
                                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                        </div>
                                        <div class="info text-center">
                                            <span class="name">{{account.firstName}} {{account.lastName}}</span>
                                            <p class="mb-3 email">{{account.email}}</p>
                                        </div>
                                    </div>
                                    <div class="dropdown-body">
                                        <ul class="profile-nav p-0 pt-3">
                                            <li class="nav-item"><a routerLink="/dashboard" class="nav-link"><i class='bx bx-user'></i> <span>Dashboard</span></a></li>
                                            <li class="nav-item"><a routerLink="/dashboard-messages" class="nav-link"><i class='bx bx-envelope'></i> <span>Messages</span></a></li>
                                            <li class="nav-item"><a routerLink="/dashboard-bookings" class="nav-link"><i class='bx bx-edit-alt'></i> <span>Bookings</span></a></li>
                                            <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-cog'></i> <span>Settings</span></a></li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-footer">
                                        <ul class="profile-nav">
                                            <li class="nav-item"><a routerLink="/" class="nav-link"><i class='bx bx-log-out'></i> <span>Logout</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="option-item">
                            <a routerLink="/dashboard-add-listings" class="default-btn"><i class="flaticon-more"></i> Add Listing</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Navbar Area -->