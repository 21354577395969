<app-webNavBar></app-webNavBar>
<section class="pricing-area bg-f9f9f9 pt-100" style="padding: 5%;">

        <div class="pricing-tabs">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="monthly-tab" data-bs-toggle="tab" data-bs-target="#monthly" type="button" role="tab" aria-controls="monthly" aria-selected="true">Monthly</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="annually-tab" data-bs-toggle="tab" data-bs-target="#annually" type="button" role="tab" aria-controls="annually" aria-selected="false">Annually</button>
                </li>
            </ul>

            <div class="tab-content" id="myTabContent" >
                <div class="tab-pane fade show active" id="monthly" role="tabpanel">
                    <div class="row" *ngIf="app.app.prices">
                        <div class="col-lg-4 col-md-6 col-sm-6"  *ngFor="let p of app.app.prices;">
                            <div class="single-pricing-box" style="border-top-color: {{p.colorCode}};">
                                <h3 style="color: {{p.colorCode}};">{{p.plan}}</h3>
                                <div class="pricing-features">
                                    <ul>
                                        <li *ngFor="let f of p.planhints"><i class='{{f.icon}}'></i> {{f.feature}}</li>
                                    </ul>
                                </div>
                                <h3 class="price">
                                    <span id="super">ETB</span> {{p.price}} <span id="sub">/mo</span> 
                                </h3>
                              
                                <a routerLink="/planDetail/{{p.appCode}}/{{p.plan}}/{{p.planType}}" (click)="onClick()" class="default-btn" style="border-color: {{p.colorCode}};background-color: {{p.colorCode}};">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="annually" role="tabpanel">
                    <div class="row" *ngFor="let YearlyPricing of yearlyPricing;">
                        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Content of YearlyPricing.singlePricingBox">
                            <div class="single-pricing-box">
                                <h3>{{Content.title}}</h3>
                                <div class="pricing-features">
                                    <ul>
                                        <li *ngFor="let Features of Content.features"><i class='{{Features.icon}}'></i> {{Features.text}}</li>
                                    </ul>
                                </div>
                                <div class="price">
                                    {{Content.price}}
                                </div>
                                <a routerLink="/planDetail" class="default-btn">{{Content.buttonText}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
</section>

<!-- End Page Title Area -->
<section class="faq-area bg-f9f9f9" style="padding: 5%;"  >

    <div class="row" style="background-color: #FFF; padding: 7%;">
        <div class="col-lg-6 col-md-6" >
            <div class="faq-item">
                <a routerLink="/login/{{appCode}}/{{planId}}/{{period}}" class="default-btn">Start My Free month</a>
            </div>
        </div>

        <div class="col-lg-6 col-md-6" >
            <div class="faq-item"  *ngFor="let f of app.detail[0].appFeatures;">
                <p><strong> <i class='bx {{f.icon}}'></i>{{f.feature}}</strong></p>
                <p><strong></strong> {{f.describtion}}</p>
            </div>
        </div>
    </div>
</section>
<!-- Start FAQ Area -->
<section class="faq-area bg-f9f9f9" style="padding: 5%;">
        <div class="row" style="background-color: #FFF; padding: 7%;" *ngIf="app.detail[0].PlanFaqs">
            <h1>Frequently asked questions </h1>
            <div class="col-lg-4 col-md-4" *ngFor="let f of app.detail[0].PlanFaqs;">
                <div class="faq-item">
                    <p><strong> {{f.question}}</strong></p>
                    <p><strong></strong> {{f.answer}}</p>
                </div>
            </div>
        </div>
   
</section>
<app-footer-style-one></app-footer-style-one>
