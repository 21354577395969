import { Component, OnInit } from '@angular/core';
import { CategoryService,ProductService,AlertService } from '../../../../_services';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/operators';
const imageUrl = `${environment.apiUrl}/preview`;

@Component({
    selector: 'app-mygig',
    templateUrl: './mygig.component.html',
    styleUrls: ['./mygig.component.scss']
})
export class MyGigComponent implements OnInit {
    imageView = imageUrl;
    products;
    constructor(
        private productService: ProductService,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.productService.getAll()
        .pipe(first())
        .subscribe(products => this.products = products);
    }

    breadcrumb = [
        {
            title: 'My Gigs',
            subTitle: 'Dashboard'
        }
    ]

    pageTitleContent = [
        {
            title: 'My Gigs'
        }
    ]

 

}