import { Component, OnInit } from '@angular/core';
import { AccountService,ApplcationService } from '../../../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class AppHomeComponent implements OnInit {
  apps;
  constructor(
    private accountService: AccountService,
      private applcationService: ApplcationService,
      private route: ActivatedRoute,
      private router: Router,
  ) { }

  ngOnInit(): void {
    this.applcationService.getAll()
    .pipe(first())
    .subscribe(apps => this.apps = apps);
}
blogGrid: number = 1;

}
