<app-webNavBar></app-webNavBar>
         


            <section class="blog-area bg-f9f9f9 ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6"   *ngFor="let app of apps | paginate: { itemsPerPage: 6, currentPage: blogGrid }">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/plan/{{app.applcationCode}}" class="d-block">
                                        <img src="assets/img/onetapcare.jpg" alt="image">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <ul class="post-meta d-flex align-items-center">
                                        <li>
                                            <div class="d-flex align-items-center">
                                                
                                                <span>{{account.applcationCode}}</span>
                                            </div>
                                        </li>
                                        <li><i class="flaticon-calendar"></i> {{app.applcationCode}}</li>
                                    </ul>
                                    <h3><a routerLink="/plan/{{app.applcationCode}}">{{app.applcationName}}</a></h3>
                                    <a routerLink="/plan/{{app.applcationCode}}" class="link-btn"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>
                        </div>
            
                        <div class="col-lg-12 col-md-12">
                            <div class="pagination-area text-center">
                                <pagination-controls (pageChange)="blogGrid = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




