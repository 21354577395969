import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
        this.resetOption = [this.options[0]];
    }

    mainBannerContent = [
        {
            title: 'Hello & Welcome',
            paragraph: "COMMITTED TO IMPROVING PEOPLE'S LIVES THROUGH TECHNOLOGIES !!",
            popularSearchList: [
                {
                    title: 'Unfied Contact Center',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Business Process Outsourcing',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Sales Outsourcing',
                    link: 'grid-listings-left-sidebar'
                }
            ]
        }
    ]

    // Category Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config = {
        displayKey: "name",
        search: true
    };
    options = [
        // Type here your category name
        {
            name: "Unfied Contact Center",
        },
        {
            name: "Business Process Outsourcing",
        },
        {
            name: "Sales Outsourcing",
        },
        {
            name: "Bank",
        },
        {
            name: "Fitness",
        },
        {
            name: "Bookstore",
        }
    ];
    searchChange($event) {
        console.log($event);
    }
    reset() {
        this.resetOption = [];
    }

}