import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../../_helpers';
import { first } from 'rxjs/operators';
import { AccountService,ApplcationService,GuzoyeSettingsService,AlertService } from '../../../_services';

import { BankAccount } from 'src/app/_models';
@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
    form: FormGroup;
    submitted = false;
    loading = false;
    id : string;
    apps;
    BankAccounts;
    planId: string;
    appCode: string;
    period: string;
    selectedBank = 0;
    pricing;
    account = this.accountService.accountValue;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private applcationService: ApplcationService,
        private guzoyeSettingsService: GuzoyeSettingsService,
        private alertService: AlertService
    ) {
     }

    ngOnInit(): void {
        this.appCode = this.route.snapshot.params['appCode'];
        this.planId = this.route.snapshot.params['planId'];
        this.period = this.route.snapshot.params['period'];
        this.id ='21';
      
        this.guzoyeSettingsService.getBusinessAccountById(this.id)
        .pipe(first())
        .subscribe(BankAccounts => this.BankAccounts = BankAccounts);
        this.applcationService.getAllPricing(this.appCode,this.planId,this.period)
        .pipe(first())
        .subscribe(pricing => this.pricing = pricing);
        this.applcationService.getAppOrderByIds(this.appCode,this.planId,this.period,this.account.id)
        .pipe(first())
        .subscribe(apps => this.apps = apps);

        this.form = this.formBuilder.group({
            paymentMethod: ['', Validators.required],
            bankId: ['', Validators.required],
            accountName: ['', Validators.required],
            accountNumber: ['', Validators.required],
            amount: ['', Validators.required],
            mobile: ['', Validators.required],
            acceptTerms: ['', Validators.required],
            applcationOrderId: ['', Validators.required],
            applcationId: ['', Validators.required],
            accountId: this.account.id
        });

      


       
        

}
 onBankSelect(value) {
    this.selectedBank = value;
}


  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    // if (this.form.invalid) {
    //     return;
    // }
    
    this.loading = true;
        this.createCheckOut();
  
        console.log(this.form.value);
}


private createCheckOut() {
    this.applcationService.createCehckOut(this.form.value)
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Membership created successfully', { keepAfterRouteChange: true });
                this.router.navigate(['/appboard/'], { relativeTo: this.route });
              
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });

      
}


}