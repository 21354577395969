import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FreelancerService,GuzoyeSettingsService,AddressService,HrmService, AccountService,AlertService } from '../../../../_services';
import { MustMatch } from '../../../../_helpers';
import { Account } from 'src/app/_models';
import { environment } from '../../../../../environments/environment';
const imageUrl = `${environment.apiUrl}/preview`;


@Component({
    selector: 'app-education',
    templateUrl: './education.component.html',
    styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
    form: FormGroup;
    id: string;
    parents;
    categoryId;
    countires;
    degrees;
    educations;
    sub;
    logoSrc: string = '';
    imagePath: string='';
    isAddMode: boolean;
    loading = false;
    submitted = false;
    imageView = imageUrl;
    account = this.accountService.accountValue;
   
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private freelancerService: FreelancerService,
        private hrmService: HrmService,
        private addressService: AddressService,
        private guzoyeSettingsService: GuzoyeSettingsService,
        private alertService: AlertService
    ) {
        
    }

    ngOnInit() {
        this.addressService.getAllCountry()
        .pipe(first())
        .subscribe(countires => this.countires = countires);
        this.freelancerService.getAllDegree()
        .pipe(first())
        .subscribe(degrees => this.degrees = degrees);
        this.freelancerService.getAllEducation()
        .pipe(first())
        .subscribe(educations => this.educations = educations);
        this.form = this.formBuilder.group({
            title: ['', Validators.required],
            inistution: ['', Validators.required],
            qualification: ['', Validators.required],
            yearOfGraguation: ['', Validators.required],
            countryId: ['', Validators.required],
            degreeId: ['', Validators.required],
            accountId: this.account.id,
        });
    }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

      onSubmit() {
          this.submitted = true;
          // reset alerts on submit
          this.alertService.clear();
  
          // stop here if form is invalid
          // if (this.form.invalid) {
          //     return;
          // }
  
          this.loading = true;
              this.createproduct();
        
      }
  
      private createproduct() {
          this.hrmService.createEducation(this.form.value)
              .pipe(first())
              .subscribe({
                  next: () => {
                      this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                    //   this.router.navigate(['../'], { relativeTo: this.route });
                  },
                  error: error => {
                      this.alertService.error(error);
                      this.loading = false;
                  }
              });
      }
    
    breadcrumb = [
        {
            title: 'Freelancer Bio',
            subTitle: 'Dashboard'
        }
    ]

}