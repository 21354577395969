import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FreelancerService,GuzoyeSettingsService,HrmService ,AccountService,AlertService } from '../../../../_services';
import { MustMatch } from '../../../../_helpers';
import { Account } from 'src/app/_models';
import { environment } from '../../../../../environments/environment';
const imageUrl = `${environment.apiUrl}/preview`;


@Component({
    selector: 'app-profileImage',
    templateUrl: './profileImage.component.html',
    styleUrls: ['./profileImage.component.scss']
})
export class ProfileImageComponent implements OnInit {
    form: FormGroup;
    id: string;
    languages;
    levels;
    isAddMode: boolean;
    logoSrc: string = '';
    imagePath: string='';
    loading = false;
    submitted = false;
    imageView = imageUrl;
    account = this.accountService.accountValue;
   
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private freelancerService: FreelancerService,
        private guzoyeSettingsService: GuzoyeSettingsService,
        private hrmService: HrmService,
        private alertService: AlertService
    ) {
        
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            image: [null],
            avator: [null],
            imagePath:  [''],
            avatorPath:  ['/account/'],
            email: this.account.id,
        });
    }
    onFileChange(event) {
        const reader = new FileReader();
        
        if(event.target.files && event.target.files.length) {
          const [file] = event.target.files;
          reader.readAsDataURL(file);
        
          reader.onload = () => {
            var orginal = file.name
            this.imagePath= orginal.replace(/[^a-zA-Z0-9.]/g, '');
            this.logoSrc = reader.result as string;
            this.form.patchValue({
              image: file
            });
           
       
          };
       
        }
      }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

      onSubmit() {
          this.submitted = true;
          // reset alerts on submit
          this.alertService.clear();
  
          // stop here if form is invalid
          // if (this.form.invalid) {
          //     return;
          // }
  
          this.loading = true;
              this.createproduct();
        
      }
  
      private createproduct() {
        const formData = new FormData();
        formData.append('image', this.form.get('image').value);
        formData.append('avator', this.imagePath);
        formData.append('avatorPath', '/account/');
        formData.append('email', this.account.email);
          this.accountService.profilePicture(formData)
              .pipe(first())
              .subscribe({
                  next: () => {
                      this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                      this.router.navigate(['../'], { relativeTo: this.route });
                  },
                  error: error => {
                      this.alertService.error(error);
                      this.loading = false;
                  }
              });
      }
    
    breadcrumb = [
        {
            title: 'Freelancer Bio',
            subTitle: 'Dashboard'
        }
    ]

}