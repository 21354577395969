<!-- Start Navbar Area -->
<div class="navbar-area">
    <hr>
    <div class="ayer-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">


                <div class="collapse navbar-collapse mean-menu">
                

                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Graphics & Design</a>
                            <ul class="dropdown-menu" >
                                <div class="row">
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                </div>
                            </ul>
                            
                        </li>

                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Digital Marketing</a>
                            <ul class="dropdown-menu" >
                                <div class="row">
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Writing & Translation</a>
                            <ul class="dropdown-menu" >
                                <div class="row">
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Video & Animation</a>
                            <ul class="dropdown-menu" >
                                <div class="row">
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Music & Audio</a>
                            <ul class="dropdown-menu" >
                                <div class="row">
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                </div>
                            </ul>
                        </li>
                      
                    </ul>
                </div>
            </nav>
        </div>
    </div>
 
    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <form class="navbar-search-box">
                                <label><i class="flaticon-search"></i></label>
                                <input type="text" class="input-search" placeholder="What are you looking for?">
                            </form>
                        </div>

                        <div class="option-item">
                            <span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i class="flaticon-user"></i> Login / Register</span>
                        </div>

                        <div class="option-item">
                            <a routerLink="/dashboard-add-listings" class="default-btn"><i class="flaticon-more"></i> Add Listing</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<hr>