<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="ayer-responsive-nav">
        <div class="container">
            <div class="ayer-responsive-menu">
                <div class="logo">
                    <a routerLink="/index-2"><img src="assets/img/ayerblue.png" alt="logo"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="ayer-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/index-2"><img src="assets/img/ayerblue.png" alt="logo"></a>

                <div class="collapse navbar-collapse mean-menu">
                    <form class="navbar-search-box">
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="input-search" placeholder="What are you looking for?">
                    </form>

                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Graphics & Design</a>
                            <ul class="dropdown-menu" >
                                <div class="row">
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                </div>
                            </ul>
                            
                        </li>

                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Digital Marketing</a>
                            <ul class="dropdown-menu" >
                                <div class="row">
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Writing & Translation</a>
                            <ul class="dropdown-menu" >
                                <div class="row">
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Video & Animation</a>
                            <ul class="dropdown-menu" >
                                <div class="row">
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Music & Audio</a>
                            <ul class="dropdown-menu" >
                                <div class="row">
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                    <div class="col-md-6">
                                        <li class="nav-item" *ngFor="let category of categories"><a routerLink="/{{category.code}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b *ngIf="category.layer ==2">{{category.name}}</b> <span *ngIf="category.layer ==3">{{category.name}}</span>
                                        </a></li>
                                    </div>
                                </div>
                            </ul>
                        </li>
                      
                    </ul>

                    <div class="others-option d-flex align-items-center">

                        <div class="option-item">
                            <span data-bs-toggle="modal" data-bs-target="#loginRegisterModal" class="default-btn"><i class="flaticon-speaker"></i> Join</span>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <form class="navbar-search-box">
                                <label><i class="flaticon-search"></i></label>
                                <input type="text" class="input-search" placeholder="What are you looking for?">
                            </form>
                        </div>

                        <div class="option-item">
                            <span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i class="flaticon-user"></i> Login / Register</span>
                        </div>

                        <div class="option-item">
                            <a routerLink="/dashboard-add-listings" class="default-btn"><i class="flaticon-more"></i> Add Listing</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Start Login & Register Modal -->
<div class="modal fade loginRegisterModal" id="loginRegisterModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><i class='bx bx-x'></i></button>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <button class="nav-link active" id="login-tab" data-bs-toggle="tab" data-bs-target="#login" type="button" role="tab" aria-controls="login" aria-selected="true">Login</button>
                </li>
                <li class="nav-item">
                    <button class="nav-link" id="register-tab" data-bs-toggle="tab" data-bs-target="#register" type="button" role="tab" aria-controls="register" aria-selected="true">Register</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="login" role="tabpanel">
                    <div class="ayer-login">
                        <div class="login-with-account">
                         
                            <span>Login with</span>
                            <ul>
                                <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                                <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            </ul>
                        </div>
                        <span class="sub-title"><span>Or login with</span></span>
                        <alert></alert>
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <input type="email" formControlName="email" placeholder="Username or Email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                    <div *ngIf="f.email.errors.email">Email is invalid</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="password" formControlName="password"  placeholder="Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                            </div>
                         
                            <button type="submit">Login Now</button>
                           
                        </form>
                        <span class="dont-account">Don't have an account? <a href="#register">Register Now</a></span>
                    </div>
                </div>
                <div class="tab-pane fade" id="register" role="tabpanel">
                    <div class="ayer-register">
                        <div class="register-with-account">
                            <span>Register with</span>
                            <ul>
                                <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                                <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            </ul>
                        </div>
                        <alert></alert>
                        <span class="sub-title"><span>Or Register with</span></span>
                        <form [formGroup]="form2" (ngSubmit)="onRegistrationSubmit()">
                                   
                                  <div class="row">  
                                  <div class="form-group col-lg-6">
                                    <label>First Name</label>
                                    <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f2.firstName.errors }" />
                                    <div *ngIf="submitted && f2.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f2.firstName.errors.required">First Name is required</div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-6">
                                    <label>Last Name</label>
                                    <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f2.lastName.errors }" />
                                    <div *ngIf="submitted && f2.lastName.errors" class="invalid-feedback">
                                        <div *ngIf="f2.lastName.errors.required">Last Name is required</div>
                                    </div>
                                </div>
                              </div>
                              <div class="row">
                                  <div class="form-group col-lg-6">
                                      <label>Email</label>
                                      <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f2.email.errors }" />
                                      <div *ngIf="submitted && f2.email.errors" class="invalid-feedback">
                                          <div *ngIf="f2.email.errors.required">Email is required</div>
                                          <div *ngIf="f2.email.errors.email">Email is invalid</div>
                                      </div>
                                  </div>
                                    <div class="form-group col-lg-6">
                                      <label>Mobile</label>
                                      <input type="text" formControlName="mobile" class="form-control" [ngClass]="{ 'is-invalid': submitted && f2.mobile.errors }" />
                                      <div *ngIf="submitted && f2.mobile.errors" class="invalid-feedback">
                                          <div *ngIf="f2.mobile.errors.required">Mobile number is required</div>
                                          <div *ngIf="f2.mobile.errors?.pattern">Mobile number should be 10 digit start with 09</div>
                                      </div>
                                  </div>
                              </div> 
                       
                              <div class="form-row col-sm-12">
                                 <div class="row">
                                  <div class="form-group col-md-6">
                                      <label>Password</label>
                                      <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f2.password.errors }" />
                                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                          <div *ngIf="f2.password.errors.required">Password is required</div>
                                          <div *ngIf="f2.password.errors.minlength">Password must be at least 6 characters</div>
                                      </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                      <label>Confirm Password</label>
                                      <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f2.confirmPassword.errors }" />
                                      <div *ngIf="submitted && f2.confirmPassword.errors" class="invalid-feedback">
                                          <div *ngIf="f2.confirmPassword.errors.required">Confirm Password is required</div>
                                          <div *ngIf="f2.confirmPassword.errors.mustMatch">Passwords must match</div>
                                      </div>
                                  </div>
                                 </div>
                              </div>
                              <div class="form-group">
                                 
                                      <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" [ngClass]="{ 'is-invalid': submitted && f2.acceptTerms.errors }" />
                                 
                                      <label for="acceptTerms" class="form-check-label" >Accept Terms & Conditions</label>
                                 
                                  <div *ngIf="submitted && f2.acceptTerms.errors" class="invalid-feedback">Accept Ts & Cs is required</div>
                              </div>
                              
                         
                            <button  type="submit" class="signin">Sign Up</button>
                           
                          
                          </form>
                       
                        <span class="already-account">Already have an account? <a href="#">Login Now</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login & Register Modal -->