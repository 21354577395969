import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Category,SubCategory,ChildCategory } from '../_models/';

const baseUrl = `${environment.apiUrl}/category`;
const subCategoryUrl = `${environment.apiUrl}/subcategory`;
const childCategoryUrl = `${environment.apiUrl}/childcategory`;
@Injectable({ providedIn: 'root' })
export class CategoryService {
    private categorySubject: BehaviorSubject<Category>;
    public category: Observable<Category>;
    private subCategorySubject: BehaviorSubject<SubCategory>;
    public subCategory: Observable<SubCategory>;
    private childCategorySubject: BehaviorSubject<ChildCategory>;
    public childCategory: Observable<ChildCategory>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.categorySubject = new BehaviorSubject<Category>(null);
        this.category = this.categorySubject.asObservable();
        this.subCategorySubject = new BehaviorSubject<SubCategory>(null);
        this.subCategory = this.subCategorySubject.asObservable();
        this.childCategorySubject = new BehaviorSubject<ChildCategory>(null);
        this.childCategory = this.childCategorySubject.asObservable();
    }

    public get categoryValue(): Category {
        return this.categorySubject.value;
    }
    public get subCategoryValue(): Category {
        return this.subCategorySubject.value;
    }
    public get childCategoryValue(): Category {
        return this.childCategorySubject.value;
    }

    getAll() {
        return this.http.get<Category[]>(baseUrl);
    }
    getAllCategory() {
        return this.http.get<Category[]>(`${baseUrl}/menu`);
    }
    getCategory(parentId) {
        return this.http.get<Category[]>(`${baseUrl}/all/${parentId}`);
    }
    getCategoryLayer(layer) {
        return this.http.get<Category[]>(`${baseUrl}/layer/${layer}`);
    }

    getById(id: string) {
        return this.http.get<Category>(`${baseUrl}/${id}`);
    }
    
    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((category: any) => {
                    // publish updated category to subscribers
                    category = { ...this.categoryValue, ...category };
                    this.categorySubject.next(category);
              
                return category;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    getAllSubCategory() {
        return this.http.get<Category[]>(subCategoryUrl);
    }
    getSubCategory() {
        return this.http.get<SubCategory[]>(subCategoryUrl);
    }
    getAllSubCategoryById(parentId: string) {
        return this.http.get<Category[]>(`${baseUrl}/sub/${parentId}`);
    }
    getAllChildCategoryById(subId: string) {
        return this.http.get<Category[]>(`${baseUrl}/child/${subId}`);
    }
    getSubById(id: string) {
        return this.http.get<SubCategory>(`${subCategoryUrl}/${id}`);
    }
    
    createSub(params) {
        return this.http.post(subCategoryUrl, params);
    }
    
    updateSub(id, params) {
        return this.http.put(`${subCategoryUrl}/${id}`, params)
            .pipe(map((subcategory: any) => {
                    // publish updated category to subscribers
                    subcategory = { ...this.subCategoryValue, ...subcategory };
                    this.categorySubject.next(subcategory);
              
                return subcategory;
            }));
    }
    deleteSub(id: string) {
        return this.http.delete(`${subCategoryUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    getAllChildCategory() {
        return this.http.get<ChildCategory[]>(childCategoryUrl);
    }
    getChildCategory(sortOrder) {
        return this.http.get<ChildCategory[]>(`${childCategoryUrl}/sortOrder/${sortOrder}`);
    }

    getChildCategoryById(id: string) {
        return this.http.get<ChildCategory>(`${childCategoryUrl}/${id}`);
    }
    // getAllChildCategoryById(id: string) {
    //     return this.http.get<ChildCategory[]>(`${childCategoryUrl}/category/${id}`);
    // }
    createChildCateogry(params) {
        return this.http.post(childCategoryUrl, params);
    }
    
    updateChildCategory(id, params) {
        return this.http.put(`${childCategoryUrl}/${id}`, params)
            .pipe(map((childCategory: any) => {
                    // publish updated category to subscribers
                    childCategory = { ...this.childCategoryValue, ...childCategory };
                    this.categorySubject.next(childCategory);
              
                return childCategory;
            }));
    }
    deleteChildCategory(id: string) {
        return this.http.delete(`${childCategoryUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   

    
}