<div class="container mt-5" id="container">

  <div class="form-container sign-in-container">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <h1 class="font-weight-bold">Create Account</h1>
              
            <div class="row">  
            <div class="form-group col-lg-6">
              <label>First Name</label>
              <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
              <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                  <div *ngIf="f.firstName.errors.required">First Name is required</div>
              </div>
          </div>
          <div class="form-group col-lg-6">
              <label>Last Name</label>
              <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
              <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                  <div *ngIf="f.lastName.errors.required">Last Name is required</div>
              </div>
          </div>
        </div>
        <div class="row">
            <div class="form-group col-lg-6">
                <label>Email</label>
                <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email is invalid</div>
                </div>
            </div>
              <div class="form-group col-lg-6">
                <label>Mobile</label>
                <input type="text" formControlName="mobile" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                    <div *ngIf="f.mobile.errors.required">Mobile number is required</div>
                    <div *ngIf="f.mobile.errors?.pattern">Mobile number should be 10 digit start with 09</div>
                </div>
            </div>
        </div> 
        
        <div class="form-group col-md-12">
          <label>Tin Number</label>
          <input type="text" formControlName="tinNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.tinNumber.errors }" />
          <div *ngIf="submitted && f.tinNumber.errors" class="invalid-feedback">
              <div *ngIf="f.tinNumber.errors.required">tinNumber  is required</div>
          </div>
      </div>
    
 
        <div class="form-row col-sm-12">
           <div class="row">
            <div class="form-group col-md-6">
                <label>Password</label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                    <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label>Confirm Password</label>
                <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                </div>
            </div>
           </div>
        </div>
        <div class="form-group">
           
                <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
           
                <label for="acceptTerms" class="form-check-label" >Accept Terms & Conditions</label>
           
            <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Accept Ts & Cs is required</div>
        </div>
        <alert></alert>
   
      <button class="signin">Sign Up</button>
     
    
    </form>
  </div>

  <div class="overlay-container">
    <div class="overlay">
      <div class="overlay-panel overlay-right">
          <h1 class="font-weight-bold">Good to see you!</h1>
          <p> You already have an account? <br>Sign in!</p>
       <a routerLink='/login/{{appCode}}/{{planId}}/{{period}}'>
             <button class="signup" id="signUp">Sign In</button>
       </a> 
      </div>
    </div>
  </div>
</div>
