<app-navbar-style-two></app-navbar-style-two>
<!-- Start Blog Details Area -->
<section class="blog-details-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="{{imageView}}{{apps.product[0].avatorPath}}{{apps.product[0].avator}}">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li>
                                    <i class='bx bx-folder-open'></i>
                                    <span>Category</span>
                                    <a routerLink="/blog-grid">Vesax</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>View</span>
                                    813,454
                                </li>
                                <li>
                                    <i class='bx bx-calendar'></i>
                                    <span>Last Updated</span>
                                    Aug 15, 2020
                                </li>
                            </ul>
                        </div>
                        <h3>{{apps.product[0].name}}</h3>
                        
                    <div [innerHTML]="apps.product[0].describtion"></div>
                      
                       <div class="row" *ngIf="topreasons">
                        <h3>4 Reason To Choose Us</h3>
                        <ul class="features-list" *ngFor="let top of topreasons">
                            <li> 
                                <div class="col-md-2">
                                    <i class='bx bx-badge-check'></i>
                                </div>
                                <div class="col-md-10">
                                    <h5> {{top.title}}</h5>
                                    {{top.detail}}
                                </div>
                            </li>
                        </ul>
                       </div>
                        <div class="article-author">
                            <div class="author-profile-header"></div>
                            <div class="author-profile">
                                <div class="author-profile-title">
                                    <img src="{{imageView}}{{apps.product[0].avatorPath}}{{apps.product[0].avator}}" class="shadow-sm" alt="image">
    
                                    <h4>{{apps.product[0].name}}</h4>
                                    <span class="d-block">{{apps.product[0].name}}</span>
                                    <div class="rating d-flex align-items-center">
                                        <span class="bx bxs-star checked"></span>
                                        <span class="bx bxs-star checked"></span>
                                        <span class="bx bxs-star checked"></span>
                                        <span class="bx bxs-star checked"></span>
                                        <span class="bx bxs-star checked"></span>
                                        <span class="rating-count">(5 reviews)</span>
                                    </div>
                                    <div [innerHTML]="apps.product[0].name"></div>
                                </div>
                            </div>
                        </div>
                        <div class=" table-responsive">
                            <table class="table table-bordered">
                          
                                <tbody>
                                    <tr>
                                        <td colspan="2">
                                            <p class="text-right">From</p>
                                            <p class="text-right">{{address.city.name}},{{address.country.name}}</p>
                                        </td>
                                        <td colspan="2">
                                            <p class="text-right">Member Since</p>
                                            <p class="text-right">{{apps.product[0].name}}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <p class="text-right">Avg. response time</p>
                                            <p class="text-right">1 hour</p>
                                        </td>
                                        <td colspan="2">
                                            <p class="text-right">Last delivery</p>
                                            <p class="text-right">1 days</p>
                                        </td>
                                    </tr>
                                    <tr>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-purchase-tag'></i></span>
                            <a routerLink="/blog-grid">{{apps.product[0].name}}</a>,
                            <a routerLink="/blog-grid">{{apps.product[0].name}}</a>,
                            <a routerLink="/blog-grid"> {{apps.product[0].name}}</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <!-- <div class="comments-area">
                        <h3 class="comments-title">2 Comments:</h3>

                        <ol class="comment-list">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/user1.jpg" class="avatar" alt="image">
                                            <b class="fn">John Jones</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <span>April 24, 2019 at 10:59 am</span>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/user2.jpg" class="avatar" alt="image">
                                                    <b class="fn">Steven Smith</b>
                                                    <span class="says">says:</span>
                                                </div>
    
                                                <div class="comment-metadata">
                                                    <span>April 24, 2019 at 10:59 am</span>
                                                </div>
                                            </footer>
    
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                            </div>
    
                                            <div class="reply">
                                                <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>

                                        <ol class="children">
                                            <li class="comment">
                                                <article class="comment-body">
                                                    <footer class="comment-meta">
                                                        <div class="comment-author vcard">
                                                            <img src="assets/img/user3.jpg" class="avatar" alt="image">
                                                            <b class="fn">Sarah Taylor</b>
                                                            <span class="says">says:</span>
                                                        </div>
            
                                                        <div class="comment-metadata">
                                                            <span>April 24, 2019 at 10:59 am</span>
                                                        </div>
                                                    </footer>
            
                                                    <div class="comment-content">
                                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                    </div>
            
                                                    <div class="reply">
                                                        <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                                    </div>
                                                </article>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>

                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/user4.jpg" class="avatar" alt="image">
                                            <b class="fn">John Doe</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <span>April 24, 2019 at 10:59 am</span>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/user1.jpg" class="avatar" alt="image">
                                                    <b class="fn">James Anderson</b>
                                                    <span class="says">says:</span>
                                                </div>
    
                                                <div class="comment-metadata">
                                                    <span>April 24, 2019 at 10:59 am</span>
                                                </div>
                                            </footer>
    
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                            </div>
    
                                            <div class="reply">
                                                <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                   

                    <section class="widget widget_vesax_posts_thumb">
                        <h3 class="widget-title">Pricing Plan</h3>

                        <div class="d-flex justify-content-between align-items-center"  *ngIf="!apps.price[0]">
                            <a routerLink="/plan/{{apps.product[0].code}}"  class="default-btn">Add Pricing Plan</a>
                        </div>
                            <div class="products-details-tabs" *ngIf="apps.price[0]">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true" style="color: {{apps.price[0].colorCode}};">{{apps.price[0].plan}}</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#faq" type="button" role="tab" aria-controls="faq" aria-selected="false" style="color: {{apps.price[1].colorCode}};">{{apps.price[1].plan}} </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false" style="color: {{apps.price[2].colorCode}};">{{apps.price[2].plan}}</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="description" role="tabpanel">
                                  
                                        <div class="products-review-comments">
                                         
                                            <div class="user-review"  *ngFor="let f of apps.product[0]">
                                               
                                                <img src="{{imageView}}{{apps.product[0].avatorPath}}{{apps.product[0].avator}}" alt="image">
                                               
                                                <p><span class="d-block sub-comment">{{f.name}}</span></p>
                                                <p>{{f.name}}</p>
                                            </div>
                                            <div class="col-lg-12 ">
                                                <div class="single-pricing-box" style="border-top-color: {{apps.price[0].colorCode}};">
                                                    <h5 style="color:{{apps.price[0].colorCode}};">{{apps.price[0].plan}}</h5>
                                                    <div class="row">
                                                        <h5 class="col-md-6">{{apps.price[0].planType}}</h5>
                                                        <p class="col-md-6">{{apps.price[0].price}} Br.</p>
                                                    </div>
                                                    <p>{{apps.price[0].tagline}}</p>
                                                    <hr>
                                                    <div class="article-content">
                                                        <ul class="features-list">
                                                            <li *ngFor="let f of apps.price[0].planhints">
                                                                <i class='{{f.icon}}'></i> {{f.feature}}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <h3 class="price">
                                                        <span id="super">ETB</span> {{apps.price[0].price}} <span id="sub">/mo</span> 
                                                    </h3>
                                                    <a routerLink="/planDetail/{{apps.price[0].appCode}}/{{apps.price[0].id}}/{{apps.price[0].planType}}"  class="default-btn" style="border-color: {{apps.price[0].colorCode}};background-color: {{apps.price[0].colorCode}};">Learn More</a>
                                                </div>
                                            </div>
                                        </div>
                                   
                                    </div>
                                    <div class="tab-pane fade show " id="faq" role="tabpanel">
                                  
                                        <div class="products-review-comments">
                                         
                                            <div class="user-review"  *ngFor="let f of apps.product[0]">
                                               
                                                <img src="{{imageView}}{{apps.product[1].avatorPath}}{{apps.product[1].avator}}" alt="image">
                                               
                                                <p><span class="d-block sub-comment">{{f.name}}</span></p>
                                                <p>{{f.name}}</p>
                                            </div>
                                            <div class="col-lg-12 ">
                                                <div class="single-pricing-box" style="border-top-color: {{apps.price[1].colorCode}};">
                                                    <h3 style="color:{{apps.price[1].colorCode}};">{{apps.price[1].plan}}</h3>
                                                    <div class="row">
                                                        <h3 class="col-md-6">{{apps.price[1].planType}}</h3>
                                                        <p class="col-md-6">{{apps.price[1].price}} Br.</p>
                                                    </div>
                                                    <p>{{apps.price[1].tagline}}</p>
                                                    <hr>
                                                    <div class="pricing-features">
                                                        <ul>
                                                            <li *ngFor="let f of apps.price[1].planhints"><i class='{{f.icon}}'></i> {{f.feature}}</li>
                                                        </ul>
                                                    </div>
                                                    <h3 class="price">
                                                        <span id="super">ETB</span> {{apps.price[1].price}} <span id="sub">/mo</span> 
                                                    </h3>
                                                    <a routerLink="/planDetail/{{apps.price[1].appCode}}/{{apps.price[1].id}}/{{apps.price[1].planType}}"  class="default-btn" style="border-color: {{apps.price[1].colorCode}};background-color: {{apps.price[1].colorCode}};">Learn More</a>
                                                </div>
                                            </div>
                                        </div>
                                   
                                    </div>
                                   
                                    <div class="tab-pane fade show " id="reviews" role="tabpanel">
                                  
                                        <div class="products-review-comments">
                                         
                                            <div class="user-review"  *ngFor="let f of apps.product[0]">
                                               
                                                <img src="{{imageView}}{{apps.product[2].avatorPath}}{{apps.product[2].avator}}" alt="image">
                                               
                                                <p><span class="d-block sub-comment">{{f.name}}</span></p>
                                                <p>{{f.name}}</p>
                                            </div>
                                            <div class="col-lg-12 ">
                                                <div class="single-pricing-box" style="border-top-color: {{apps.price[1].colorCode}};">
                                                    <h3 style="color:{{apps.price[1].colorCode}};">{{apps.price[2].plan}}</h3>
                                                    <div class="row">
                                                        <h3 class="col-md-6">{{apps.price[2].planType}}</h3>
                                                        <p class="col-md-6">{{apps.price[2].price}} Br.</p>
                                                    </div>
                                                    <p>{{apps.price[1].tagline}}</p>
                                                    <hr>
                                                    <div class="pricing-features">
                                                        <ul>
                                                            <li *ngFor="let f of apps.price[2].planhints"><i class='{{f.icon}}'></i> {{f.feature}}</li>
                                                        </ul>
                                                    </div>
                                                    <h3 class="price">
                                                        <span id="super">ETB</span> {{apps.price[2].price}} <span id="sub">/mo</span> 
                                                    </h3>
                                                    <a routerLink="/planDetail/{{apps.price[1].appCode}}/{{apps.price[1].id}}/{{apps.price[1].planType}}"  class="default-btn" style="border-color: {{apps.price[1].colorCode}};background-color: {{apps.price[1].colorCode}};">Learn More</a>
                                                </div>
                                            </div>
                                        </div>
                                   
                                    </div>
                                </div>
                            </div>
                           
                        
                    </section>

                    <section class="widget widget_instagram">
                        <ul>
                            <li>
                                <div class="box">
                                    <img src="assets/img/blog/blog9.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                            <li>
                                <div class="box">
                                    <img src="assets/img/blog/blog8.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                            <li>
                                <div class="box">
                                    <img src="assets/img/blog/blog7.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                            <li>
                                <div class="box">
                                    <img src="assets/img/blog/blog6.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                            <li>
                                <div class="box">
                                    <img src="assets/img/blog/blog5.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                            <li>
                                <div class="box">
                                    <img src="assets/img/blog/blog4.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                        </ul>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>

<app-copyrights></app-copyrights>