
<nav class="navbar top-navbar navbar-expand-md" >
    <div class="navbar-header">
        <a class="navbar-brand" routerLink="/">
            <!-- Logo icon -->
            <b class="logo-icon">
                <img src="assets/img/onetaplogo.png" alt="homepage" class="light-logo" />
            </b>
        </a>
    </div>
    <ul class="navbar-nav float-left mr-auto navbar-left">
        <li class="nav-item">
            <a  routerLink="/dashboard" class="nav-link waves-effect waves-dark"[routerLinkActive]="'active'"  href="javascript:void(0)">
                <span class="d-md-block">  <img width="20px" src="../assets/img/header-icons/MainDashboard.svg">Dashboard
                </span>
            </a>
        </li>
        <li class="nav-item" >
            <a routerLink="/business"  class="nav-link waves-effect waves-dark" routerLinkActive="active" href="javascript:void(0)">
                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainSales.svg">MarketPlace
          </span>
            </a>
        </li>
        <li class="nav-item">
            <a routerLink="/guzoye" [routerLinkActive]="'active'"  class="nav-link waves-effect waves-dark" href="javascript:void(0)">
                <span class="d-md-block"> <img width="30px" src="../assets/img/header-icons/MainCustomers.svg">Guzoye
        </span>
            </a>
        </li>
        <li class="nav-item">
            <a routerLink="/hrm" [routerLinkActive]="'active'"  class="nav-link waves-effect waves-dark" href="javascript:void(0)">
                <span class="d-md-block"> <img width="30px" src="../assets/img/header-icons/MainCustomers.svg">HRM
        </span>
            </a>
        </li>
        <li class="nav-item">
            <a routerLink="/catalog" [routerLinkActive]="'active'"  class="nav-link waves-effect waves-dark" href="javascript:void(0)">
                <span class="d-md-block"> <img width="30px" src="../assets/img/header-icons/MainCustomers.svg">Catalog
        </span>
            </a>
        </li>
        <li class="nav-item" >
            <a routerLink="/accounts" [routerLinkActive]="'active'"  class="nav-link waves-effect waves-dark" href="javascript:void(0)">
                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainMarketplace.svg">Account</span>
            </a>
        </li>
        <li class="nav-item" >
            <a routerLink="/outlet" [routerLinkActive]="'active'" class="nav-link waves-effect waves-dark"  href="javascript:void(0)">
                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainCMS.svg">outlet </span>
            </a>
        </li>
        <li class="nav-item">
            <a routerLink="/orders" [routerLinkActive]="'active'" class="nav-link waves-effect waves-dark"  href="javascript:void(0)">
                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainServices.svg"> Orders</span>
            </a>
        </li>
        <li class="nav-item">
            <a routerLink="/address" [routerLinkActive]="'active'" class="nav-link waves-effect waves-dark"  href="javascript:void(0)">
                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainServices.svg"> Address</span>
            </a>
        </li>
        <li class="nav-item">
            <a routerLink="/settings" [routerLinkActive]="'active'" class="nav-link waves-effect waves-dark"  href="javascript:void(0)">
                <span class="d-md-block"> <img width="20px" src="../assets/img/header-icons/MainServices.svg"> Setting</span>
            </a>
        </li>
    </ul>
</nav>
