<!-- Start Category Area -->
<div class="container">
    <div class="section-title" *ngFor="let Title of sectionTitle;">
        <h2>{{Title.title}}</h2>
        <p>{{Title.paragraph}}</p>
    </div>

    <div class="row">
        <div class="col-lg-2 col-sm-6 col-md-4" *ngFor="let category of categories;">
            <div class="single-category-box">
                <div class="icon">
                    <!-- <img src="{{imageView}}{{category.imagePath}}{{category.avator}}"  class="icon"> -->
                    <i class="flaticon-category"></i>
                </div>
               
                
                
                <h3>{{category.nameE}}</h3>
                <a routerLink="/{{category.name}}" class="link-btn"></a>
            </div>
        </div>
    </div>
</div>
<!-- End Category Area -->