<!-- Start Listings Area -->
<section class="listing-area">
    <div class="row">
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let product of products | paginate: { itemsPerPage: 6, currentPage: gridListings }">
        
                <a routerLink="/detail/{{product.code}}">
                    <div class="single-listings-box">
                        <div class="listings-image">
                            <img src="{{imageView}}{{product.avatorPath}}{{product.avator}}" alt="image" >
                            <a routerLink="/{{product.name}}" class="bookmark-save"><i class="flaticon-heart"></i></a>
                        </div>
                        <div class="listings-content">
                            <div class="author">
                                <div class="d-flex align-items-center">
                                    <img src="{{imageView}}{{product.avatorPath}}{{product.avator}}" alt="image" >
                                    <!-- <span>{{product.code}}</span> -->
                                </div>
                            </div>
                            <span class="status {{product.path}}"><i class="flaticon-save"></i> {{product.name}}</span>
                            <hr>
                            <div class="d-flex justify-content-between">
                                
                                <div class="rating">
                                    <span class="count">****</span>
                                </div>
                                <div class="price">
                                    STARTING at 100 Br
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </ng-template>
            
        </owl-carousel-o>

       

      
    </div>
</section>
<!-- End Listings Area -->