import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-feedback-style-one',
    templateUrl: './feedback-style-one.component.html',
    styleUrls: ['./feedback-style-one.component.scss']
})
export class FeedbackStyleOneComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: 'User’s Feedback About Us',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.'
        }
    ]
    singleFeedbackBox = [
        {
            paragraph: 'I am impressed with their experience & work ethics and standard procedures which easie all our hassle. We have been working with OneTap since 2018 and we are excited to continue working with this amazing team.',
            authorImg: 'assets/img/user1.jpg',
            authorName: 'Elias Amare',
            authorDesignation: 'Operation Manager',
            rating: [
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                }
            ]
        },
        {
            paragraph: 'I am impressed with their experience & work ethics and standard procedures which easie all our hassle. We have been working with OneTap since 2018 and we are excited to continue working with this amazing team. ',
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Elias Amare',
            authorDesignation: 'Operation Manager',
            rating: [
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star-half'
                }
            ]
        },
        {
            paragraph: 'I am impressed with their experience & work ethics and standard procedures which easie all our hassle. We have been working with OneTap since 2018 and we are excited to continue working with this amazing team.',
            authorImg: 'assets/img/user3.jpg',
            authorName: 'Elias Amare',
            authorDesignation: 'Operation Manager',
            rating: [
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bx-star'
                }
            ]
        },
        {
            paragraph: 'I am impressed with their experience & work ethics and standard procedures which easie all our hassle. We have been working with OneTap since 2018 and we are excited to continue working with this amazing team.',
            authorImg: 'assets/img/user4.jpg',
            authorName: 'Elias Amare',
            authorDesignation: 'Operation Manager',
            rating: [
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star-half'
                },
                {
                    icon: 'bx bx-star'
                }
            ]
        }
    ]

    customOptions: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        items: 4,
        margin: 30,
        navText: [
            "<i class='flaticon-left-chevron'></i>",
            "<i class='flaticon-right-chevron'></i>"
        ],
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            }
        },
    }

}