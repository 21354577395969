import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-partner',
    templateUrl: './partner.component.html',
    styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
    
    sectionTitle = [
        {
            title: 'Our Partners',
            paragraph: 'All the hassle & pain in your day to day life is an opportunity to innovate and come up with a better way of doing things. Would you like to know how? Give us a chance we are ready to help'
        }
    ]
    partnerItem = [
        {
            img: 'assets/img/partner/chamber.png',
            link: '#'
        },
        {
            img: 'assets/img/partner/coop.jpg',
            link: '#'
        },
        {
            img: 'assets/img/partner/abay.png',
            link: '#'
        },
        {
            img: 'assets/img/partner/dbe.png',
            link: '#'
        },
        {
            img: 'assets/img/partner/noah.jpg',
            link: '#'
        },
        {
            img: 'assets/img/partner/selambus.jpg',
            link: '#'
        }, {
            img: 'assets/img/partner/coop.jpg',
            link: '#'
        },
        {
            img: 'assets/img/partner/abay.png',
            link: '#'
        },
        {
            img: 'assets/img/partner/dbe.png',
            link: '#'
        },
        {
            img: 'assets/img/partner/noah.jpg',
            link: '#'
        },
        {
            img: 'assets/img/partner/selambus.jpg',
            link: '#'
        }
    ]
    customOptions: OwlOptions = {
		loop: true,
		nav: false,
        dots: false,
        lazyLoad: true,
		autoplayHoverPause: true,
        autoplay: true,
        margin: 0,
        items:6,
        navText: [
            "<i class='flaticon-left-chevron'></i>",
            "<i class='flaticon-right-chevron'></i>"
        ],
        responsive: {
			0: {
				items: 6,
			},
			576: {
				items: 10,
			},
			768: {
				items: 10,
			},
			1200: {
				items: 10,
			}
        },
    }

}