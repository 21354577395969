import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FreelancerService,GuzoyeSettingsService,HrmService ,AccountService,AlertService } from '../../../../_services';
import { MustMatch } from '../../../../_helpers';
import { Account } from 'src/app/_models';
import { environment } from '../../../../../environments/environment';
const imageUrl = `${environment.apiUrl}/preview`;


@Component({
    selector: 'app-4reason',
    templateUrl: './4reason.component.html',
    styleUrls: ['./4reason.component.scss']
})
export class FourReasonComponent implements OnInit {
    form: FormGroup;
    id: string;
    topReasons;
    isAddMode: boolean;
    loading = false;
    submitted = false;
    imageView = imageUrl;
    account = this.accountService.accountValue;
   
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private freelancerService: FreelancerService,
        private guzoyeSettingsService: GuzoyeSettingsService,
        private hrmService: HrmService,
        private alertService: AlertService
    ) {
        
    }

    ngOnInit() {
        this.freelancerService.getAllTopReason(this.account.id)
        .pipe(first())
        .subscribe(topReasons => this.topReasons = topReasons);
        this.form = this.formBuilder.group({
            title: ['', Validators.required],
            detail: ['', Validators.required],
            accountId: this.account.id,
        });
    }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

      onSubmit() {
          this.submitted = true;
          this.freelancerService.getAllTopReason(this.account.id)
          .pipe(first())
          .subscribe(topReasons => this.topReasons = topReasons);
          // reset alerts on submit
          this.alertService.clear();
  
          // stop here if form is invalid
          // if (this.form.invalid) {
          //     return;
          // }
  
          this.loading = true;
              this.createproduct();
        
      }
  
      private createproduct() {
          this.freelancerService.createTopReason(this.form.value)
              .pipe(first())
              .subscribe({
                  next: () => {
                      this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                      this.loading = true;
                  },
                  error: error => {
                      this.alertService.error(error);
                      this.loading = false;
                  }
              });
      }
    
    breadcrumb = [
        {
            title: 'Freelancer Bio',
            subTitle: 'Dashboard'
        }
    ]

}