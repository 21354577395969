import { Component, OnInit } from '@angular/core';
import { AccountService,ApplcationService } from '../../../_services';

@Component({
  selector: 'app-webNavBar',
  templateUrl: './webNavBar.component.html',
  styleUrls: ['./webNavBar.component.scss']
})
export class OneTapNavComponent implements OnInit {
  account = this.accountService.accountValue;
  constructor(
    private accountService: AccountService,
  ) { }

  ngOnInit(): void {
  }

}
