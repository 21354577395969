<!-- Start Main Banner Area -->
<div class="main-banner-area">
    <div class="container">
        <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
           
            <h1>{{Content.title}} 
                <ngx-typed-js [strings]="['We are OneTap Technologies PLC ','We Provide','Unfied Contact Center', 'Business Process Outsourcing', 'Sales Outsourcing']" [typeSpeed]="30" [loop]="true" [backSpeed]="10" [showCursor]="false">
                    <span class="typing"></span>
                </ngx-typed-js>
            </h1>
            <p>{{Content.paragraph}}</p>
         
         
        </div>
    </div>
</div>
<!-- End Main Banner Area -->