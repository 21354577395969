import { Component, OnInit } from '@angular/core';
import { MustMatch } from '../../../../_helpers';
import { first } from 'rxjs/operators';
import { AccountService,ApplcationService,GuzoyeSettingsService,AlertService } from '../../../../_services';
@Component({
  selector: 'app-freelancer-navbar',
  templateUrl: './freelancer-navbar.component.html',
  styleUrls: ['./freelancer-navbar.component.scss']
})
export class FreelancerNavbarComponent implements OnInit {
  account = this.accountService.accountValue;
  constructor(
    private accountService: AccountService,
  ) { }

  ngOnInit(): void {
  }

}
