
    <app-navbar-style-two></app-navbar-style-two>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">


    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <section class="listing-area">
        <div class="row">
            
            <div class="col-xl-3 col-lg-6 col-md-6" *ngFor="let product of products | paginate: { itemsPerPage: 6, currentPage: gridListings }">
                <a routerLink="/detail/{{product.code}}">
                <div class="single-listings-box">
                    <div class="listings-image">
                        <div class="listings-image-slides">
                            <img src="{{imageView}}{{product.avatorPath}}{{product.avator}}" alt="image" >
                        </div>
                        <a routerLink="/{{product.name}}" class="bookmark-save"><i class="flaticon-heart"></i></a>
                        <a routerLink="/{{product.name}}" class="category"><i class="flaticon-cooking"></i></a>
                    </div>
                    <div class="listings-content">
                        <div class="author">
                            <div class="d-flex align-items-center">
                                <img src="{{imageView}}{{product.avatorPath}}{{product.avator}}" alt="image" >
                                <span>{{product.code}}</span>
                            </div>
                        </div>
                        <h3><a routerLink="/{{product.path}}">{{product.code}}</a></h3>
                        <span class="status {{product.path}}"><i class="flaticon-save"></i> {{product.name}}</span>
                        <hr>
                        <div class="d-flex justify-content-between">
                            
                            <div class="rating">
                                <span class="count">****</span>
                            </div>
                            <div class="price">
                                STARTING at 100 Br
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <pagination-controls (pageChange)="gridListings = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </section>
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->