import { Component, OnInit } from '@angular/core';
import { MustMatch } from '../../../_helpers';
import { first } from 'rxjs/operators';
import { AccountService,ApplcationService,GuzoyeSettingsService,AlertService } from '../../../_services';
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    account = this.accountService.accountValue;
    constructor(
        private accountService: AccountService,
    ) { }

    ngOnInit(): void {
    }

    breadcrumb = [
        {
            title: 'Howdy, Andy!',
            subTitle: 'Dashboard'
        }
    ]

}