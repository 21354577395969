import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Applcation,Pricing,CheckOut,AppOrder,Coupon,PricingDetail,PlanFaq } from '../_models';
const baseUrl = `${environment.apiUrl}/applcation`;
const pricingUrl = `${environment.apiUrl}/Pricing`;
const appOrdertUrl = `${environment.apiUrl}/appOrder`;
const checkoutUrl = `${environment.apiUrl}/checkout`;
const couponUrl = `${environment.apiUrl}/coupon`;
const faqUrl = `${environment.apiUrl}/planfaq`;
const pricingDetailUrl = `${environment.apiUrl}/PricingDetail`;

@Injectable({ providedIn: 'root' })
export class ApplcationService {
    private applcationSubject: BehaviorSubject<Applcation>;
    public applcation: Observable<Applcation>;
    private pricingSubject: BehaviorSubject<Pricing>;
    public pricing: Observable<Pricing>;
    private appOrderSubject: BehaviorSubject<AppOrder>;
    public AppOrder: Observable<AppOrder>;
    private checkOutSubject: BehaviorSubject<CheckOut>;
    public CheckOut: Observable<CheckOut>;
    private couponSubject: BehaviorSubject<Coupon>;
    public Coupon: Observable<Coupon>;
    private planFaqSubject: BehaviorSubject<PlanFaq>;
    public pricingDetail: Observable<PricingDetail>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.applcationSubject = new BehaviorSubject<Applcation>(null);
        this.applcation = this.applcationSubject.asObservable();
    }

    public get applcationValue(): Applcation {
        return this.applcationSubject.value;
    }
    getAllAppOrder() {
        return this.http.get<AppOrder[]>(appOrdertUrl);
    }
    getLastOrder(appCode:string,id: string) {
        return this.http.get<AppOrder[]>(`${appOrdertUrl}/${appCode}/${id}`);
    }
    getAppOrderByIds(appCode:string,planId:string,period:string,id: string) {
        return this.http.get<AppOrder[]>(`${appOrdertUrl}/${appCode}/${planId}/${period}/${id}`);
    }
    getAllCoupon() {
        return this.http.get<Coupon[]>(couponUrl);
    }
    getCouponByIds(id: string) {
        return this.http.get<Coupon[]>(`${couponUrl}/${id}`);
    }
    PricingDetail(planId: string) {
        return this.http.get<PricingDetail[]>(`${pricingDetailUrl}/plan/${planId}`);
    }
    PlanFaq(planId: string) {
        return this.http.get<PlanFaq[]>(`${faqUrl}/plan/${planId}`);
    }
    getAllCheckOut() {
        return this.http.get<CheckOut[]>(checkoutUrl);
    }
    getAllUserCheckOut(id: string) {
        return this.http.get<CheckOut[]>(`${checkoutUrl}/user/${id}`);
    }
    getCheckOutDetail(appId: string,planId:string,id: string) {
        return this.http.get<CheckOut[]>(`${checkoutUrl}/${appId}/${planId}/${id}`);
    }
    getCheckOutByIds(id: string) {
        return this.http.get<CheckOut[]>(`${checkoutUrl}/${id}`);
    }
    getAll() {
        return this.http.get<Applcation[]>(baseUrl);
    }
    getAllPricing(appCode: string,planId: string,period: string) {
        return this.http.get<Applcation[]>(`${pricingUrl}/${appCode}/${planId}/${period}`);
    }
    getAllPricingByIds(appCode: string) {
        return this.http.get<Applcation[]>(`${baseUrl}/prcing/${appCode}`);
    }

    getAllPricingByAppCode(appCode: string) {
        return this.http.get<Applcation[]>(`${baseUrl}/prcing/${appCode}`);
    }

    getAllPricingDetail(appCode: string,planId: string,period:string) {
        return this.http.get<Applcation[]>(`${baseUrl}/prcing/${appCode}/${planId}/${period}`);
    }

    getById(id: string) {
        return this.http.get<Applcation>(`${baseUrl}/${id}`);
    }

    getAllAppWithPrice() {
        return this.http.get<Applcation[]>(`${baseUrl}/prcing`);
    }

     getAllPricingDetails(id: string) {
        return this.http.get<Applcation[]>(`${baseUrl}/prcing/${id}`);
    }

    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((applcation: any) => {
                    // publish updated membership to subscribers
                    applcation = { ...this.applcationValue, ...applcation };
                    this.applcationSubject.next(applcation);
              
                return applcation;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    createAppOrder(params) {
        return this.http.post(appOrdertUrl, params);
    }
    
    updateAppOrder(id, params) {
        return this.http.put(`${appOrdertUrl}/${id}`, params)
            .pipe(map((appOrder: any) => {
                    // publish updated membership to subscribers
                    appOrder = { ...this.appOrderSubject, ...appOrder };
                    this.appOrderSubject.next(appOrder);
              
                return appOrder;
            }));
    }
    deleteAppOrder(id: string) {
        return this.http.delete(`${appOrdertUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createCehckOut(params) {
        return this.http.post(checkoutUrl, params);
    }
    
    updateCehckOut(id, params) {
        return this.http.put(`${checkoutUrl}/${id}`, params)
            .pipe(map((checkout: any) => {
                    // publish updated membership to subscribers
                    checkout = { ...this.checkOutSubject, ...checkout };
                    this.checkOutSubject.next(checkout);
              
                return checkout;
            }));
    }
    deleteCheckOut(id: string) {
        return this.http.delete(`${checkoutUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    createCoupon(params) {
        return this.http.post(couponUrl, params);
    }
    
    updateCoupon(id, params) {
        return this.http.put(`${couponUrl}/${id}`, params)
            .pipe(map((coupon: any) => {
                    // publish updated membership to subscribers
                    coupon = { ...this.couponSubject, ...coupon };
                    this.couponSubject.next(coupon);
              
                return coupon;
            }));
    }
    deleteCoupon(id: string) {
        return this.http.delete(`${couponUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
    
}