<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Start -->
    <div class="row">
        <section class="listing-area">
            <div class="row">
                <div class="col-xl-10 col-lg-10 col-md-10" >
                    <div class="single-listings-box">
                        <div class="listings-image">
                            <div class="listings-image-slides">
                                <img src="{{imageView}}{{product.avatorPath}}{{product.avator}}" alt="image" >
                            </div>
                        </div>
                        <div class="listings-content">
                            <span class="status {{product.name}}"><i class="flaticon-save"></i> {{product.name}}</span>
                            <div class="d-flex align-items-center justify-content-between">
                                
                           
                            </div>
                        </div>
                        <div class="listings-footer">
                            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-xl-10 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Question </label>
                                            <input type="text" class="form-control" formControlName="question"  placeholder="question">
                                            <div *ngIf="submitted && f.question.errors" class="invalid-feedback">
                                                <div *ngIf="f.question.errors.required">Please enter question</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-10 col-lg-8 col-md-12">
                                        <div class="form-group">
                                            <label>Answer </label>
                                            <input type="hidden" formControlName="productId" [ngModel]="product.id">
                                            <textarea cols="30" rows="6" formControlName="answer"  placeholder="Short description about you..." class="form-control"></textarea>
                                            <div *ngIf="submitted && f.answer.errors" class="invalid-feedback">
                                                <div *ngIf="f.answer.errors.required">Please enter answer</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-8 col-md-12">
                                        <div class="form-group">
                                            <button type="submit">Save Change</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
        <div class="col-lg-8 col-md-12">
            <div class="my-profile-box">
                <h3>Freelancer Bio</h3>
    
               
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-10 col-md-12">
            <div class="my-profile-box">
                <div class="col-lg-8">
                    <div class="col-lg-8 col-md-12">
        
                        <div class="row">
                            <div class="col-lg-12 col-md-12" *ngFor="let plan of gigFaqs ">
                                <div class="single-listings-item">
                                    <div class="row m-0">
        
                                        <div class="col-lg-8 col-md-8 p-0">
                                            <div class="listings-content">
                                                <span class="status {{s.name}}"><i class="flaticon-save"></i>{{plan.plan}}, {{plan.price}}</span>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
       
        </div>
        </div>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->