<app-seller-sidemenu></app-seller-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-freelancer-navbar></app-freelancer-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="row" *ngIf="!address">
    
        <div class="col-lg-10 col-md-12">
            <div class="my-profile-box">
                <h3>Freelancer Current Address</h3>
    
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="row">
                        
                        
                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><i class='bx bx-duplicate'></i>  Region :</label>
                                <select formControlName="regionId" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.regionId.errors }" (change)="onRegionSelect($event.target.value)">
                                    <option>Select Region</option>
                                    <option value="{{region.id}}" *ngFor="let region of regions">{{region.nameE}}</option>
                                </select>
                                <div *ngIf="submitted && f.regionId.errors" class="invalid-feedback">
                                    <div *ngIf="f.regionId.errors.required">Please Select Region</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><i class='bx bx-duplicate'></i> City :</label>
                                <select formControlName="cityId" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.cityId.errors }" (change)="onCitySelect($event.target.value)">
                                    <option>Select Category</option>
                                    <option value="{{city.id}}" *ngFor="let city of cities">{{city.nameE}}</option>
                                </select>
                                <div *ngIf="submitted && f.cityId.errors" class="invalid-feedback">
                                    <div *ngIf="f.cityId.errors.required">Please Select Categories</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><i class='bx bx-duplicate'></i>  Sub City:</label>
                                <select formControlName="subCityId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.subCityId.errors }" (change)="onSubCitySelect($event.target.value)">
                                    <option>Select Category</option>
                                    <option value="{{subCity.id}}" *ngFor="let subCity of subCities">{{subCity.nameE}}</option>
                                </select>
                                <div *ngIf="submitted && f.subCityId.errors" class="invalid-feedback">
                                    <div *ngIf="f.subCityId.errors.required">Please Select Sub City</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><i class='bx bx-duplicate'></i>  Area Name:</label>
                                <select formControlName="seferId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.seferId.errors }" >
                                    <option>Select Category</option>
                                    <option value="{{sefer.id}}" *ngFor="let sefer of sefers">{{sefer.nameE}}</option>
                                </select>
                                <div *ngIf="submitted && f.seferId.errors" class="invalid-feedback">
                                    <div *ngIf="f.seferId.errors.required">Please Select Area Name</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <button type="submit">Save Change</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="address">
        <div class="col-lg-10 col-md-12">
            <div class="my-profile-box">
                <div class="author-sidebar">
                    <div class="user-profile">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/user3.jpg" alt="image">
    
                            <div class="title">
                                <h4>{{account.firstName}} {{account.lastName}}</h4>
                                <span class="sub-title"> {{ address.country.name}},{{ address.region.name}},{{ address.city.name}},{{ address.subCity.name}},{{ address.sefer.name}}</span>
                                <div class="rating d-flex align-items-center">
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="rating-count">(5 reviews)</span>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="user-contact-info">
                        <h3>Contact</h3>
    
                        <ul class="user-contact">
                            <li><a href="#"><i class='bx bx-phone-call'></i> {{account.mobile}}</a></li>
                            <li><a href="#"><i class='bx bx-envelope'></i> {{account.email}}</a></li>
                        </ul>
    
                        <ul class="social-profiles">
                            <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                            <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            <li><a href="#" class="linkedin"><i class='bx bxl-linkedin'></i> LinkedIn</a></li>
                            <li><a href="#" class="youttube"><i class='bx bxl-youtube'></i> youtube</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->