<app-webNavBar></app-webNavBar>

<app-homeone-banner></app-homeone-banner>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content" >
                    <h2>WELCOME TO ONETAP</h2>
                  
                      <p>  OneTap is a Financial Technology dedicated to empowering the community through technology focus on hospitality and service industries by providing an innovative and convenient solutions.</p>

 
                        <p>We committed to bringing smiles to millions of lives & focused on creating customer satisfaction, accountability & ease of access for community-based service by providing a digital platform.</p>

                         <p>For the Past successful years, we were helping Financial institutions by providing a Contact center, Business Process Outsourcing & Consultancy service.   Now we are geared up to expand our service in the Agricultural sector, Health sector, Hospitality Industries, Transport sector, E-commerce, and  Online Counselling service.</p>
                         <p>OneTap proprietor and registered in the Addis Ababa City Administration Trade Bureau of The Federal Democratic Republic of Ethiopia.</p>

                  
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image" >
                    <img src="assets/img/onetapwhite.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<app-homeone-listings></app-homeone-listings>





<section class="feedback-area ptb-100">
    <app-feedback-style-one></app-feedback-style-one>
</section>

<div class="partner-area ptb-100 bg-f5f5f5">
    <app-partner></app-partner>
</div>


<app-footer-style-one></app-footer-style-one>