import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Pricing,PricingDetail } from '../_models';
const baseUrl = `${environment.apiUrl}/pricing`;

@Injectable({ providedIn: 'root' })
export class PricingService {
    private pricingSubject: BehaviorSubject<Pricing>;
    public pricing: Observable<Pricing>;
    private pricingDetailSubject: BehaviorSubject<PricingDetail>;
    public pricingDetail: Observable<PricingDetail>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.pricingSubject = new BehaviorSubject<Pricing>(null);
        this.pricing = this.pricingSubject.asObservable();
    }

    public get pricingValue(): Pricing {
        return this.pricingSubject.value;
    }

   

    getAll() {
        return this.http.get<Pricing[]>(baseUrl);
    }

    getById(id: string) {
        return this.http.get<Pricing>(`${baseUrl}/${id}`);
    }

    getAllProductWithPrice() {
        return this.http.get<Pricing[]>(`${baseUrl}/prcing`);
    }

    getAllPricingByIds(id: string) {
        return this.http.get<Pricing[]>(`${baseUrl}/pricingdetail/${id}`);
    }

    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((pricing: any) => {
                    // publish updated membership to subscribers
                    pricing = { ...this.pricingValue, ...pricing };
                    this.pricingSubject.next(pricing);
              
                return pricing;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   

    
}