<app-webNavBar></app-webNavBar>

<!-- Start Listings Details Area -->
<section class="listings-details-area pb-70">
    <div class="listings-details-image">
       <div class="row">
        <div class="col-md-3">
           
           </div>
           <div class="col-md-9">
            <img src="assets/img/onetapcall.jpg" alt="image">
           </div>
       </div>
        
        
        <div class="container">
            <div class="container">
                <div class="listings-details-content">
                    <span class="meta"> 
                        <h1>
                            <ngx-typed-js [strings]="['SMS ','USSD','CALL CENTER', 'CRM', 'TICKITING SYSTEM']" [typeSpeed]="100" [loop]="true" [backSpeed]="10" [showCursor]="false">
                                <span class="typing"></span>
                            </ngx-typed-js>
                        </h1>
                    </span>
                   
                  
                    <ul class="d-flex align-items-center">
                        <li class="phone-number">
                            <a  href="tel:+251111400810"><i class='bx bx-phone-call'></i>+251-111-400-810</a>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid">
          
        </div>
    </div>
  
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="listings-details-desc">
                    <h3>WHY ONETAP IS BEST</h3>
                    <p>We are pioneers in the industry successfully deploy and maintain more than 20 call centers including Top Financial Institutions and Tech start-ups.</p>
                    <p>We provide a stable & high availability contact center & ready to serve you 24/7. </p>
                    
                    <h3>TOP FEATURES</h3>
                    <ul class="amenities-list">
                        <li><span><i class='bx bx-check'></i> INTRATIVE DASHBOAR</span></li>
                        <li><span><i class='bx bx-check'></i> CUSTOM CONVERSATION FLOW</span></li>
                        <li><span><i class='bx bx-check'></i> SMART DISPATCHER</span></li>
                        <li><span><i class='bx bx-check'></i> AGENT PERFORAMNCE (KPI)</span></li>
                        <li><span><i class='bx bx-check'></i> REMOTE ACCESS</span></li>
                        <li><span><i class='bx bx-check'></i> COMPUTER TELEPHONY INTEGRATION </span></li>
                        <li><span><i class='bx bx-check'></i> INTERACTIVE VOICE RESPONSE (IVR)</span></li>
                        <li><span><i class='bx bx-check'></i> ROBO CALLING</span></li>
                    </ul>
                    <h3>Gallery</h3>
                    <div id="gallery">
                        <div class="gallery-slides">
                            <owl-carousel-o [options]="galleryOptions">
                                <ng-template carouselSlide *ngFor="let Image of singleImageBox;">
                                    <div class="single-image-box">
                                        <img [src]="Image.img" alt="image">
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>
                  
                  
               
            
                    
                
                </div>
            </div>
         
        </div>
    </div>
</section>
<!-- End Listings Details Area -->

<app-footer-style-one></app-footer-style-one>