<div *ngIf="alerts.length" >
    <div class="m-3">
        <div *ngFor="let alert of alerts" class="{{cssClasses(alert)}}">
            <a class="close" (click)="removeAlert(alert)">&times;</a>
            <span style="color: yellow;"> {{alert.message}}</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        
    </div>
</div>
