
<div class="container-fluid" id="login">

    <div class="form-container sign-in-container">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <h1 class="font-weight-bold">Sign n</h1>
        <div class="social-container">
        </div>
  
        <div class="form-group">
            <label>Email</label>
            <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email is invalid</div>
            </div>
        </div>
        <div class="form-group">
            <label>Password</label>
            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
        </div>
        <button class="signin">Sign In</button>
        <div class="forgot" routerLink="/register/{{appCode}}/{{planId}}/{{period}}">
            Register
          </div>
        <div class="forgot" [routerLink]="['../forgot-password']">
            Cant login?
          </div>
      </form>
   
    </div>

    <div class="overlay-container">
      <div class="overlay">
        <div class="overlay-panel overlay-right">
          <h1 class="font-weight-bold">Hello, Friend!</h1>
          <p>You don't have account yet? Don't worry! You still can join us</p>
         <a routerLink='/register/{{appCode}}/{{planId}}/{{period}}'>
               <button class="signup" id="signUp">Sign Up</button>
         </a> 
        </div>
      </div>
    </div>
  </div>
  