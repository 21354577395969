import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Plan } from '../_models';

const baseUrl = `${environment.apiUrl}/Pricing`;

@Injectable({ providedIn: 'root' })
export class PlanService {
    private planSubject: BehaviorSubject<Plan>;
    public plan: Observable<Plan>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.planSubject = new BehaviorSubject<Plan>(null);
        this.plan = this.planSubject.asObservable();
    }

    public get planValue(): Plan {
        return this.planSubject.value;
    }

    register(plan: Plan) {
        return this.http.post(`${baseUrl}/register`, plan);
    }

    getAll() {
        return this.http.get<Plan[]>(baseUrl);
    }
    getProductPlan(productCode : string) {
        return this.http.get<Plan[]>(`${baseUrl}/product/${productCode}`);
    }
    getById(id: string) {
        return this.http.get<Plan>(`${baseUrl}/${id}`);
    }
    
    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((plan: any) => {
                    // publish updated membership to subscribers
                    plan = { ...this.planValue, ...plan };
                    this.planSubject.next(plan);
              
                return plan;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   

    
}