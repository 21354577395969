import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FreelancerService,GuzoyeSettingsService,AddressService,HrmService,ProductService, AccountService,AlertService } from '../../../../_services';
import { MustMatch } from '../../../../_helpers';
import { Account } from 'src/app/_models';
import { environment } from '../../../../../environments/environment';
import { flatten } from '@angular/compiler';
const imageUrl = `${environment.apiUrl}/preview`;


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    form: FormGroup;
    bioForm: FormGroup;
    aForm: FormGroup;
    lForm: FormGroup;
    eForm: FormGroup;
    sForm: FormGroup;
    cForm: FormGroup;
    id: string;
    products;
    languageSkills;
    skills;
    educations;
    languages;
    certifications;
    levels;
    regionId;
    regions;
    cities;
    cityId;
    subCityId;
    seferId;
    subCities;
    sefers;
    address;
    countires;
    degrees;
    parents;
    categoryId;
    categories;
    subCategories;
    subCategoryId;
    childCategories;
    sub;
    logoSrc: string = '';
    imagePath: string='';
    isAddMode: boolean;
    loading = false;
    submitted = false;
    imageView = imageUrl;
    account = this.accountService.accountValue;
   

    
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private addressService: AddressService,
        private freelancerService: FreelancerService,
        private guzoyeSettingsService: GuzoyeSettingsService,
        private hrmService:  HrmService,
        private productService:  ProductService,
        private alertService: AlertService
    ) {
        
    }

    ngOnInit() {
        this.addressService.getRegioninCountryById('1')
        .pipe(first())
        .subscribe(regions => this.regions = regions);
        this.guzoyeSettingsService.getAllLanguage()
        .pipe(first())
        .subscribe(languages => this.languages = languages);
        this.freelancerService.getAllLevel()
        .pipe(first())
        .subscribe(levels => this.levels = levels);

        this.addressService.getAllCountry()
        .pipe(first())
        .subscribe(countires => this.countires = countires);
        this.freelancerService.getAllDegree()
        .pipe(first())
        .subscribe(degrees => this.degrees = degrees);

        this.freelancerService.getAllLanguageSkill()
        .pipe(first())
        .subscribe(languageSkills => this.languageSkills = languageSkills);

        this.freelancerService.getAllSkill()
        .pipe(first())
        .subscribe(skills => this.skills = skills);

        this.freelancerService.getAllEducation()
        .pipe(first())
        .subscribe(educations => this.educations = educations);

        this.freelancerService.getAllCertification()
        .pipe(first())
        .subscribe(certifications => this.certifications = certifications);

        this.productService.getAll()
        .pipe(first())
        .subscribe(products => this.products = products);

        this.bioForm = this.formBuilder.group({
            bio: ['', Validators.required],
            accountId: this.account.id,
        });
        this.aForm = this.formBuilder.group({
            countryId: ['1', Validators.required],
            regionId: ['', Validators.required],
            cityId: ['', Validators.required],
            subCityId: ['', Validators.required],
            seferId: ['', Validators.required],
            accountId: this.account.id,
        });
        this.lForm = this.formBuilder.group({
            languageId: ['', Validators.required],
            levelId: ['', Validators.required],
            accountId: this.account.id,
        });
        this.sForm = this.formBuilder.group({
            name: ['', Validators.required],
            levelId: ['', Validators.required],
            accountId: this.account.id,
        });
        this.eForm = this.formBuilder.group({
            title: ['', Validators.required],
            inistution: ['', Validators.required],
            qualification: ['', Validators.required],
            yearOfGraguation: ['', Validators.required],
            countryId: ['', Validators.required],
            degreeId: ['', Validators.required],
            accountId: this.account.id,
        });
        this.cForm = this.formBuilder.group({
            certification: ['', Validators.required],
            inistution: ['', Validators.required],
            grantedOn: ['', Validators.required],
            accountId: this.account.id,
        });
    }

    onRegionSelect(value) {
        this.regionId = value;
        this.addressService.getCityInRegionById(this.regionId)
        .pipe(first())
        .subscribe(cities => this.cities = cities);
    }
    onCitySelect(value) {
        this.cityId = value;
        this.addressService.getSubCityinCityById(this.cityId)
        .pipe(first())
        .subscribe(subCities => this.subCities = subCities);
    }
    onSubCitySelect(value) {
        this.subCityId = value;
        this.addressService.getSeferInSubCityById(this.subCityId)
        .pipe(first())
        .subscribe(sefers => this.sefers = sefers);
    } 
    bio:boolean=false;
    location:boolean=false;
    language:boolean=false;
    skill:boolean=false;
    education:boolean=false;
    certification:boolean=false;

    cancel(){
        this.bio=false;
        this.location=false;
        this.language=false
        this.skill=false
        this.education=false
        this.certification=false
    }
    bioFunction(){
        this.bio=true;
        this.location=false;
        this.language=false
        this.skill=false
        this.education=false
        this.certification=false
    }

    locationFunction(){
        this.bio=false;
        this.location=true;
        this.language=false
        this.skill=false
        this.education=false
        this.certification=false
    }

    languageFunction(){
        this.bio=false;
        this.location=false;
        this.language=true
        this.skill=false
        this.education=false
        this.certification=false
    }
    skillFunction(){
        this.bio=false;
        this.location=false;
        this.language=false
        this.skill=true
        this.education=false
        this.certification=false
    }
    educationFunction(){
        this.bio=false;
        this.location=false;
        this.language=false
        this.skill=false
        this.education=true
        this.certification=false
    }
    certaficationFunction(){
        this.bio=false;
        this.location=false;
        this.language=false
        this.skill=false
        this.education=false
        this.certification=true
    }
    // convenience getter for easy access to form fields
        get bf() { return this.bioForm.controls; }
        get af() { return this.aForm.controls; }
        get lf() { return this.lForm.controls; }
        get ef() { return this.eForm.controls; }
        get sf() { return this.sForm.controls; }
        get cf() { return this.cForm.controls; }
   
   

    
      onBioSubmit() {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
       

        this.loading = true;
            this.createBio();
      
    }
    onAddressSubmit() {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
       

        this.loading = true;
            this.createLocation();
      
    }
    onLanguageSubmit() {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
       

        this.loading = true;
            this.createLanguage();
      
    }
    onSkillSubmit() {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
       

        this.loading = true;
            this.createSkill();
      
    }
    onEducationSubmit() {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
       

        this.loading = true;
            this.createEducation();
      
    }
    onCertificationSubmit() {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
       

        this.loading = true;
            this.createCertification();
      
    }

      private createBio() {
        const formData = new FormData();
        formData.append('bio', this.bioForm.get('bio').value);
        formData.append('accountId', this.account.id);
          this.accountService.bio(this.bioForm.value)
              .pipe(first())
              .subscribe({
                  next: () => {
                      this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                      this.router.navigate(['/profile'], { relativeTo: this.route });
                  },
                  error: error => {
                      this.alertService.error(error);
                      this.loading = false;
                  }
              });
      }
      private createLocation() {
        this.freelancerService.createLocation(this.aForm.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                    
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
      private createLanguage() {
        const formData = new FormData();
          this.freelancerService.createLanguageSkill(this.lForm.value)
              .pipe(first())
              .subscribe({
                  next: () => {
                      this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                      this.router.navigate(['/profile'], { relativeTo: this.route });
                  },
                  error: error => {
                      this.alertService.error(error);
                      this.loading = false;
                  }
              });
      }
      private createSkill() {
        this.freelancerService.createSkill(this.sForm.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                    this.router.navigate(['/profile'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
      private createEducation() {
        this.hrmService.createEducation(this.eForm.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                    this.router.navigate(['/profile'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
      private createCertification() {
        this.freelancerService.createCertification(this.cForm.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                    this.router.navigate(['/profile'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
    
    
    breadcrumb = [
        {
            title: 'My Profile',
            subTitle: 'Dashboard'
        }
    ]

}