<!-- Start Navbar Area -->
<div class="navbar-area navbar-style-one">
    <div class="vesax-responsive-nav">
        <div class="container">
            <div class="vesax-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="vesax-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>

                <div class="collapse navbar-collapse mean-menu">
                 

                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>

                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Service</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/ucc" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">MULTI-CHANNEL COMUNICATION</a></li>
                                
                                <li class="nav-item"><a routerLink="/bpo" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">BUSINESS PROCESS OUTSOURCING</a></li>

                                <li class="nav-item"><a routerLink="/sales" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">SALES OUTSOURCING</a></li>
                            </ul>
                        </li>
                        <li class="nav-item" >
                            <a routerLink="/marketplace"  class="nav-link waves-effect waves-dark" routerLinkActive="active" href="javascript:void(0)">
                                <span class="d-md-block"> <img width="20px" >Marketplace
                          </span>
                            </a>
                        </li>
                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>

                    </ul>

                    <div class="others-option d-flex align-items-center">
                        

                        <div class="option-item" *ngIf="!account">
                            <a routerLink="/auth/login" class="default-btn"><i class="flaticon-user"></i> Login / Register</a>
                        </div>
                        <div class="option-item" *ngIf="account">
                            <a routerLink="/logout" class="default-btn"><i class="flaticon-logout"></i> logout</a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                   

                        <div class="option-item">
                            <span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i class="flaticon-user"></i> Login / Register</span>
                        </div>

                        <div class="option-item">
                            <a routerLink="/dashboard-add-listings" class="default-btn"><i class="flaticon-more"></i> Add Listing</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Start Login & Register Modal -->
<div class="modal fade loginRegisterModal" id="loginRegisterModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><i class='bx bx-x'></i></button>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <button class="nav-link active" id="login-tab" data-bs-toggle="tab" data-bs-target="#login" type="button" role="tab" aria-controls="login" aria-selected="true">Login</button>
                </li>
                <li class="nav-item">
                    <button class="nav-link" id="register-tab" data-bs-toggle="tab" data-bs-target="#register" type="button" role="tab" aria-controls="register" aria-selected="true">Register</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="login" role="tabpanel">
                    <div class="vesax-login">
                        <div class="login-with-account">
                            <span>Login with</span>
                            <ul>
                                <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                                <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            </ul>
                        </div>
                        <span class="sub-title"><span>Or login with</span></span>
                        <form>
                            <div class="form-group">
                                <input type="text" placeholder="Username or Email" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" class="form-control">
                            </div>
                            <button type="submit">Login Now</button>
                        </form>
                        <span class="dont-account">Don't have an account? <a href="#">Register Now</a></span>
                    </div>
                </div>
                <div class="tab-pane fade" id="register" role="tabpanel">
                    <div class="vesax-register">
                        <div class="register-with-account">
                            <span>Register with</span>
                            <ul>
                                <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                                <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            </ul>
                        </div>
                        <span class="sub-title"><span>Or Register with</span></span>
                        <form>
                            <div class="form-group">
                                <input type="text" placeholder="Username" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="email" placeholder="Email" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Confirm Password" class="form-control">
                            </div>
                            <button type="submit">Register Now</button>
                        </form>
                        <span class="already-account">Already have an account? <a href="#">Login Now</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login & Register Modal -->