import { Role } from './role';

export class Account {
    id: string;
    title: string;
    firstName: string;
    lastName: string;
    mobile: string;
    tinNumber: string;
    avatorPath: string;
    avator: string;
    bio: string;
    bioE: string;
    tagline: string;
    taglineE: string;
    facebookUrl: string;
    instagramUrl: string;
    youtubeChannel: string;
    tikTakUrl: string;
    email: string;
    role: Role;
    jwtToken?: string;
}