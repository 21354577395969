import { Component, OnInit } from '@angular/core';
import { CategoryService,AccountService,AlertService } from '../../../_services';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MustMatch } from '../../../_helpers';

@Component({
  selector: 'app-topNav',
  templateUrl: './topNav.component.html',
  styleUrls: ['./topNav.component.scss']
})
export class TopNavComponent implements OnInit {
categories;
form;
form2
user;
loading = false;
submitted = false;
account = this.accountService.accountValue;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private accountService: AccountService,
    private categoryService: CategoryService
) {}

  ngOnInit(): void {
    this.categoryService.getCategory(1)
    .pipe(first())
    .subscribe(categories => this.categories = categories);
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
  });
  this.form2 = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    loginType: ['Web'],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', Validators.required],
    acceptTerms: [false, Validators.requiredTrue],
}, {
    validator: MustMatch('password', 'confirmPassword')
});

  }
  get f() { return this.form.controls; }
  get f2() { return this.form2.controls; }


  onSubmit() {
      this.submitted = true;

      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      // if (this.form.invalid) {
      //     return;
      // }

   
      this.accountService.login(this.f.email.value, this.f.password.value)
          .pipe(first())
          .subscribe({
              next: () => {
                  var user = this.user = user;
                  this.alertService.success(user, { keepAfterRouteChange: true });
                  this.router.navigate(['/profile' ], { relativeTo: this.route });
              },
              error: error => {
                  this.alertService.error(error);
                  this.loading = true;
              }
          });
  }
  onRegistrationSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    // if (this.form.invalid) {
    //     return;
    // }

  
    this.accountService.register(this.form2.value)
        .pipe(first())
        .subscribe({
            next: () => {
                var user = this.user = user;
                this.alertService.success(user, { keepAfterRouteChange: true });
                this.router.navigate(['/' ], { relativeTo: this.route });
                
            },
            error: error => {
                this.alertService.error(error);
                this.loading = true;
            }
        });
}
logout() {
  this.accountService.logout();
}

}

