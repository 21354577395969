import { Component, OnInit } from '@angular/core';
import { CategoryService,AlertService } from '../../../../_services';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/operators';
@Component({
    selector: 'app-afterlogin',
    templateUrl: './afterlogin.component.html',
    styleUrls: ['./afterlogin.component.scss']
})
export class AfterLoginComponent implements OnInit {
    categories;
    constructor(
        private categoryService: CategoryService,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.resetOption = [this.options[0]];
        this.categoryService.getAll()
        .pipe(first())
        .subscribe(categories => this.categories = categories);
    }

    bannerContent = [
        {
            title: 'Find Nearby',
            paragraph: 'Expolore top-rated attractions, activities and more...',
            popularSearchList: [
                {
                    title: 'Restaurants',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Events',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Clothing',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Bank',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Fitness',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Bookstore',
                    link: 'grid-listings-left-sidebar'
                }
            ]
        }
    ]
    bannerImage = [
        {
            img: 'assets/img/onetapsales.jpg'
        }
    ]

    // Category Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config = {
        displayKey: "name",
        search: true
    };
    options = [
        // Type here your category name
        {
            name: "Restaurants",
        },
        {
            name: "Events",
        },
        {
            name: "Clothing",
        },
        {
            name: "Bank",
        },
        {
            name: "Fitness",
        },
        {
            name: "Bookstore",
        }
    ];
    searchChange($event) {
        console.log($event);
    }
    reset() {
        this.resetOption = [];
    }

}