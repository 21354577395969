import { Component, OnInit } from '@angular/core';
import { AccountService,AlertService } from '../../../_services';
import { MustMatch } from '../../../_helpers';
import { Account } from 'src/app/_models';

@Component({
  selector: 'app-home-demo-two',
  templateUrl: './home-demo-two.component.html',
  styleUrls: ['./home-demo-two.component.scss']
})
export class HomeDemoTwoComponent implements OnInit {
  account = this.accountService.accountValue;
  constructor(
    private accountService:  AccountService,
    private alertService: AlertService
) {
    
}

  ngOnInit(): void {
  }

}
