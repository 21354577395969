import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AccountInfo,AccountLocation,Language,LanguageSkill,Level,Skill,Degree,Certification,Education,TopReason } from '../_models/';

const infoUrl = `${environment.apiUrl}/accountinfo`;
const locationUrl = `${environment.apiUrl}/accountlocation`;
const languagueUrl = `${environment.apiUrl}/language`;
const levelUrl = `${environment.apiUrl}/level`;
const degreeUrl = `${environment.apiUrl}/degree`;
const skillUrl = `${environment.apiUrl}/skill`;
const certificationUrl = `${environment.apiUrl}/certification`;
const languageSkillUrl = `${environment.apiUrl}/languageskill`;
const educationUrl = `${environment.apiUrl}/education`;
const topreasonUrl = `${environment.apiUrl}/topreason`;
@Injectable({ providedIn: 'root' })
export class FreelancerService {
    private accountInfoSubject: BehaviorSubject<AccountInfo>;
    public accountInfo: Observable<AccountInfo>;
    private accountLocationSubject: BehaviorSubject<AccountLocation>;
    public accountLocation: Observable<AccountLocation>;
    private languageSubject: BehaviorSubject<Language>;
    public language: Observable<Language>;
    private levelSubject: BehaviorSubject<Level>;
    public level: Observable<Level>;
    private degreeSubject: BehaviorSubject<Degree>;
    public degree: Observable<Degree>;
    private languageSkillSubject: BehaviorSubject<LanguageSkill>;
    public languageSkill: Observable<LanguageSkill>;
    private certificationSubject: BehaviorSubject<Certification>;
    public certificationSkill: Observable<Certification>;
    private educationSubject: BehaviorSubject<Education>;
    public education: Observable<Education>;
    private topReasonSubject: BehaviorSubject<TopReason>;
    public topReason: Observable<TopReason>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.accountInfoSubject = new BehaviorSubject<AccountInfo>(null);
        this.accountInfo = this.accountInfoSubject.asObservable();
        this.accountLocationSubject = new BehaviorSubject<AccountLocation>(null);
        this.accountLocation = this.accountLocationSubject.asObservable();
        this.languageSubject = new BehaviorSubject<Language>(null);
        this.language = this.languageSubject.asObservable();
        this.levelSubject = new BehaviorSubject<Level>(null);
        this.level = this.levelSubject.asObservable();
        this.degreeSubject = new BehaviorSubject<Degree>(null);
        this.degree = this.degreeSubject.asObservable();
        this.languageSkillSubject = new BehaviorSubject<LanguageSkill>(null);
        this.languageSkill = this.languageSkillSubject.asObservable();
        this.certificationSubject = new BehaviorSubject<Certification>(null);
        this.certificationSkill = this.certificationSubject.asObservable();
        this.educationSubject = new BehaviorSubject<Education>(null);
        this.education = this.educationSubject.asObservable();
        this.topReasonSubject = new BehaviorSubject<TopReason>(null);
        this.topReason = this.topReasonSubject.asObservable();
        
    }

    public get accountInfoValue(): AccountInfo {
        return this.accountInfoSubject.value;
    }
    public get accountLocationValue(): AccountInfo {
        return this.accountLocationSubject.value;
    }

    public get languageValue(): Language {
        return this.languageSubject.value;
    }

    public get levelValue(): Level {
        return this.levelSubject.value;
    }

    public get degreeValue(): Degree {
        return this.degreeSubject.value;
    }

    public get languageSkillValue(): Level {
        return this.languageSkillSubject.value;
    }
    public get certificationValue(): Certification {
        return this.certificationSubject.value;
    }
    public get educationValue(): Education {
        return this.educationSubject.value;
    }
    public get topReasonValue(): TopReason {
        return this.topReasonSubject.value;
    }
    getAll() {
        return this.http.get<AccountInfo[]>(infoUrl);
    }
    getOne(accountId : string) {
        return this.http.get<AccountInfo[]>(`${infoUrl}/one/${accountId}`);
    }
    getOneAddress(accountId : string) {
        return this.http.get<AccountLocation[]>(`${locationUrl}/one/${accountId}`);
    }
    getAllProductDetail(productCode: string) {
        return this.http.get<AccountInfo[]>(`${infoUrl}/all/${productCode}`);
    }

    getById(id: string) {
        return this.http.get<AccountInfo>(`${infoUrl}/${id}`);
    }
    
    create(params) {
        return this.http.post(infoUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${infoUrl}/${id}`, params)
            .pipe(map((accountInfo: any) => {
                    // publish updated Product to subscribers
                    accountInfo = { ...this.accountInfoValue, ...accountInfo };
                    this.accountInfoSubject.next(accountInfo);
              
                return accountInfo;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${infoUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    } 
    getAllLocation() {
        return this.http.get<AccountLocation[]>(locationUrl);
    }

    getLocationById(id: string) {
        return this.http.get<AccountLocation>(`${locationUrl}/${id}`);
    }
    
    createLocation(params) {
        return this.http.post(locationUrl, params);
    }
    
    updateLocation(id, params) {
        return this.http.put(`${locationUrl}/${id}`, params)
            .pipe(map((accountLocation: any) => {
                    // publish updated Product to subscribers
                    accountLocation = { ...this.accountLocationValue, ...accountLocation };
                    this.accountInfoSubject.next(accountLocation);
              
                return accountLocation;
            }));
    }
    deleteLocation(id: string) {
        return this.http.delete(`${locationUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    } 
    getAllLanguage() {
        return this.http.get<Language[]>(languagueUrl);
    }  
    getLanguageById(id: string) {
        return this.http.get<Language>(`${languagueUrl}/${id}`);
    }
    getAllLanguageSkill() {
        return this.http.get<LanguageSkill[]>(languageSkillUrl);
    }
    createLanguageSkill(params) {
        return this.http.post(languageSkillUrl, params);
    }
    getLanguageSkillById(id: string) {
        return this.http.get<Level>(`${languageSkillUrl}/${id}`);
    }
    
    updateLanguageSkill(id, params) {
        return this.http.put(`${languageSkillUrl}/${id}`, params)
            .pipe(map((languageSkill: any) => {
                    // publish updated Product to subscribers
                    languageSkill = { ...this.languageSkillValue, ...languageSkill };
                    this.languageSkillSubject.next(languageSkill);
              
                return languageSkill;
            }));
    }
    deleteLanguageSkill(id: string) {
        return this.http.delete(`${levelUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    } 
    getAllLevel() {
        return this.http.get<Level[]>(levelUrl);
    }  
    getLevelById(id: string) {
        return this.http.get<Level>(`${levelUrl}/${id}`);
    }

    getAllDegree() {
        return this.http.get<Degree[]>(degreeUrl);
    }  
    getDegreeById(id: string) {
        return this.http.get<Level>(`${degreeUrl}/${id}`);
    }
    createSkill(params) {
        return this.http.post(skillUrl, params);
    }
    getAllSkill() {
        return this.http.get<Skill[]>(skillUrl);
    }  
    getSkillById(id: string) {
        return this.http.get<Skill>(`${skillUrl}/${id}`);
    }
    createCertification(params) {
        return this.http.post(certificationUrl, params);
    }
    getAllCertification() {
        return this.http.get<Certification[]>(certificationUrl);
    }  
    getCertificationById(id: string) {
        return this.http.get<Certification>(`${certificationUrl}/${id}`);
    }
    getAllEducation() {
        return this.http.get<Education[]>(educationUrl);
    }  
    getEducationById(id: string) {
        return this.http.get<Education>(`${educationUrl}/${id}`);
    }
    updateEducation(id, params) {
        return this.http.put(`${educationUrl}/${id}`, params)
            .pipe(map((education: any) => {
                    // publish updated Product to subscribers
                    education = { ...this.educationValue, ...education };
                    this.educationSubject.next(education);
              
                return education;
            }));
    }
    updateTopReason(id, params) {
        return this.http.put(`${topreasonUrl}/${id}`, params)
            .pipe(map((topReason: any) => {
                    // publish updated Product to subscribers
                    topReason = { ...this.topReasonValue, ...topReason };
                    this.topReasonSubject.next(topReason);
              
                return topReason;
            }));
    }
    createTopReason(params) {
        return this.http.post(topreasonUrl, params);
    }
    getAllTopReason(accountId : string) {
        return this.http.get<TopReason[]>(`${topreasonUrl}/my/${accountId}`);
    }  
    getTopReasonById(id: string) {
        return this.http.get<TopReason>(`${topreasonUrl}/${id}`);
    }
}