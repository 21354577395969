import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService,ApplcationService } from '../../../_services';
import { first } from 'rxjs/operators';

@Component({
	selector: 'app-planDetail',
	templateUrl: './planDetail.component.html',
	styleUrls: ['./planDetail.component.scss']
})
export class PlanDetailComponent implements OnInit {
    app;
    apps;
    planId: string;
    appCode: string;
    period: string;
    account = this.accountService.accountValue;

	constructor(
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private applcationService: ApplcationService
      ) {
          
          route.params.subscribe(routeParam => {
        this.appCode = routeParam['appCode'];
        this.planId = routeParam['planId'];
        this.period = routeParam['period'];
        this.applcationService.getAllPricingDetail(this.appCode,this.planId,this.period)
        .pipe(first())
        .subscribe(app => this.app = app);
      }); }

      
	ngOnInit(): void {
      
        this.route.queryParams.subscribe((params) => {
            this.appCode = this.route.snapshot.params['appCode'];
        this.planId = this.route.snapshot.params['planId'];
        this.period = this.route.snapshot.params['period'];
        this.apps = this.route.snapshot.params['planCode'];
        this.applcationService.getAllPricingDetail(this.appCode,this.planId,this.period)
            .pipe(first())
            .subscribe(app => this.app = app);
         });
         
       
	}

onClick(){
    // window.location.reload();
      
   
}
    

    pageTitleContent = [
        {
            title: 'Plan',
            backgroundImage: 'assets/img/page-title/page-title1.jpg'
        }
    ]

    monthlyPricing = [
        {
            singlePricingBox: [
                {
                    title: 'Starter Plan',
                    features: [
                        {
                            icon: 'bx bx-check',
                            text: '5 Listing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Non-Featured'
                        },
                        {
                            icon: 'bx bx-check',
                            text: '90 Days Availability'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'One Time Fee'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Multi Team Tasking'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Registration & Ticketing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Features'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Online Booking'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Limited Support'
                        }
                    ],
                    price: '$9.99',
                    buttonText: 'Select Plan',
                    buttonLink: '#'
                },
                {
                    title: 'Advance Plan',
                    features: [
                        {
                            icon: 'bx bx-check',
                            text: '5 Listing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Non-Featured'
                        },
                        {
                            icon: 'bx bx-check',
                            text: '90 Days Availability'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'One Time Fee'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Multi Team Tasking'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Registration & Ticketing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Features'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Online Booking'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Limited Support'
                        }
                    ],
                    price: '$19.99',
                    buttonText: 'Select Plan',
                    buttonLink: '#'
                },
                {
                    title: 'Premium Plan',
                    features: [
                        {
                            icon: 'bx bx-check',
                            text: '5 Listing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Non-Featured'
                        },
                        {
                            icon: 'bx bx-check',
                            text: '90 Days Availability'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'One Time Fee'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Multi Team Tasking'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Registration & Ticketing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Features'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Online Booking'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Limited Support'
                        }
                    ],
                    price: '$29.99',
                    buttonText: 'Select Plan',
                    buttonLink: '#'
                }
            ]
        }
    ]
    yearlyPricing = [
        {
            singlePricingBox: [
                {
                    title: 'Starter Plan',
                    features: [
                        {
                            icon: 'bx bx-check',
                            text: '5 Listing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Non-Featured'
                        },
                        {
                            icon: 'bx bx-check',
                            text: '90 Days Availability'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'One Time Fee'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Multi Team Tasking'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Registration & Ticketing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Features'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Online Booking'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Limited Support'
                        }
                    ],
                    price: '$119.99',
                    buttonText: 'Select Plan',
                    buttonLink: '#'
                },
                {
                    title: 'Advance Plan',
                    features: [
                        {
                            icon: 'bx bx-check',
                            text: '5 Listing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Non-Featured'
                        },
                        {
                            icon: 'bx bx-check',
                            text: '90 Days Availability'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'One Time Fee'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Multi Team Tasking'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Registration & Ticketing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Features'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Online Booking'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Limited Support'
                        }
                    ],
                    price: '$129.99',
                    buttonText: 'Select Plan',
                    buttonLink: '#'
                },
                {
                    title: 'Premium Plan',
                    features: [
                        {
                            icon: 'bx bx-check',
                            text: '5 Listing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Non-Featured'
                        },
                        {
                            icon: 'bx bx-check',
                            text: '90 Days Availability'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'One Time Fee'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Multi Team Tasking'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Registration & Ticketing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Features'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Online Booking'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Limited Support'
                        }
                    ],
                    price: '$139.99',
                    buttonText: 'Select Plan',
                    buttonLink: '#'
                }
            ]
        }
    ]

}