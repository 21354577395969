import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService,ProductService,ApplcationService,FreelancerService } from '../../../../_services';
import { first } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
const imageUrl = `${environment.apiUrl}/preview`;
@Component({
    selector: 'app-productdetails',
    templateUrl: './productdetails.component.html',
    styleUrls: ['./productdetails.component.scss']
})
export class ProductsDetailsComponent implements OnInit {
    apps;
    productCode;
    planId: string;
    appId: string;
    features;
    address;
    topreasons;
    faqs;
    imageView=imageUrl
    account = this.accountService.accountValue;
    ids: Array<String> = ['overview', 'describtion', 'about', 'packages','Recommendations','faq','Reviews']
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private productService: ProductService,
        private applcationService: ApplcationService,
        private freelancerService: FreelancerService
    ) { }

    ngOnInit(): void {
        this.productCode = this.route.snapshot.params['productCode'];
        this.planId = this.route.snapshot.params['planId'];
        this.productService.getAllProductDetail(this.productCode)
            .pipe(first())
            .subscribe(apps => this.apps = apps);
            this.freelancerService.getOneAddress(this.account.id)
            .pipe(first())
            .subscribe(address => this.address = address);
            this.freelancerService.getAllTopReason(this.account.id)
            .pipe(first())
            .subscribe(topreasons => this.topreasons = topreasons);
      
	}
  
    
    pageTitleContent = [
        {
            title: 'Products Details',
            backgroundImage: 'assets/img/page-title/page-title4.jpg'
        }
    ]

}