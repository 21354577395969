import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators ,FormArray} from '@angular/forms';
import { MustMatch } from '../../../_helpers';
import { first } from 'rxjs/operators';
import { AccountService,CategoryService,ProductService,AlertService } from '../../../_services';

@Component({
    selector: 'app-product-add',
    templateUrl: './product-add.component.html',
    styleUrls: ['./product-add.component.scss']
})
export class AddProductComponent implements OnInit {
    form: FormGroup;
    parents;
    categoryId;
    categories;
    subCategories;
    subCategoryId;
    childCategories;
    sub;
    logoSrc: string = '';
    imagePath: string='';
    lastOrder;
    app;
    pricing;
    appCode: string;
    planId: string;
    period: string;
    today;
    oneMonth;
    twoMonth;
    oneyear;
    myService;
    price;
    qty;
    discount;
    finalPrice;
    alreadyRegister;
    selectedValue: string;
    submitted = false;
    loading = false;
    account = this.accountService.accountValue;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private productService: ProductService,
        private accountService: AccountService,
        private categoryService: CategoryService,
        private alertService: AlertService
    ) { }

    ngOnInit(): void {
        this.categoryService.getCategoryLayer(1)
        .pipe(first())
        .subscribe(categories => this.categories = categories);

        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            nameE: ['', Validators.required],
            describtion: ['', Validators.required],
            image: [null],
            logo: [null],
            imagePath:  [''],
            avatorPath:  ['/product/'],
            subId: ['', Validators.required],
            categoryId: ['', Validators.required],
            businessId: ['', Validators.required],
            parentId: ['', Validators.required],
            accountId: this.account.id,
        });
        
    }

    onCategorySelect(value) {
        this.categoryId = value;
        this.categoryService.getAllSubCategoryById(this.categoryId)
        .pipe(first())
        .subscribe(subCategories => this.subCategories = subCategories);
    }
    onsubCategorySelect(value) {
        this.subCategoryId = value;
        this.categoryService.getAllChildCategoryById(this.subCategoryId)
        .pipe(first())
        .subscribe(childCategories => this.childCategories = childCategories);
    }
    onFileChange(event) {
        const reader = new FileReader();
        
        if(event.target.files && event.target.files.length) {
          const [file] = event.target.files;
          reader.readAsDataURL(file);
        
          reader.onload = () => {
            var orginal = file.name
            this.imagePath= orginal.replace(/[^a-zA-Z0-9.]/g, '');
            this.logoSrc = reader.result as string;
            this.form.patchValue({
              image: file
            });
           
       
          };
       
        }
      }

      get f() { return this.form.controls; }

   // We will create multiple form controls inside defined form controls photos.
    createItem(data): FormGroup {
        return this.formBuilder.group(data);
    }
    //Help to get all photos controls as form array.
    get photos(): FormArray {
        return this.form.get('photos') as FormArray;
    };
    detectFiles(event) {
        let files = event.target.files;
        if (files) {
            for (let file of files) {
                let reader = new FileReader();
                reader.onload = (e: any) => {
                    this.photos.push(this.createItem({
                        file,
                        url: e.target.result  //Base64 string for preview image
                    }));
                }
                reader.readAsDataURL(file);
            }
        }
    }

    breadcrumb = [
        {
            title: 'Add Product',
            subTitle: 'Dashboard'
        }
    ]

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        // if (this.form.invalid) {
        //     return;
        // }

        this.loading = true;
       
            this.createproduct();
        
    }

    private createproduct() {
        const formData = new FormData();
        formData.append('name', this.form.get('name').value);
        formData.append('nameE', this.form.get('nameE').value);
        formData.append('describtion', this.form.get('describtion').value);
        formData.append('image', this.form.get('image').value);
        formData.append('avator', this.imagePath);
        formData.append('imagePath', '/product/');
        formData.append('subId', this.form.get('subId').value);
        formData.append('categoryId', this.form.get('categoryId').value);
        formData.append('businessId', '1');
        formData.append('avatorPath', '/product/');
        formData.append('parentId', this.form.get('parentId').value);
        formData.append('accountId', this.account.id);

        
        this.productService.create(formData)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                    this.router.navigate(['../'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

}