import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Product,ProductFaq } from '../_models/';

const baseUrl = `${environment.apiUrl}/product`;
const faqUrl = `${environment.apiUrl}/productfaq`;

@Injectable({ providedIn: 'root' })
export class ProductService {
    private productSubject: BehaviorSubject<Product>;
    public product: Observable<Product>;
    private productFaqSubject: BehaviorSubject<ProductFaq>;
    public productFaq: Observable<ProductFaq>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.productSubject = new BehaviorSubject<Product>(null);
        this.product = this.productSubject.asObservable();
        this.productFaqSubject = new BehaviorSubject<ProductFaq>(null);
        this.productFaq = this.productFaqSubject.asObservable();
    }

    public get productValue(): Product {
        return this.productSubject.value;
    }
    public get productFaqValue(): ProductFaq {
        return this.productFaqSubject.value;
    }

    getAll() {
        return this.http.get<Product[]>(baseUrl);
    }
    getOne(productCode: string) {
        return this.http.get<Product[]>(`${baseUrl}/one/${productCode}`);
    }
    getAllProductDetail(productCode: string) {
        return this.http.get<Product[]>(`${baseUrl}/all/${productCode}`);
    }

    getById(id: string) {
        return this.http.get<Product>(`${baseUrl}/${id}`);
    }
    
    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((product: any) => {
                    // publish updated Product to subscribers
                    product = { ...this.productValue, ...product };
                    this.productSubject.next(product);
              
                return product;
            }));
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }

    getGigFaq(productCode: string) {
        return this.http.get<Product>(`${faqUrl}/product/${productCode}`);
    }
    
    createFaq(params) {
        return this.http.post(faqUrl, params);
    }
    
    updateFaq(id, params) {
        return this.http.put(`${faqUrl}/${id}`, params)
            .pipe(map((productFaq: any) => {
                    // publish updated Product to subscribers
                    productFaq = { ...this.productFaqValue, ...productFaq };
                    this.productFaqSubject.next(productFaq);
              
                return productFaq;
            }));
    }
    deleteFaq(id: string) {
        return this.http.delete(`${faqUrl}/${id}`)
            .pipe(finalize(() => {
            }));
    }
   

    
}