<app-webNavBar></app-webNavBar>


<!-- Start Checkout Area -->
<section>
    <div class="box">
            <div class="row board">

                        <div class="col-lg-4 box" style="background-color: #FF6700;">
                            <h4> {{apps.productName}}</h4>
                           </div>
                       <div class="col-lg-8 box" style="background-color: #FFF;">
                      <div class="order-details">
                      <h5>Asfaw, start your 1-month free trial now!</h5>  
                      </div>
                       </div>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
        
            <div class="row ">
                <div class="col-md-1">
                    <button class="default-btn" style="background-color: #FF6700;color: #FFF;"> <span class="icon">2</span></button>
                </div>
           
           <div class="col-md-11">
            <h5>Select your payment method
            </h5>  
           </div>
            </div>
            <div class="row board">
                <div class="payment" style="background-color: #FFF;">
                    <div class="paymentType">
                      
                     <div class="row">
                        <div class="col-md-4 paymentMethod" ng-click="pMethod = 'pMethod'">
                            <div class="row"> 
                                <div class="col-md-1">
                                    <input type="radio" id="paymentMethod" formControlName="paymentMethod" value="directTranser" ng-model="paymentMethod">
                                </div>
                                <div class="col-md-11"><label for="directTranser">Direct Bank Transfer</label></div>
                            </div>
                           
                            <div class="row">
                                <img class="col-md-3 logo" src="assets/img/logo/cbe.jpg" alt="image">
                                <img class="col-md-3 logo" src="assets/img/logo/abyssinina.jpeg" alt="image">
                                <img class="col-md-3 logo" src="assets/img/logo/coop.png" alt="image">
                                <img class="col-md-3 logo" src="assets/img/logo/oromiya.png" alt="image">
                            </div>
                           
                        </div>
                        <div class="col-md-4 paymentMethod" ng-click="paymentMethod = 'paymentMethod'">
                            <div class="row"> 
                                <div class="col-md-1">
                                    <input type="radio" id="paymentMethod" name="paymentMethod" formControlName="paymentMethod" value="mobileWallet" ng-model="paymentMethod">
                                </div>
                                <div class="col-md-11"><label for="mobileWallet">Mobile Wallet</label></div>
                            </div>
                            <div class="row">
                                <img class="col-md-3 logo" src="assets/img/logo/TeleBirr.png" alt="image">
                                <img class="col-md-3 logo" src="assets/img/logo/abyssinina.jpeg" alt="image">
                                <img class="col-md-3 logo" src="assets/img/logo/coop.png" alt="image">
                                <img class="col-md-3 logo" src="assets/img/logo/oromiya.png" alt="image">
                            </div>
                           
                        </div>
                        <div class="col-md-4 paymentMethod" ng-click="paymentMethod = 'paymentMethod'">
                            <div class="row"> 
                                <div class="col-md-1">
                                    <input type="radio" id="paymentMethod" name="paymentMethod" formControlName="paymentMethod" value="mastercard" ng-model="paymentMethod">
                                </div>
                                <div class="col-md-11"><label for="mastercard">Direct Bank Transfer</label></div>
                            </div>
                            <div class="row">
                                <img class="col-md-6 logo" src="assets/img/logo/mastercard.jpeg" alt="image">
                                <img class="col-md-6 logo" src="assets/img/logo/visa.png" alt="image">
                            </div>
                           
                        </div>
                     </div>
                     <div class="row formBox board">
                        <div class="">

                                  <div class="row col-lg-6" >   
                                    <div class="form-group">
                                        <label >Bank</label>
                                        <select formControlName="bankId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.bankId.errors }"  (change)="onBankSelect($event.target.value)">
                                            <option value=""> Select Bank</option>
                                            <option *ngFor="let bankAccount of BankAccounts" value="{{bankAccount.bankId}}" >{{bankAccount.bank.nameA}}</option>
                                        </select>
                                        <div *ngIf="submitted && f.bankId.errors" class="invalid-feedback">
                                            <div *ngIf="f.bankId.errors.required">Please Select Bank</div>
                                        </div>
                                    </div>
                                    <div *ngFor="let bank of BankAccounts">
                                  <div class="form-group" *ngIf="selectedBank == bank.bankId" >
                                    <label>Account Name</label>
                                    <input type="text"  class="form-control" formControlName="accountName"   [ngModel]="bank.accountName" [ngClass]="{ 'is-invalid': submitted && f.accountName.errors }"   />
                                    <div *ngIf="submitted && f.accountName.errors" class="invalid-feedback">
                                        <div *ngIf="f.accountName.errors.required">Account Name is required</div>
                                    </div>
                                  </div>
                                  <div class="form-group" *ngIf="selectedBank == bank.bankId" >
                                    <label>Account Number  </label>
                                    <input type="text" class="form-control" formControlName="accountNumber"  [ngClass]="{ 'is-invalid': submitted && f.accountNumber.errors }"   [ngModel]="bank.accountNumber" />
                                    <div *ngIf="submitted && f.accountNumber.errors" class="invalid-feedback">
                                        <div *ngIf="f.accountNumber.errors.required">Account Number is required</div>
                                    </div>
                                </div>
                            </div>
                                <div class="form-group" *ngIf="pricing">
                                      <label>Amount</label>
                                      <input type="text" formControlName="amount" class="form-control"  [ngModel]="pricing[0].price" [ngClass]="{ 'is-invalid': submitted && f.amount.errors }"  />
                                      <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                                          <div *ngIf="f.amount.errors.required">Amount is required</div>
                                          <div *ngIf="f.amount.errors.email">Amount is invalid</div>
                                      </div>
                                </div>
                                <div class="form-group">
                                      <label>Mobile</label>
                                      <input type="text" formControlName="mobile" class="form-control"  [ngModel]="account.mobile" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                                      <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                          <div *ngIf="f.mobile.errors.required">Mobile number is required</div>
                                          <div *ngIf="f.mobile.errors?.pattern">Mobile number should be 10 digit start with 09</div>
                                      </div>
                               </div>
                              </div> 
                              <div class="form-group">
                                     <input type="hidden" formControlName="applcationOrderId" [ngModel]="apps.id">
                                     <input type="hidden" formControlName="applcationId" [ngModel]="apps.applcationId">
                                      <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
                                 
                                      <label for="acceptTerms" class="form-check-label" >Accept Terms & Conditions</label>
                                 
                                  <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Accept Ts & Cs is required</div>
                              </div>
                              
                        
                        </div>
                     </div>
                    </div>
                 
                </div>
            </div>
            <button type="submit" class="default-btn"><i class="flaticon-tick"></i>Place Order</button>
     </form>
    </div>
</section>
<!-- End Checkout Area -->

<app-footer-style-one></app-footer-style-one>