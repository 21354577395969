import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService, AlertService } from '../../../../_services';
import { MustMatch } from '../../../../_helpers';


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
  })
export class RegisterComponent implements OnInit {
    form: FormGroup;
    loading = false;
    account = [];
    response =[];
    appCode ; string;
    planId: string;
    period: string;
    submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.appCode = this.route.snapshot.params['appCode'];
        this.planId = this.route.snapshot.params['planId'];
        this.period = this.route.snapshot.params['period'];
        this.form = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            tinNumber: ['', Validators.required],
            loginType: ['Web'],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required],
            acceptTerms: [false, Validators.requiredTrue],
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        
        this.loading = true;
        
 this.accountService.register(this.form.value)
            .pipe(first())
            .subscribe({
                
                next: () => {
                    var account = this.account = account;
                    console.log(account);
                    
                    this.alertService.success(account, { keepAfterRouteChange: true });
                    this.router.navigate(['/otp/'+this.appCode+'/'+ this.planId+'/'+ this.period +'/'+ this.f.email.value], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
}