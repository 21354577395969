

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>


    <!-- Start -->
    <div class="row">
    
        <div class="col-lg-4 col-md-12">
            <div class="my-profile-box">
    
                <div class="author-sidebar">
                    <div class="user-profile">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/user3.jpg" alt="image">
    
                            <div class="title">
                                <h4>{{account.firstName}}  {{account.lastName}}</h4>
                                <span class="sub-title">Own Company</span>
                                <div class="rating d-flex align-items-center">
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="rating-count">(5 reviews)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="user-contact-info">
                 

                    <ul class="user-contact">
                        <li><a href="#"><i class='bx bx-phone-call'></i> {{account.mobile}}</a></li>
                        <li><a href="#"><i class='bx bx-envelope'></i> {{account.email}}</a></li>
                    </ul>
                    <hr>
                    <ul class="social-profiles">
                        <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                        <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                    </ul>

             
                </div>
            </div>
            <div class="my-profile-box">
    
                <div class="user-contact-info">
                    <h3>Contact</h3>

                    <ul class="user-contact">
                        <li><a href="#"><i class='bx bx-phone-call'></i> (+44) - 45789 - 5789</a></li>
                        <li><a href="#"><i class='bx bx-envelope'></i> hello@jamesandy.com</a></li>
                    </ul>

                    <ul class="social-profiles">
                        <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                        <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                    </ul>

                    <form>
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="name" class="form-control" id="name" required placeholder="Your name">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="email" name="email" class="form-control" id="email" required placeholder="Your email address">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" class="form-control" id="phone_number" required placeholder="Your phone number">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" id="message" class="form-control" cols="30" rows="6" required placeholder="Write your message..."></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
       
        <div class="col-lg-8 col-md-12">
            <div class="my-profile-box">
                <h3>Freelancer Bio</h3>
    
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="row">
                        
                        
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Motto</label>
                                <input type="text" class="form-control" formControlName="motto"  placeholder="">
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Website</label>
                                <input type="text" class="form-control" formControlName="website"  placeholder="ayer.com">
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Bio</label>
                                <textarea cols="30" rows="6" formControlName="bio"  placeholder="Short description about you..." class="form-control"></textarea>
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Facebook URL</label>
                                <input type="text" class="form-control" formControlName="facebookUrl"  placeholder="https://www.facebook.com/">
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Instagram URL</label>
                                <input type="text" class="form-control" formControlName="instagramUrl"  placeholder="https://instagram.com/">
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Linkedin URL</label>
                                <input type="text" class="form-control" formControlName="linkedInUrl"   placeholder="https://www.linkedin.com/">
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>TikTok URL</label>
                                <input type="text" class="form-control" formControlName="tiktokUrl"  placeholder="https://tiktok.com/">
                            </div>
                        </div>
    
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <button type="submit">Save Change</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-footer-style-two></app-footer-style-two>

</div>
<!-- End Main Content Wrapper Area -->