import { NgModule } from '@angular/core';
import { Routes, RouterModule ,ExtraOptions} from '@angular/router';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { AuthorProfileComponent } from './components/pages/author-profile/author-profile.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { DashboardAddListingsComponent } from './components/pages/dashboard/dashboard-add-listings/dashboard-add-listings.component';
import { DashboardBookingsComponent } from './components/pages/dashboard/dashboard-bookings/dashboard-bookings.component';
import { DashboardBookmarksComponent } from './components/pages/dashboard/dashboard-bookmarks/dashboard-bookmarks.component';
import { DashboardInvoiceComponent } from './components/pages/dashboard/dashboard-invoice/dashboard-invoice.component';
import { DashboardMessagesComponent } from './components/pages/dashboard/dashboard-messages/dashboard-messages.component';
import { DashboardMyProfileComponent } from './components/pages/dashboard/dashboard-my-profile/dashboard-my-profile.component';
import { DashboardReviewsComponent } from './components/pages/dashboard/dashboard-reviews/dashboard-reviews.component';
import { DashboardWalletComponent } from './components/pages/dashboard/dashboard-wallet/dashboard-wallet.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { AppBoardComponent } from './components/pages/appboard/appboard.component';
import { EventsDetailsComponent } from './components/pages/events-details/events-details.component';
import { EventsComponent } from './components/pages/events/events.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { GridListingsFullWidthComponent } from './components/pages/grid-listings-full-width/grid-listings-full-width.component';
import { GridListingsLeftSidebarComponent } from './components/pages/grid-listings-left-sidebar/grid-listings-left-sidebar.component';
import { GridListingsRightSidebarComponent } from './components/pages/grid-listings-right-sidebar/grid-listings-right-sidebar.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { ListingsDetailsComponent } from './components/pages/listings-details/listings-details.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
// import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ProductsListComponent } from './components/pages/products-list/products-list.component';
import { TopPlaceComponent } from './components/pages/top-place/top-place.component';
import { VerticalListingsFullWidthComponent } from './components/pages/vertical-listings-full-width/vertical-listings-full-width.component';
import { VerticalListingsLeftSidebarComponent } from './components/pages/vertical-listings-left-sidebar/vertical-listings-left-sidebar.component';
import { VerticalListingsRightSidebarComponent } from './components/pages/vertical-listings-right-sidebar/vertical-listings-right-sidebar.component';
import { UccComponent } from './components/pages/onetap/ucc/ucc.component';
import { OneTapComponent } from './components/pages/onetap/onetap.component';
import { MarketPlaceComponent } from './components/pages/onetap/marketplace/marketplace.component';
import { PaidAppComponent } from './components/pages/onetap/paidapp/paidapp.component';
import { UnPaidAppComponent } from './components/pages/onetap/unpaidapp/unpaidapp.component';
import { BpoComponent } from './components/pages/onetap/bpo/bpo.component';
import { AplicationComponent } from './components/pages/onetap/app/app.component';
import { SalesComponent } from './components/pages/onetap/sales/sales.component';
import { AlertComponent } from './_components';
import { HomeComponent } from './components/pages/home';
import { AppHomeComponent } from './components/pages/onetap/home/home.component';
import { PlanComponent } from './components/pages/plan/plan.component';
import { PlanDetailComponent } from './components/pages/planDetail/planDetail.component';
import { CheckoutAuthComponent } from './components/pages/onetap/checkoutAuth/checkoutAuth';
import { LoginComponent } from './components/pages/onetap/login/login.component';
import { RegisterComponent } from './components/pages/onetap/register/register.component';
import { OtpComponent } from './components/pages/onetap/otp/otp.component';
import { ProductsDetailsComponent } from './components/pages/onetap/productdetails/productdetails.component';
import { AddProductComponent } from './components/pages/appProduct/product-add.component';
import { ProfileComponent } from './components/pages/freelancer/profile/profile.component';
import { BioComponent } from './components/pages/freelancer/bio/bio.component';
import { FreelancerAddressComponent } from './components/pages/freelancer/address/address.component';
import { LanguageComponent } from './components/pages/freelancer/langauge/language.component';
import { EducationComponent } from './components/pages/freelancer/education/eudcation.component';
import { SkillComponent } from './components/pages/freelancer/skill/skill.component';
import { CertaficationComponent } from './components/pages/freelancer/certafication/certafication.component';
import { ChangePasswordComponent } from './components/pages/freelancer/changePassword/changePassword.component';
import { ProfileInfoComponent } from './components/pages/freelancer/profileInfo/profileInfo.component';
import { ProfileImageComponent } from './components/pages/freelancer/profileImage/profileImage.component';
import { FourReasonComponent } from './components/pages/freelancer/4reason/4reason.component';
import { GigPricingComponent } from './components/pages/onetap/gigPricing/gigPricing.component';
import { TopNavComponent } from './components/common/topNav/topNav.component';
import { SellerNavComponent } from './components/common/sellerNav/sellerNav.component';
import { MyGigComponent } from './components/pages/dashboard/mygig/mygig.component';
import { GigPlanComponent } from './components/pages/dashboard/gigplan/gigplan.component';
import { GigFaqComponent } from './components/pages/dashboard/gigfaq/gigfaq.component';
import { AfterLoginComponent } from './components/pages/home-demo-two/afterlogin/afterlogin.component';
import { VerifyEmailComponent } from './components/pages/onetap/verify-email/verify-email.component';
//product
//product
import { ProductsComponent } from './components/pages/onetap/products/products.component';
import { AuthGuard } from './_helpers';
import { Role } from './_models';
import { GigDetailComponent } from './components/pages/dashboard/gigdetail/gigdetail.component';

//appl
const authModule = () => import('./components/pages/auth/auth.module').then(x => x.AuthModule);
const applicationModule = () => import('./components/pages/applcation/applcation.module').then(x => x.ApplcationModule);
const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
  };
const routes: Routes = [
    {path: '', component: HomeDemoTwoComponent },
    { path: 'auth', loadChildren: authModule },
    { path: 'app',component: AplicationComponent},
    {path: 'marketplace', component: MarketPlaceComponent},
    {path: 'home', component: AppHomeComponent},
    {path: 'apps', component: HomeComponent},
    {path: 'index-2', component: HomeDemoTwoComponent},
    // {path: 'about', component: AboutUsComponent},
    // {path: 'how-it-works', component: HowItWorksPageComponent},
    // {path: 'pricing', component: PricingComponent},
    // {path: 'gallery', component: GalleryComponent},
    // {path: 'faq', component: FaqComponent},
    // {path: 'coming-soon', component: ComingSoonComponent},
    // {path: 'contact', component: ContactComponent},
    // {path: 'blog-grid', component: BlogGridComponent},
    // {path: 'blog-right-sidebar', component: BlogRightSidebarComponent},
    // {path: 'blog-details', component: BlogDetailsComponent},
    // {path: 'products-list', component: ProductsListComponent},
    // {path: 'cart/:appCode', component: CartComponent},
    // {path: 'checkout', component: CheckoutComponent},
    // {path: 'user-profile', component: AuthorProfileComponent},
    // {path: 'categories', component: CategoriesComponent},
    // {path: 'destinations', component: TopPlaceComponent},
    // {path: 'vertical-listings-left-sidebar', component: VerticalListingsLeftSidebarComponent},
    // {path: 'vertical-listings-right-sidebar', component: VerticalListingsRightSidebarComponent},
    // {path: 'vertical-listings-full-width', component: VerticalListingsFullWidthComponent},
    // {path: 'grid-listings-left-sidebar', component: GridListingsLeftSidebarComponent},
    // {path: 'grid-listings-right-sidebar', component: GridListingsRightSidebarComponent},
    // {path: 'grid-listings-full-width', component: GridListingsFullWidthComponent},
    // {path: 'single-listings', component: ListingsDetailsComponent},
    // {path: 'events', component: EventsComponent},
    // {path: 'single-events', component: EventsDetailsComponent},
    // {path: 'dashboard', component: DashboardComponent},
    // {path: 'dashboard-messages', component: DashboardMessagesComponent},
    // {path: 'dashboard-wallet', component: DashboardWalletComponent},
    // {path: 'dashboard-reviews', component: DashboardReviewsComponent},
    // {path: 'dashboard-invoice', component: DashboardInvoiceComponent},
    // {path: 'dashboard-my-profile', component: DashboardMyProfileComponent},
    // {path: 'addproduct', component: DashboardAddListingsComponent},
    // {path: 'dashboard-bookmarks', component: DashboardBookmarksComponent},
    {path: 'appboard', component: AppBoardComponent},
    {path: 'productDetail/:appId/:planId', component: ProductsDetailsComponent},
    {path: 'addproduct', component: AddProductComponent},
    {path: 'ucc', component: UccComponent},
    {path: 'bpo', component: BpoComponent},
    {path: 'sales', component: SalesComponent},
    {path: 'plan/:appCode', component: PlanComponent},
    {path: 'planDetail/:appCode/:planId/:period', component: PlanDetailComponent},
    {path: 'cart/:appCode/:planId/:period', component: CartComponent},
    {path: 'checkout/:appCode/:planId/:period', component: CheckoutComponent},
    {path: 'checkoutauth/:appCode/:planId/:period', component: CheckoutAuthComponent},
    {path: 'login/:appCode/:planId/:period', component: LoginComponent},
    {path: 'login', component: LoginComponent},
    {path: 'activate', component: VerifyEmailComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'otp/:appCode/:planId/:period/:email', component: OtpComponent},
    {path: 'detail/:productCode', component: ProductsDetailsComponent},
    {path: 'bio', component: BioComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'address', component: FreelancerAddressComponent},
    {path: 'language', component: LanguageComponent},
    {path: 'education', component: EducationComponent},
    {path: 'skill', component: SkillComponent},
    {path: 'certafication', component: CertaficationComponent},
    {path: '4reason', component: FourReasonComponent},
    {path: 'changepassword', component: ChangePasswordComponent},
    {path: 'profileinfo', component: ProfileInfoComponent},
    {path: 'profileimage', component: ProfileImageComponent},
    {path: 'mygig', component: MyGigComponent},
    {path: 'gigdetail/:productCode', component:GigDetailComponent },
    {path: 'gigpricing/:productCode', component:GigPlanComponent },
    {path: 'gigfaq/:productCode', component:GigFaqComponent },
    
    
    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];


@NgModule({
    imports: [RouterModule.forRoot(routes,routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }