
 <section class="listing-area">
    <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-6"  *ngFor="let app of apps">
            <div class="single-listings-box" *ngIf="app.paymentStatus">
                <div class="listings-image">
                    <div class="listings-image-slides">
                        <div class="post-image">
                            <a routerLink="/plan/{{app.applcationCode}}" class="d-block">
                                <img src="assets/img/onetapcare.jpg" alt="image">
                            </a>
                        </div>
                    </div>
                    <a routerLink="/{{app.applcationName}}" class="bookmark-save"><i class="flaticon-heart"></i></a>
                    <a routerLink="/{{app.applcationName}}" class="category"><i class="flaticon-cooking"></i></a>
                </div>
                <div class="listings-content">
                    <div class="author">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/onetapcare.jpg" alt="image">
                            <span>{{app.applcationName}}</span>
                        </div>
                    </div>
                    <ul class="listings-meta">
                        <li><a routerLink="/{{app.applcationName}}"><i class="flaticon-furniture-and-household"></i> Restaurant</a></li>
                        <li><i class="flaticon-pin"></i> {{app.applcationName}}</li>
                    </ul>
                    <h3><a routerLink="/{{app.applcationName}}">{{app.applcationName}}</a></h3>
                    <span class="status {{app.applcationName}}"><i class="flaticon-save"></i> {{app.applcationName}}</span>
                   
                </div>
                <div class="listings-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <a routerLink="/" class="default-btn">Edit</a>
                        <a routerLink="/" class="default-btn">Delete</a>
                    </div>
                </div>
            </div>
        </div>

       
    </div>
</section>



