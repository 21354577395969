import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CategoryService,ProductService, AccountService,AlertService } from '../../../../_services';
import { MustMatch } from '../../../../_helpers';
import { Account } from 'src/app/_models';
@Component({
    selector: 'app-dashboard-add-listings',
    templateUrl: './dashboard-add-listings.component.html',
    styleUrls: ['./dashboard-add-listings.component.scss']
})
export class DashboardAddListingsComponent implements OnInit {

    form: FormGroup;
    id: string;
    parents;
    categoryId;
    categories;
    subCategories;
    subCategoryId;
    childCategories;
    sub;
    logoSrc: string = '';
    imagePath: string='';
    isAddMode: boolean;
    loading = false;
    submitted = false;
    account = this.accountService.accountValue;
    
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private productService: ProductService,
        private accountService: AccountService,
        private categoryService: CategoryService,
        private alertService: AlertService
    ) {
        
    }

    ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;
        this.categoryService.getCategoryLayer(1)
            .pipe(first())
            .subscribe(categories => this.categories = categories);

        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            nameE: ['', Validators.required],
            describtion: ['', Validators.required],
            image: [null],
            logo: [null],
            imagePath:  [''],
            avatorPath:  ['/product/'],
            subId: ['', Validators.required],
            categoryId: ['', Validators.required],
            businessId: ['', Validators.required],
            parentId: ['', Validators.required],
            accountId: this.account.id,
        });

        if (!this.isAddMode) {
            this.productService.getById(this.id)
                .pipe(first())
                .subscribe(x => this.form.patchValue(x));
        }
    }
    onCategorySelect(value) {
        this.categoryId = value;
        this.categoryService.getAllSubCategoryById(this.categoryId)
        .pipe(first())
        .subscribe(subCategories => this.subCategories = subCategories);
    }
    onsubCategorySelect(value) {
        this.subCategoryId = value;
        this.categoryService.getAllChildCategoryById(this.subCategoryId)
        .pipe(first())
        .subscribe(childCategories => this.childCategories = childCategories);
    }
    onFileChange(event) {
        const reader = new FileReader();
        
        if(event.target.files && event.target.files.length) {
          const [file] = event.target.files;
          reader.readAsDataURL(file);
        
          reader.onload = () => {
            var orginal = file.name
            this.imagePath= orginal.replace(/[^a-zA-Z0-9.]/g, '');
            this.logoSrc = reader.result as string;
            this.form.patchValue({
              image: file
            });
           
       
          };
       
        }
      }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        // if (this.form.invalid) {
        //     return;
        // }

        this.loading = true;
        if (this.isAddMode) {
            this.createproduct();
        } else {
            this.updateproduct();
        }
    }

    private createproduct() {
        const formData = new FormData();
        formData.append('name', this.form.get('name').value);
        formData.append('nameE', this.form.get('nameE').value);
        formData.append('describtion', this.form.get('describtion').value);
        formData.append('image', this.form.get('image').value);
        formData.append('avator', this.imagePath);
        formData.append('imagePath', '/product/');
        formData.append('subId', this.form.get('subId').value);
        formData.append('categoryId', this.form.get('categoryId').value);
        formData.append('businessId', '1');
        formData.append('avatorPath', '/product/');
        formData.append('parentId', this.form.get('parentId').value);
        formData.append('accountId', this.account.id);

        
        this.productService.create(formData)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('product created successfully', { keepAfterRouteChange: true });
                    this.router.navigate(['../'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    private updateproduct() {
        this.productService.update(this.id, this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.router.navigate(['../../'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
    
    breadcrumb = [
        {
            title: 'Add Listings',
            subTitle: 'Dashboard'
        }
    ]

}