import { BrowserModule } from '@angular/platform-browser';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AuthGuard } from './_helpers';
import { Role } from './_models';

import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { ComponentsModule } from './layout/index';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountService } from './_services';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { AppDownloadComponent } from './components/common/app-download/app-download.component';
import { HowItWorksComponent } from './components/common/how-it-works/how-it-works.component';
import { HomeoneBlogComponent } from './components/pages/home-demo-one/homeone-blog/homeone-blog.component';
import { FeedbackStyleOneComponent } from './components/common/feedback-style-one/feedback-style-one.component';
import { HomeoneDestinationsComponent } from './components/pages/home-demo-one/homeone-destinations/homeone-destinations.component';
import { CategoryComponent } from './components/common/category/category.component';
import { HomeoneListingsComponent } from './components/pages/onetap/homeone-listings/homeone-listings.component';
import { FeaturesComponent } from './components/common/features/features.component';
import { HomeoneBannerComponent } from './components/pages/home-demo-one/homeone-banner/homeone-banner.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { HometwoBannerComponent } from './components/pages/home-demo-two/hometwo-banner/hometwo-banner.component';
import { HometwoListingsComponent } from './components/pages/home-demo-two/hometwo-listings/hometwo-listings.component';
import { HometwoDestinationsComponent } from './components/pages/home-demo-two/hometwo-destinations/hometwo-destinations.component';
import { HometwoEventsComponent } from './components/pages/home-demo-two/hometwo-events/hometwo-events.component';
import { HometwoBlogComponent } from './components/pages/home-demo-two/hometwo-blog/hometwo-blog.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { TeamComponent } from './components/common/team/team.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ProductsListComponent } from './components/pages/products-list/products-list.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
// import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { RelatedProductsComponent } from './components/pages/products-details/related-products/related-products.component';
import { AuthorProfileComponent } from './components/pages/author-profile/author-profile.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { TopPlaceComponent } from './components/pages/top-place/top-place.component';
import { ListingsDetailsComponent } from './components/pages/listings-details/listings-details.component';
import { EventsDetailsComponent } from './components/pages/events-details/events-details.component';
import { EventsComponent } from './components/pages/events/events.component';
import { VerticalListingsLeftSidebarComponent } from './components/pages/vertical-listings-left-sidebar/vertical-listings-left-sidebar.component';
import { VerticalListingsRightSidebarComponent } from './components/pages/vertical-listings-right-sidebar/vertical-listings-right-sidebar.component';
import { VerticalListingsFullWidthComponent } from './components/pages/vertical-listings-full-width/vertical-listings-full-width.component';
import { GridListingsLeftSidebarComponent } from './components/pages/grid-listings-left-sidebar/grid-listings-left-sidebar.component';
import { GridListingsRightSidebarComponent } from './components/pages/grid-listings-right-sidebar/grid-listings-right-sidebar.component';
import { GridListingsFullWidthComponent } from './components/pages/grid-listings-full-width/grid-listings-full-width.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { DashboardNavbarComponent } from './components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from './components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { SellerSidemenuComponent } from './components/common/seller-sidebar/seller-sidemenu.component';
import { CopyrightsComponent } from './components/pages/dashboard/copyrights/copyrights.component';
import { StatsComponent } from './components/pages/dashboard/stats/stats.component';
import { RecentActivitiesComponent } from './components/pages/dashboard/recent-activities/recent-activities.component';
import { DashboardMessagesComponent } from './components/pages/dashboard/dashboard-messages/dashboard-messages.component';
import { DashboardBookingsComponent } from './components/pages/dashboard/dashboard-bookings/dashboard-bookings.component';
import { DashboardWalletComponent } from './components/pages/dashboard/dashboard-wallet/dashboard-wallet.component';
import { DashboardReviewsComponent } from './components/pages/dashboard/dashboard-reviews/dashboard-reviews.component';
import { DashboardInvoiceComponent } from './components/pages/dashboard/dashboard-invoice/dashboard-invoice.component';
import { DashboardMyProfileComponent } from './components/pages/dashboard/dashboard-my-profile/dashboard-my-profile.component';
import { DashboardAddListingsComponent } from './components/pages/dashboard/dashboard-add-listings/dashboard-add-listings.component';
import { DashboardBookmarksComponent } from './components/pages/dashboard/dashboard-bookmarks/dashboard-bookmarks.component';
import { AppBoardComponent } from './components/pages/appboard/appboard.component';
import { PaidAppComponent } from './components/pages/onetap/paidapp/paidapp.component';
import { UnPaidAppComponent } from './components/pages/onetap/unpaidapp/unpaidapp.component';
import { ProductsDetailsComponent } from './components/pages/onetap/productdetails/productdetails.component';


//new
import { OneTapNavComponent } from './components/common/webNavbar/webNavBar.component';
import { OneTapComponent } from './components/pages/onetap/onetap.component';
import { MarketPlaceComponent } from './components/pages/onetap/marketplace/marketplace.component';
import { BpoComponent } from './components/pages/onetap/bpo/bpo.component';
import { UccComponent } from './components/pages/onetap/ucc/ucc.component';
import { SalesComponent } from './components/pages/onetap/sales/sales.component';
import { BpoServicesComponent } from './components/pages/onetap/bpoServices/bpoServices.component';
import { SalesServicesComponent } from './components/pages/onetap/salesServices/salesServices.component';
import { AplicationComponent } from './components/pages/onetap/app/app.component';
import { AppListComponent } from './components/pages/onetap/appList/appList.component';
import { HomeComponent } from './components/pages/home';
import { PlanComponent } from './components/pages/plan/plan.component';
import { PlanDetailComponent } from './components/pages/planDetail/planDetail.component';
import { CheckoutAuthComponent } from './components/pages/onetap/checkoutAuth/checkoutAuth';
import { LoginComponent } from './components/pages/onetap/login/login.component';
import { RegisterComponent } from './components/pages/onetap/register/register.component';
import { OtpComponent } from './components/pages/onetap/otp/otp.component';
import { AppHomeComponent } from './components/pages/onetap/home/home.component';
import { AlertComponent } from './_components';
import { ProfileComponent } from './components/pages/freelancer/profile/profile.component';
import { BioComponent } from './components/pages/freelancer/bio/bio.component';
import { FreelancerAddressComponent } from './components/pages/freelancer/address/address.component';
import { LanguageComponent } from './components/pages/freelancer/langauge/language.component';
import { EducationComponent } from './components/pages/freelancer/education/eudcation.component';
import { SkillComponent } from './components/pages/freelancer/skill/skill.component';
import { CertaficationComponent } from './components/pages/freelancer/certafication/certafication.component';
import { AddProductComponent } from './components/pages/appProduct/product-add.component';
import { FreelancerNavbarComponent } from './components/pages/freelancer/freelancer-navbar/freelancer-navbar.component';
import { ChangePasswordComponent } from './components/pages/freelancer/changePassword/changePassword.component';
import { ProfileInfoComponent } from './components/pages/freelancer/profileInfo/profileInfo.component';
import { ProfileImageComponent } from './components/pages/freelancer/profileImage/profileImage.component';
import { FourReasonComponent } from './components/pages/freelancer/4reason/4reason.component';
import { TopNavComponent } from './components/common/topNav/topNav.component';
import { SellerNavComponent } from './components/common/sellerNav/sellerNav.component';
import { GigPricingComponent } from './components/pages/onetap/gigPricing/gigPricing.component';
import { MyGigComponent } from './components/pages/dashboard/mygig/mygig.component';
import { GigDetailComponent } from './components/pages/dashboard/gigdetail/gigdetail.component';
import { GigPlanComponent } from './components/pages/dashboard/gigplan/gigplan.component';
import { GigFaqComponent } from './components/pages/dashboard/gigfaq/gigfaq.component';
import { AfterLoginComponent } from './components/pages/home-demo-two/afterlogin/afterlogin.component';
import { VerifyEmailComponent } from './components/pages/onetap/verify-email/verify-email.component';
//products
import { ProductsComponent } from './components/pages/onetap/products/products.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeDemoOneComponent,
    HomeDemoTwoComponent,
    AppDownloadComponent,
    HowItWorksComponent,
    HomeoneBlogComponent,
    FeedbackStyleOneComponent,
    HomeoneDestinationsComponent,
    CategoryComponent,
    HomeoneListingsComponent,
    FeaturesComponent,
    HomeoneBannerComponent,
    FooterStyleOneComponent,
    NavbarStyleOneComponent,
    NavbarStyleTwoComponent,
    HometwoBannerComponent,
    HometwoListingsComponent,
    HometwoDestinationsComponent,
    HometwoEventsComponent,
    HometwoBlogComponent,
    ComingSoonComponent,
    NotFoundComponent,
    AboutUsComponent,
    PartnerComponent,
    TeamComponent,
    FunfactsComponent,
    HowItWorksPageComponent,
    PricingComponent,
    GalleryComponent,
    FaqComponent,
    ContactComponent,
    FooterStyleTwoComponent,
    BlogGridComponent,
    BlogRightSidebarComponent,
    BlogDetailsComponent,
    ProductsListComponent,
    CartComponent,
    CheckoutComponent,
    ProductsDetailsComponent,
    RelatedProductsComponent,
    AuthorProfileComponent,
    CategoriesComponent,
    TopPlaceComponent,
    ListingsDetailsComponent,
    EventsDetailsComponent,
    EventsComponent,
    VerticalListingsLeftSidebarComponent,
    VerticalListingsRightSidebarComponent,
    VerticalListingsFullWidthComponent,
    GridListingsLeftSidebarComponent,
    GridListingsRightSidebarComponent,
    GridListingsFullWidthComponent,
    DashboardComponent,
    DashboardNavbarComponent,
    DashboardSidemenuComponent,
    CopyrightsComponent,
    StatsComponent,
    RecentActivitiesComponent,
    DashboardMessagesComponent,
    DashboardBookingsComponent,
    DashboardWalletComponent,
    DashboardReviewsComponent,
    DashboardInvoiceComponent,
    DashboardMyProfileComponent,
    DashboardAddListingsComponent,
    DashboardBookmarksComponent,
    OneTapNavComponent,
    OneTapComponent,
    MarketPlaceComponent,
    UccComponent,
    BpoComponent,
    AlertComponent,
    SalesComponent,
    BpoServicesComponent,
    SalesServicesComponent,
    AplicationComponent,
    AppListComponent,
    HomeComponent,
    PlanComponent,
    PlanDetailComponent,
    CheckoutAuthComponent,
    LoginComponent,
    RegisterComponent,
    OtpComponent,
    AppHomeComponent,
    AppBoardComponent,
    PaidAppComponent,
    UnPaidAppComponent,
    ProductsDetailsComponent,
    AddProductComponent,
    ProductsComponent,
    SellerSidemenuComponent,
    ProfileComponent,
    BioComponent,
    FreelancerAddressComponent,
    LanguageComponent,
    EducationComponent,
    SkillComponent,
    CertaficationComponent,
    FourReasonComponent,
    FreelancerNavbarComponent,
    ChangePasswordComponent,
    ProfileInfoComponent,
    ProfileImageComponent,
    TopNavComponent,
    SellerNavComponent,
    GigPricingComponent,
    MyGigComponent,
    GigDetailComponent,
    GigPlanComponent,
    GigFaqComponent,
    VerifyEmailComponent,
    AfterLoginComponent
  ],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    AngularEditorModule,
    SelectDropDownModule,
    NgxTypedJsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    HttpClientModule,
    ComponentsModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
