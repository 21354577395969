

export class AppOrder {
    id: string;
    paymentPeriod: string;
    paymentMethod: string;
    bankId: string;
    accountName: string;
    accountNumber: string;
    amount: string;
    mobile: string;
}


