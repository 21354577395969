<app-seller-sidemenu></app-seller-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-freelancer-navbar></app-freelancer-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="row">
    
        <div class="col-lg-10 col-md-12">
            <div class="my-profile-box">
                <h3>Freelancer Bio</h3>
    
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div  class="col-md-8">
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>Current Password</label>
                                    <input type="password" class="form-control" value="123456">
                                </div>
                            </div>
                            
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>New Password</label>
                                    <input type="password" class="form-control">
                                </div>
                            </div>
                            
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>Confirm New Password</label>
                                    <input type="password" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <button type="submit">Save Change</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->