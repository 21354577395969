<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>Hello,{{account.firstName}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/dashboard">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->


    <app-unpaidapp></app-unpaidapp>
  <app-paidapp></app-paidapp>

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->