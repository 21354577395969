import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CategoryService,ProductService,AlertService } from '../../../../_services';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/operators';
const imageUrl = `${environment.apiUrl}/preview`;
@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
    imageView = imageUrl;
    products;
    constructor(
        private categoryService: CategoryService,
        private productService: ProductService,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.productService.getAll()
        .pipe(first())
        .subscribe(products => this.products = products);
    }

  
 
  
      gridListings: number = 1;
  
      customOptions: OwlOptions = {
          loop: true,
          nav: true,
          dots: false,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
          autoplayHoverPause: true,
          autoplay: false,
          mouseDrag: true,
          margin: 30,
          rewind: true,
          navText: [
            "<i class='flaticon-left-chevron'></i>",
            "<i class='flaticon-right-chevron'></i>"
        ],
          responsive: {
            0: {
                items: 3,
            },
            768: {
                items: 3,
            },
            1200: {
                items: 5,
            }
        },
      }
      
   

}